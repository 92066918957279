import React from 'react';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem } from '@mui/material';

import { IReference } from './types';
import classes from './ReportComponent.module.scss';

interface Props {
  references: IReference[];
  checkboxReferenceHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ReferencesMenu: React.FC<Props> = ({ references, checkboxReferenceHandler }) => {
  let referenceElement: JSX.Element | null = null;

  const [referenceMenuState, setReferenceMenuState] = React.useState<null | HTMLElement>(null);

  const handleReferenceMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReferenceMenuState(event.currentTarget);
  };
  const closeReferenceMenu = () => {
    setReferenceMenuState(null);
  };

  if (references && references.length > 0) {
    referenceElement = (
      <>
        <Button
          style={{ textTransform: 'none' }}
          aria-controls="reference-menu"
          aria-haspopup="true"
          variant="outlined"
          onClick={handleReferenceMenuClick}
          className={`${classes.actionButton} ${references.some((x) => x.checked) ? classes.checkedButton : ''} ml-3`}
        >
          References
          <Icon path={mdiChevronDown} title="arrow down" size={0.9} />
        </Button>
        <Menu
          anchorEl={referenceMenuState}
          keepMounted
          open={Boolean(referenceMenuState)}
          onClose={closeReferenceMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {references.map((reference) => (
            <MenuItem key={reference.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reference.checked}
                    onChange={checkboxReferenceHandler}
                    value={reference.id}
                    color="default"
                    name={reference.name}
                  />
                }
                label={reference.name}
              />
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
  return <>{referenceElement}</>;
};
export default ReferencesMenu;
