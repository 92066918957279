/* eslint-disable jsx-a11y/no-access-key */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import deepCopy from 'deepcopy';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';

import classes from './Reports.module.scss';
import routerPaths from '../../../router/RouterPaths';
import ConfirmDialog, { ConfirmDialogType } from '../../../components/dialog/ConfirmDialog';
import NewReportDialog from './NewReportDialog';
import PrognosSnackbar, { SnackbarType } from '../../../ui/snackbar/Snackbar';
import ReportsTable, { TableHeader } from './ReportsTable';
import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

interface Props {
  customerId: string;
}
enum REPORT_TYPE {
  SI = 'SI',
  STC = 'STC',
  CC = 'CC',
}
interface IReport {
  _id: string;
  name: string;
  country: string;
  currency: string;
  isDraft: boolean;
  isShared: boolean;
  inCollections: string[];
  reportUrl: string;
  isReportValid: boolean;
  hideReportFromUserReportList: boolean;
  reportType?: REPORT_TYPE.CC | REPORT_TYPE.SI | REPORT_TYPE.STC;
  firstDatePointDate?: Date;
  lastDataPointDate?: Date;
  lastDateUpdatedDataFromPdm?: Date;
  lastDateChangedReportRequestData?: Date;
}

const AdminReports: React.FC<Props> = ({ customerId }) => {
  const confirmDataConstant = {
    dialogText: '',
    errorMessage: '',
    buttonTextAccept: '',
    buttonTextDismiss: '',
    dialogTitle: '',
    isLoading: false,
    showDialog: false,
    submitDialog: () => undefined,
  };
  const [reportsState, setReportsState] = useState<IReport[]>([]);
  const [confirmDialogState, setConfirmDialogState] = useState<ConfirmDialogType>(confirmDataConstant);
  const [snackbarState, setSnackbarState] = useState({
    title: '',
    message: '',
    showSnackbar: false,
  });
  const [showNewReportDialogState, setShowNewReportDialogState] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [tableItemsToShow, setTableItemsToShow] = useState<IReport[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const axiosPrivate = useAxiosPrivate();
  const { customerUrl } = useParams();
  const newSearchInput = (searchTextInput: string, reportSorted?: IReport[]) => {
    setSearchText(searchTextInput);
    const reportCopy = reportSorted || deepCopy(reportsState);
    const filteredReports = reportCopy.filter((reportFilter) => {
      const searchTextLower = searchTextInput.toLocaleLowerCase();
      if (!searchTextInput) return true;
      if (
        reportFilter._id.toLocaleLowerCase().includes(searchTextLower) ||
        reportFilter.name.toLocaleLowerCase().includes(searchTextLower) ||
        reportFilter.currency.toLocaleLowerCase().includes(searchTextLower) ||
        reportFilter.country.toLocaleLowerCase().includes(searchTextLower)
      ) {
        return true;
      }

      return false;
    });
    setTableItemsToShow(filteredReports);
  };
  const sortReportByName = (reportsToSort: IReport[]) => {
    const reportCopy = deepCopy(reportsToSort);
    return reportCopy.sort((a, b) => {
      const nameA = a.name?.toUpperCase();
      const nameB = b.name?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };

  async function getReports() {
    setErrorMessage('');
    await axiosPrivate
      .get('/reports/getUserReportsAdmin', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
        params: {
          customerId: customerId,
        },
      })
      .then((response) => {
        const reports: IReport[] = response.data;
        if (reports) {
          const sortedReports = sortReportByName(reports);
          setReportsState(sortedReports);
          newSearchInput(searchText, sortedReports);
        }
      })
      .catch((e) => {
        setErrorMessage('Something went wrong try again later.');
      });
  }

  useEffect(() => {
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteReport = async (report: IReport, confirmDialogValues: ConfirmDialogType) => {
    const dialogValuesCopy = { ...confirmDialogValues, isLoading: true };
    setConfirmDialogState(dialogValuesCopy);

    await axiosPrivate
      .delete(`/reports/${report._id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((reponse) => {
        setConfirmDialogState({ ...confirmDataConstant });
        setSnackbarState({
          title: '',
          message: `Report "${report.name}" is deleted.`,
          showSnackbar: true,
        });
        getReports();
        setTimeout(() => {
          setSnackbarState({ title: '', message: '', showSnackbar: false });
        }, 5000);
      })
      .catch((error) => {
        const confirmDialogValuesCopy = {
          ...confirmDialogValues,
          errorMessage: 'Something went wrong',
          isLoading: false,
        };
        setConfirmDialogState(confirmDialogValuesCopy);
      });
  };

  const confirmDeleteReport = (report: IReport) => {
    const values = {
      dialogTitle: 'Confirm',
      errorMessage: '',
      dialogText: `${'Are you sure you want to delete "'}${report.name}" ?`,
      buttonTextAccept: 'Delete',
      showDialog: true,
      submitDialog: () => deleteReport(report, confirmDialogState),
    };
    values.submitDialog = () => deleteReport(report, values);

    setConfirmDialogState(values);
  };
  const closeConfirmAlertDialog = () => {
    const confirmAlertDataConstantCopy = { ...confirmDataConstant };
    setConfirmDialogState(confirmAlertDataConstantCopy);
  };
  const reportToPublish = async (report: IReport, confirmDialogValues: ConfirmDialogType) => {
    const dialogValuesCopy = { ...confirmDialogValues, isLoading: true };
    setConfirmDialogState(dialogValuesCopy);
    await axiosPrivate
      .patch(`reports/publish/${report._id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((response) => {
        setConfirmDialogState({ ...confirmDataConstant });
        setSnackbarState({
          title: '',
          message: `Report "${report.name}" is now published.`,
          showSnackbar: true,
        });
        getReports();
        setTimeout(() => {
          setSnackbarState({ title: '', message: '', showSnackbar: false });
        }, 5000);
      })
      .catch((e) => {
        const confirmDialogValuesCopy = {
          ...confirmDialogValues,
          errorMessage: 'Could not publish report',
          isLoading: false,
        };
        setConfirmDialogState(confirmDialogValuesCopy);
      });
  };
  const confirmReportToPublish = (report: IReport) => {
    const values = {
      dialogTitle: 'Confirm',
      errorMessage: '',
      dialogText: `${'Are you sure you want to publish "'}${report.name}" ?`,
      buttonTextAccept: 'Publish',
      showDialog: true,
      submitDialog: () => reportToPublish(report, confirmDialogState),
    };
    values.submitDialog = () => reportToPublish(report, values);

    setConfirmDialogState(values);
  };
  const unpublish = async (report: IReport, confirmDialogValues: ConfirmDialogType) => {
    const dialogValuesCopy = { ...confirmDialogValues, isLoading: true };
    setConfirmDialogState(dialogValuesCopy);

    await axiosPrivate
      .patch(`reports/unpublish/${report._id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((response) => {
        setConfirmDialogState({ ...confirmDataConstant });
        setSnackbarState({
          title: '',
          message: `Report "${report.name}" is now unpublished.`,
          showSnackbar: true,
        });
        getReports();
        setTimeout(() => {
          setSnackbarState({ title: '', message: '', showSnackbar: false });
        }, 5000);
      })
      .catch((e) => {
        const confirmDialogValuesCopy = {
          ...confirmDialogValues,
          errorMessage: 'Could not publish report',
          isLoading: false,
        };
        setConfirmDialogState(confirmDialogValuesCopy);
      });
  };
  const confirmUnpublish = (report: IReport) => {
    const values = {
      dialogTitle: 'Confirm',
      dialogText: `${'Are you sure you want to unpublish "'}${report.name}" ?`,
      buttonTextAccept: 'Unpublish',
      showDialog: true,
      submitDialog: () => unpublish(report, confirmDialogState),
    };
    values.submitDialog = () => unpublish(report, values);

    setConfirmDialogState(values);
  };
  const openNewReportDialog = () => {
    setShowNewReportDialogState(true);
  };
  const closeNewReportDialog = () => {
    setShowNewReportDialogState(false);
  };
  const newReportCreated = () => {
    getReports();
    setSnackbarState({
      title: 'Report created',
      message: 'The report was created successfully',
      showSnackbar: true,
    });
    setTimeout(() => {
      setSnackbarState({ title: '', message: '', showSnackbar: false });
    }, 5000);
  };

  const tableHeaders: TableHeader[] = [
    {
      id: 'name',
      name: 'Name',
      sortable: true,
      numeric: false,
    },
    {
      id: 'country',
      name: 'Country',
      sortable: true,
      numeric: false,
    },
    {
      id: 'currency',
      name: 'Currency',
      sortable: true,
      numeric: false,
    },
    {
      id: 'hidden',
      name: 'Hidden',
      sortable: false,
      numeric: false,
    },
    {
      id: 'firstDatePointDate',
      name: 'Date from',
      sortable: true,
      numeric: false,
    },
    {
      id: 'firstDatePointDate',
      name: 'Date to',
      sortable: true,
      numeric: false,
    },
  ];
  return (
    <>
      <div className={`$classes.main ml-2 mr-2`}>
        <div className={`${classes.tablePageHeader} mb-3`}>
          <Button
            small
            type={ButtonType.button}
            buttonStyleType={ButtonStyleType.primary}
            text="New report"
            onClick={openNewReportDialog}
          />
          <div>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon fontSize="small" />
              </div>
              <InputBase
                className={classes.inputInput}
                accessKey="f"
                onChange={(event) => newSearchInput(event.target.value)}
                value={searchText}
                style={{ width: '100%' }}
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </div>
        </div>
        {errorMessage && <div className="errorText">{errorMessage}</div>}
        <h2 className="mb-3 ml-3">Drafts</h2>
        <ReportsTable
          tableHeaders={tableHeaders}
          tableItems={
            tableItemsToShow &&
            tableItemsToShow
              .filter((x) => x.isDraft)
              .map((tableItem) => ({
                id: tableItem._id,
                name: tableItem.name,
                country: tableItem.country,
                currency: tableItem.currency,
                firstDatePointDate: tableItem.firstDatePointDate
                  ? new Date(tableItem.firstDatePointDate).toLocaleDateString('sv-SE')
                  : '',
                lastDataPointDate: tableItem.lastDataPointDate
                  ? new Date(tableItem.lastDataPointDate).toLocaleDateString('sv-SE')
                  : '',
                hidden: tableItem.hideReportFromUserReportList ? 'hidden' : '',
                isReportValid: <>{!tableItem.isReportValid && <div className="isValidError">Error in report!</div>}</>,
                actions: (
                  <>
                    {customerUrl && (
                      <Link className="ml-3" to={routerPaths.reportEdit.replace(':reportUrl', tableItem.reportUrl)}>
                        <Button
                          small
                          type={ButtonType.button}
                          buttonStyleType={ButtonStyleType.secondary}
                          text="Edit"
                        />
                      </Link>
                    )}
                    {customerUrl && (
                      <Link
                        className="ml-3"
                        to={routerPaths.report
                          .replace(':customerUrl', customerUrl)
                          .replace(':reportUrl', tableItem.reportUrl)}
                      >
                        <Button
                          small
                          type={ButtonType.button}
                          buttonStyleType={ButtonStyleType.secondary}
                          text="Preview"
                        />
                      </Link>
                    )}
                    <Button
                      className="ml-3"
                      small
                      type={ButtonType.button}
                      buttonStyleType={ButtonStyleType.primary}
                      text="Publish"
                      onClick={() => confirmReportToPublish(tableItem)}
                    />
                    <Button
                      className="ml-3"
                      small
                      type={ButtonType.button}
                      buttonStyleType={ButtonStyleType.delete}
                      text="X"
                      onClick={() => confirmDeleteReport(tableItem)}
                    />
                  </>
                ),
              }))
          }
          hasActions
          totalItems={reportsState.filter((i) => i.isDraft).length}
        />
        <h2 className="mt-6 mb-3 ml-3">Published Reports</h2>
        <ReportsTable
          tableHeaders={tableHeaders}
          tableItems={
            tableItemsToShow &&
            tableItemsToShow
              .filter((x) => !x.isDraft)
              .map((tableItem) => ({
                id: tableItem._id,
                name: tableItem.name,
                country: tableItem.country,
                currency: tableItem.currency,
                firstDatePointDate: tableItem.firstDatePointDate
                  ? new Date(tableItem.firstDatePointDate).toLocaleDateString('sv-SE')
                  : '',
                lastDataPointDate: tableItem.lastDataPointDate
                  ? new Date(tableItem.lastDataPointDate).toLocaleDateString('sv-SE')
                  : '',
                hidden: tableItem.hideReportFromUserReportList ? 'hidden' : '',
                isReportValid: <>{!tableItem.isReportValid && <div className="isValidError">Error in report!</div>}</>,
                actions: (
                  <>
                    {customerUrl && (
                      <Link className="ml-3" to={routerPaths.reportEdit.replace(':reportUrl', tableItem.reportUrl)}>
                        <Button
                          small
                          type={ButtonType.button}
                          buttonStyleType={ButtonStyleType.secondary}
                          text="Edit"
                        />
                      </Link>
                    )}
                    {customerUrl && (
                      <Link
                        className="ml-3"
                        to={routerPaths.report
                          .replace(':customerUrl', customerUrl)
                          .replace(':reportUrl', tableItem.reportUrl)}
                      >
                        <Button
                          small
                          type={ButtonType.button}
                          buttonStyleType={ButtonStyleType.secondary}
                          text="View"
                        />
                      </Link>
                    )}
                    <Button
                      className="ml-3"
                      small
                      type={ButtonType.button}
                      buttonStyleType={ButtonStyleType.delete}
                      text="Unpublish"
                      onClick={() => confirmUnpublish(tableItem)}
                    />
                    <Button
                      className="ml-3"
                      small
                      type={ButtonType.button}
                      buttonStyleType={ButtonStyleType.delete}
                      text="X"
                      onClick={() => confirmDeleteReport(tableItem)}
                    />
                  </>
                ),
              }))
          }
          hasActions
          totalItems={reportsState.filter((i) => !i.isDraft).length}
        />
      </div>
      {customerId && (
        <NewReportDialog
          customerId={customerId}
          showDialog={showNewReportDialogState}
          closeDialog={closeNewReportDialog}
          reportCreated={() => newReportCreated()}
        />
      )}
      <ConfirmDialog
        isLoading={confirmDialogState?.isLoading || false}
        errorMessage={confirmDialogState.errorMessage}
        submitDialog={confirmDialogState.submitDialog}
        showDialog={confirmDialogState.showDialog}
        closeDialog={closeConfirmAlertDialog}
        buttonTextAccept={confirmDialogState?.buttonTextAccept}
        dialogText={confirmDialogState?.dialogText}
      />
      <PrognosSnackbar
        title={snackbarState.title}
        message={snackbarState.message}
        showSnackbar={snackbarState.showSnackbar}
        snackbarType={SnackbarType.success}
      />
    </>
  );
};

AdminReports.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default AdminReports;
