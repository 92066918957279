import React, { useEffect, useState } from 'react';
/* import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'; */
import classes from './EditReport.module.scss';
import TextField, { TextFieldType, ValidationRules } from '../../../ui/input/TextField';
import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import { axiosPrivate } from '../../../plugins/Axios';
import { Checkbox } from '@mui/material';

export interface Props {
  name: string;
  yLabelName?: string;
  customerId: string;
  reportId: string;
  hideReportFromUserReportList: boolean;
  setBasicInfoValues: () => void;
}
interface Rule {
  rule: string;
  ruleHelper?: string | number;
  errorText: string;
}
interface ReportName {
  value: string;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}

interface YLabelName {
  value: string | undefined;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}

interface HideReportFromUserReportList {
  value: boolean | undefined;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}
interface FormElement {
  reportName: ReportName;
  yLabelName: YLabelName;
  hideReportFromUserReportList: HideReportFromUserReportList;
}
interface IsValidReturnType {
  hasError: boolean;
  errorText: string;
}

const BasicInfo: React.FC<Props> = ({
  name,
  setBasicInfoValues,
  yLabelName,
  customerId,
  reportId,
  hideReportFromUserReportList,
}) => {
  // const dateConstant = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const [showBasicInfoSaveButton, setShowBasicInfoSaveButton] = useState(false);
  const [basicInfoIsLoading, setBasicInfoIsLoading] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState('');
  // const [selectedDate, handleDateChange] = useState<Date | any>(dateConstant);
  const [formValuesState, setFormValuesState] = useState<FormElement>({
    reportName: {
      value: '',
      rules: [
        {
          rule: ValidationRules.required,
          errorText: 'Name cannot be empty',
        },
      ],
      error: false,
      errorText: '',
    },
    yLabelName: {
      value: '',
      rules: [
        {
          rule: ValidationRules.maxLength,
          ruleHelper: 15,
          errorText: 'Label cannot be longer than 15 characters',
        },
      ],
      error: false,
      errorText: '',
    },
    hideReportFromUserReportList: {
      value: undefined,
      rules: [
        {
          rule: ValidationRules.maxLength,
          ruleHelper: 15,
          errorText: 'Label cannot be longer than 15 characters',
        },
      ],
      error: false,
      errorText: '',
    },
  });
  useEffect(() => {
    let formValuesStateCopy = { ...formValuesState };
    formValuesStateCopy.reportName = { ...formValuesStateCopy.reportName };
    formValuesStateCopy.reportName.value = name;
    formValuesStateCopy.yLabelName = { ...formValuesStateCopy.yLabelName };
    formValuesStateCopy.yLabelName.value = yLabelName;

    formValuesStateCopy.hideReportFromUserReportList = { ...formValuesStateCopy.hideReportFromUserReportList };
    formValuesStateCopy.hideReportFromUserReportList.value = hideReportFromUserReportList;

    setFormValuesState(formValuesStateCopy);
    return () => {
      formValuesStateCopy = { ...formValuesState };
      formValuesStateCopy.reportName = { ...formValuesStateCopy.reportName };
      formValuesStateCopy.reportName.value = '';
      formValuesStateCopy.yLabelName = { ...formValuesStateCopy.yLabelName };
      formValuesStateCopy.yLabelName.value = '';
      formValuesStateCopy.hideReportFromUserReportList = { ...formValuesStateCopy.hideReportFromUserReportList };
      formValuesStateCopy.hideReportFromUserReportList.value = undefined;
      setFormValuesState(formValuesStateCopy);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, yLabelName]);

  const isInputValid = (value: string | number | Date | null | boolean, inputRules: Rule[]): IsValidReturnType => {
    const returnValue = {
      hasError: false,
      errorText: '',
    };
    for (let index = 0; index < inputRules.length; index += 1) {
      if (inputRules[index].rule === ValidationRules.required && value === '') {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }

      if (
        (inputRules[index].rule === ValidationRules.maxLength &&
          value !== null &&
          Number(inputRules[index].ruleHelper) < value?.toString().length) ??
        0
      ) {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }
    }
    return returnValue;
  };
  const inputChangeHandler = (newValue: string | number | Date | null | boolean, itemIdentifier: string) => {
    setShowBasicInfoSaveButton(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formValuesStateCopy: any = { ...formValuesState };
    const updatedElement = { ...formValuesStateCopy[itemIdentifier] };

    updatedElement.value = newValue;
    if (updatedElement.rules) {
      const { hasError, errorText } = isInputValid(newValue, updatedElement.rules);
      updatedElement.error = hasError;
      updatedElement.errorText = hasError ? errorText : '';
    } else if (updatedElement.error) {
      updatedElement.error = false;
    }

    formValuesStateCopy[itemIdentifier] = updatedElement;
    setFormValuesState(formValuesStateCopy);
  };
  const isFormValidFrontend = (): boolean => {
    let isValid = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formValuesStateCopy: any = { ...formValuesState };
    Object.entries(formValuesState).forEach(([itemIdentifier, formElement]) => {
      const { hasError, errorText } = isInputValid(formElement.value, formElement.rules);
      if (hasError) {
        isValid = false;
        const updatedElement = { ...formValuesStateCopy[itemIdentifier] };
        updatedElement.error = hasError;
        updatedElement.errorText = errorText;
        formValuesStateCopy[itemIdentifier] = updatedElement;
      }
    });
    setFormValuesState(formValuesStateCopy);
    return isValid;
  };
  const saveBasicInfo = async (event: React.FormEvent) => {
    event.preventDefault();
    setBasicInfoIsLoading(true);
    setErrorMessageState('');
    if (isFormValidFrontend()) {
      const reportName = formValuesState.reportName.value;
      const yLabelName = formValuesState.yLabelName?.value;

      try {
        await axiosPrivate.patch(`reports/${reportId}`, {
          reportId: reportId,
          reportName,
          yLabelName,
          hideReportFromUserReportList: formValuesState.hideReportFromUserReportList?.value ?? false,
        });
      } catch (error) {
        setErrorMessageState('Error when saving');
        setBasicInfoIsLoading(false);
        return;
      }
      setBasicInfoValues();
      setShowBasicInfoSaveButton(false);
    }
    setBasicInfoIsLoading(false);
  };

  return (
    <div className={`${classes.card}`}>
      <h2>Basic info</h2>
      {errorMessageState && (
        <div
          className="mt-3 mb-5"
          style={{
            backgroundColor: '#FFCDD2',
            border: '1px solid #EF9A9A',
            padding: '8px',
            borderRadius: '3px',
          }}
        >
          {errorMessageState}
        </div>
      )}
      <form>
        <TextField
          className="mb-3 mt-3"
          id="reportName"
          label="Name"
          error={formValuesState.reportName.error}
          helperText={formValuesState.reportName.errorText}
          type={TextFieldType.text}
          value={formValuesState.reportName.value}
          valueChanged={(newValue) => inputChangeHandler(newValue, 'reportName')}
        />
        <TextField
          className="mb-3 mt-3"
          id="yLabelName"
          label="Y-axis label"
          error={formValuesState.yLabelName?.error}
          helperText={formValuesState.yLabelName?.errorText}
          type={TextFieldType.text}
          value={formValuesState.yLabelName.value ?? ''}
          valueChanged={(newValue) => inputChangeHandler(newValue, 'yLabelName')}
        />
        <div>
          <Checkbox
            checked={formValuesState.hideReportFromUserReportList?.value ?? false}
            color="primary"
            onChange={(event) => inputChangeHandler(event.target.checked, 'hideReportFromUserReportList')}
            sx={{
              color: '#19555a',
              '&.Mui-checked': {
                color: '#19555a',
              },
            }}
          />
          Hide report from user report list (Use when a report is only a reference)
        </div>
        <div className={classes.formActions}>
          {showBasicInfoSaveButton && (
            <Button
              loading={basicInfoIsLoading}
              type={ButtonType.submit}
              buttonStyleType={ButtonStyleType.primary}
              text="Save"
              onClick={saveBasicInfo}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default React.memo(BasicInfo);
