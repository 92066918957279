import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import classes from './Tooltip.module.scss';

interface Props {
  children: React.ReactNode;
  title: string | JSX.Element;
}

const TooltipPrognos: React.FC<Props> = (props) => {
  const { children, title } = props;
  return (
    <Tooltip title={<h1 className={classes.title}>{title}</h1>}>
      <div>{children}</div>
    </Tooltip>
  );
};

export default TooltipPrognos;
