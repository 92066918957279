import React, { useEffect, useState } from 'react';
import Router from './router/Router';
import useUser from './hooks/useUser';
import useAuth from './hooks/useAuth';

import { axiosPrivate } from './plugins/Axios';
import axios from 'axios';
import { UserData } from './context/UserProvider';
import useRefreshToken from './hooks/useRefreshToken';
import CircularProgress from '@mui/material/CircularProgress';
import classes from './App.module.scss';
import jwt_decode from 'jwt-decode';
import { ROLE } from './context/Roles.enum';

type JwtPayload = {
  iat: number;
  exp: number;
  jwtPayload: {
    sub: string;
    roles: ROLE[];
    userId: string;
    customerId: string;
  };
};

function App() {
  const [setup, setSetup] = useState(false);
  const refreshToken = useRefreshToken();
  const userContext = useUser();
  const authContext = useAuth();

  const isValidToken = (exp: number): boolean => {
    if (exp * 1000 < Date.now()) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let validAccessToken = false;
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
          const accessTokenDecoded: JwtPayload = jwt_decode(accessToken);
          validAccessToken = isValidToken(accessTokenDecoded.exp);
          if (validAccessToken) {
            authContext?.saveAuthData({
              userId: accessTokenDecoded.jwtPayload.userId,
              accessToken: accessToken,
              customerId: accessTokenDecoded.jwtPayload.customerId,
              roles: accessTokenDecoded.jwtPayload.roles,
              exp: accessTokenDecoded.exp,
              iat: accessTokenDecoded.iat,
            });
          }
        }

        if (refreshToken && !validAccessToken) {
          await refreshToken();
        }
      } catch (error: unknown) {
        //TODO: log error
      }
      setSetup(true);
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    async function getUserData() {
      try {
        const response = await axiosPrivate.get('/users/getUser', {
          cancelToken: source.token,
        });
        if (response.data) {
          const userData: UserData = {
            customerName: response.data?.customerName || '',
            customerUrl: response.data?.customerUrl || '',
            email: response.data?.email || '',
          };
          userContext?.updateUserData(userData);
        }
      } catch (error) {
        // TODO handle error
      }
    }
    if (authContext?.authData.userId) {
      getUserData();
    }
    return () => {
      source.cancel('Cancelling in cleanup');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext?.authData.userId, authContext?.authData.customerId]);

  return (
    <>
      {setup ? (
        <Router />
      ) : (
        <div className={classes.loading}>
          <CircularProgress color="info" size={80} />
        </div>
      )}
    </>
  );
}

export default App;
