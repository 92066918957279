import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { mdiContentCopy } from '@mdi/js';
import IconButton from '../../../ui/iconButton/IconButton';
import PrognosSnackbar, { SnackbarType } from '../../../ui/snackbar/Snackbar';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import axios from 'axios';

interface Props {
  customerId: string;
}
interface IUserInvitations {
  invitationKey: string;
  id: string;
  active: boolean;
}
const AdminInvitationKeys: React.FC<Props> = ({ customerId }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [userInvitations, setUserInvitations] = React.useState<IUserInvitations[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getUserInvitation = async () => {
      await axiosPrivate
        .get(`/user-invitations/${customerId}`, {
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        })
        .then((response) => {
          const userInvitation: IUserInvitations[] = response.data;
          if (userInvitation) {
            setUserInvitations(userInvitation);
          }
        })
        .catch((error) => {
          if (error?.code === 'ERR_CANCELED') {
            return;
          }
          setErrorMessage('Something went wrong try again later.');
        });
    };
    getUserInvitation();
  }, [axiosPrivate, customerId]);

  const copyTextHandler = (text: string) => {
    navigator.clipboard.writeText(text);
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 5000);
  };
  return (
    <div className="ml-2 mr-2">
      <h2 className="ml-3">Invitation key</h2>
      {errorMessage && <h2 className="pt-3">{errorMessage}</h2>}
      {userInvitations.map((userInvitation) => (
        <div
          key={userInvitation.id}
          className="mt-3 "
          style={{
            backgroundColor: '#d1ecf1',
            border: '1px solid #bee5eb',
            padding: '8px',
            borderRadius: '3px',
          }}
        >
          {userInvitation.invitationKey}
          <IconButton
            areaLabel="Copy invitation key"
            icon={mdiContentCopy}
            onClick={() => copyTextHandler(userInvitation.invitationKey)}
          />
          <span className="alertWarning floatRight">{userInvitation.active ? 'Active' : 'Not active'}</span>
        </div>
      ))}
      <PrognosSnackbar
        message="Invitation key copied to clipboard"
        showSnackbar={showSnackbar}
        snackbarType={SnackbarType.info}
      />
    </div>
  );
};

AdminInvitationKeys.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default AdminInvitationKeys;
