import PropTypes from 'prop-types';
import React from 'react';
import { IconButton, styled, IconProps } from '@mui/material';
import Icon from '@mdi/react';

export enum IconButtonStyleType {
  delete = 'delete',
  edit = 'edit',
  add = 'add',
  default = 'default',
}

interface Props {
  icon: string;
  areaLabel: string;
  size?: number;
  buttonStyleType?: IconButtonStyleType;
  children?: React.ReactNode;
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonIcon: React.FC<Props> = ({
  icon,
  onClick,
  areaLabel,
  size,
  children,
  buttonStyleType,
  className,
}) => {
  const DeleteButton = styled(IconButton)<IconProps>(({ theme }) => ({
    color: '#fff',
    borderRadius: '8px',
    backgroundColor: '#bd2130',
    '&:hover': {
      backgroundColor: '#971A26',
    },
  }));

  const AddButton = styled(IconButton)<IconProps>(({ theme }) => ({
    color: '#fff',
    borderRadius: '8px',
    backgroundColor: '#28a745',
    '&:hover': {
      backgroundColor: '#208537',
    },
  }));

  const EditButton = styled(IconButton)<IconProps>(({ theme }) => ({
    color: '#fff',
    borderRadius: '8px',
    backgroundColor: '#868e96',
    '&:hover': {
      backgroundColor: '#6B7178',
    },
  }));

  let iconButton: JSX.Element = <div />;

  switch (buttonStyleType) {
    case IconButtonStyleType.delete:
      iconButton = (
        <DeleteButton
          className={className}
          aria-label={areaLabel}
          size="small"
          onClick={onClick}
        >
          <Icon path={icon} title={areaLabel} size={size} />
          {children}
        </DeleteButton>
      );
      break;
    case IconButtonStyleType.add:
      iconButton = (
        <AddButton
          className={className}
          aria-label={areaLabel}
          size="small"
          onClick={onClick}
        >
          <Icon path={icon} title={areaLabel} size={size} />
          {children}
        </AddButton>
      );
      break;
    case IconButtonStyleType.edit:
      iconButton = (
        <EditButton
          className={className}
          aria-label={areaLabel}
          size="small"
          onClick={onClick}
        >
          <Icon path={icon} title={areaLabel} size={size} />
          {children}
        </EditButton>
      );
      break;
    default:
      iconButton = (
        <IconButton
          className={className}
          aria-label={areaLabel}
          size="small"
          onClick={onClick}
        >
          <Icon path={icon} title={areaLabel} size={size} />
          {children}
        </IconButton>
      );
      break;
  }

  return <>{iconButton}</>;
};
ButtonIcon.defaultProps = {
  size: 1,
  children: '',
  buttonStyleType: IconButtonStyleType.default,
  className: '',
};

ButtonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  areaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  children: PropTypes.string,
  buttonStyleType: PropTypes.oneOf([
    IconButtonStyleType.delete,
    IconButtonStyleType.edit,
    IconButtonStyleType.add,
    IconButtonStyleType.default,
  ]),
};

export default ButtonIcon;
