import { useContext, useDebugValue } from 'react';
import AdminCustomerProvider, {
  AdminCustomerType,
} from '../context/AdminCustomerProvider';

const useUser = () => {
  const adminCustomerContext = useContext<AdminCustomerType | undefined>(
    AdminCustomerProvider
  );
  useDebugValue(adminCustomerContext, (authData) =>
    adminCustomerContext?.adminCustomerData?.customerId
      ? 'Have customer data'
      : 'No customer data'
  );
  return useContext(AdminCustomerProvider);
};

export default useUser;
