import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Icon from '@mdi/react';
import { mdiEarth, mdiCash } from '@mdi/js';
import classes from './Legend.module.scss';
import TooltipPrognos from '../../../ui/tooltip/Tooltip';

export interface LegendInterFace {
  id: string;
  name: string;
  share?: number | null;
  description?: string;
  country?: string;
  currency?: string;
  color: string;
}

const Legend: React.FC<LegendInterFace> = (props) => {
  const { search, pathname } = useLocation();
  const urlNestedChartIds = new URLSearchParams(search).getAll('c');
  const { name, id, share, description, country, currency, color } = props;

  const toLink = () => {
    let link = pathname;
    if (urlNestedChartIds.length > 0) {
      link += '?c=';
      for (let index = 0; index < urlNestedChartIds.length; index += 1) {
        if (index === 0) {
          link += urlNestedChartIds[index];
        } else {
          link += `&c=${urlNestedChartIds[index]}`;
        }
      }
      link += `&c=${id}&co=${color}`;
    } else {
      link += `?c=${id}&co=${color}`;
    }
    return link;
  };

  const legendElementInside: JSX.Element = (
    <TooltipPrognos
      title={
        <>
          {!description && !currency && !country && <i>No additional information</i>}
          {description}
          {description && (country || currency) && <hr className={classes.hrToolTip} />}
          {country && (
            <div className={classes.verticalCenter}>
              <Icon className="mr-1" path={mdiEarth} title="Earth icon" size={1} />
              {country}
            </div>
          )}
          {currency && (
            <div className={classes.verticalCenter}>
              <Icon className="mr-1" path={mdiCash} title="Earth icon" size={1} />
              {currency}
            </div>
          )}
          {}
        </>
      }
    >
      <span className={`${classes.verticalCenter} ${classes.legendLinkBox}`}>
        {name}
        <div className={classes.colorBox} style={{ backgroundColor: color }}>
          {share && `${(share * 100).toFixed(6).replace(/\.?0+$/, '')}%`}
        </div>
      </span>
    </TooltipPrognos>
  );
  let legendElement: JSX.Element = <div />;

  if (share) {
    legendElement = (
      <Link to={toLink()} className={`${classes.legendContainer}`}>
        {legendElementInside}
      </Link>
    );
  } else {
    legendElement = <div className={`${classes.legendContainer}`}>{legendElementInside}</div>;
  }

  return <>{legendElement}</>;
};
Legend.defaultProps = {
  share: undefined,
  country: undefined,
  currency: undefined,
  description: '',
};

Legend.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  share: PropTypes.number,
  description: PropTypes.string,
  country: PropTypes.string,
  currency: PropTypes.string,
  color: PropTypes.string.isRequired,
};

export default Legend;
