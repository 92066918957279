import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Button, { ButtonStyleType, ButtonType } from '../../ui/button/Button';

export interface ConfirmDialogType {
  dialogTitle?: string;
  dialogText: string;
  buttonTextAccept: string;
  buttonTextDismiss?: string;
  isLoading?: boolean;
  errorMessage?: string;
  showDialog: boolean;
  submitDialog?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
interface Props {
  dialogText: string;
  buttonTextAccept: string;
  buttonTextDismiss?: string;
  errorMessage?: string;
  showDialog: boolean;
  isLoading?: boolean;
  submitDialog?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  closeDialog: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ConfirmAlertDialog: React.FC<Props> = ({
  dialogText,
  buttonTextAccept,
  buttonTextDismiss,
  submitDialog,
  showDialog,
  closeDialog,
  errorMessage,
  isLoading,
}) => {
  const ButtonDismissText = buttonTextDismiss || 'Cancel';
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={showDialog}
      onClose={closeDialog}
      aria-labelledby="Create customer dialog"
    >
      <DialogTitle style={{ backgroundColor: '#f7f7f7' }}>Confirm</DialogTitle>
      <hr />
      <DialogContent className="pt-5">
        {dialogText}
        {errorMessage && (
          <div
            className="mt-3 "
            style={{
              backgroundColor: '#FFCDD2',
              border: '1px solid #EF9A9A',
              padding: '8px',
              borderRadius: '3px',
            }}
          >
            {errorMessage}
          </div>
        )}
      </DialogContent>
      <hr />
      <DialogActions className="mt-1 mb-1">
        <Button
          type={ButtonType.button}
          buttonStyleType={ButtonStyleType.secondary}
          text={ButtonDismissText}
          onClick={closeDialog}
        />
        <Button
          loading={isLoading}
          type={ButtonType.button}
          buttonStyleType={ButtonStyleType.primary}
          text={buttonTextAccept}
          onClick={submitDialog}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmAlertDialog.defaultProps = {
  buttonTextDismiss: 'Cancel',
  errorMessage: '',
  submitDialog: () => undefined,
  isLoading: false,
};

ConfirmAlertDialog.propTypes = {
  dialogText: PropTypes.string.isRequired,
  buttonTextAccept: PropTypes.string.isRequired,
  buttonTextDismiss: PropTypes.string,
  errorMessage: PropTypes.string,
  submitDialog: PropTypes.func,
  closeDialog: PropTypes.func.isRequired,
  showDialog: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};
export default ConfirmAlertDialog;
