import { createContext, FC, ReactNode, useState } from 'react';

export const AdminCustomerContext = createContext<
  AdminCustomerType | undefined
>(undefined);

export interface IAdminCustomerData {
  customerName: string;
  customerId: string;
  customerUrl: string;
}

export type AdminCustomerType = {
  adminCustomerData: IAdminCustomerData;
  updateAdminCustomerData: (adminCustomerData: IAdminCustomerData) => void;
  removeAdminCustomerData: () => void;
};

const defaultAdminCustomerData: IAdminCustomerData = {
  customerName: '',
  customerId: '',
  customerUrl: '',
};
type Props = {
  children?: ReactNode;
};

export const AdminCustomerProvider: FC<Props> = ({ children }) => {
  const [adminCustomerData, setAdminCustomerData] =
    useState<IAdminCustomerData>(defaultAdminCustomerData);

  const removeAdminCustomerData = () => {
    updateAdminCustomerData(defaultAdminCustomerData);
  };

  const updateAdminCustomerData = (adminCustomerData: IAdminCustomerData) => {
    const newAdminCustomer: IAdminCustomerData = {
      customerName: adminCustomerData.customerName,
      customerId: adminCustomerData.customerId,
      customerUrl: adminCustomerData.customerUrl,
    };
    setAdminCustomerData(newAdminCustomer);
  };

  return (
    <AdminCustomerContext.Provider
      value={{
        adminCustomerData,
        updateAdminCustomerData,
        removeAdminCustomerData,
      }}
    >
      {children}
    </AdminCustomerContext.Provider>
  );
};
export default AdminCustomerContext;
