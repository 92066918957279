import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Button, { ButtonType, ButtonStyleType } from '../../../ui/button/Button';
import TextField, { TextFieldType, ValidationRules } from '../../../ui/input/TextField';
import jwt_decode from 'jwt-decode';

import routerPaths from '../../../router/RouterPaths';
import { ROLE } from '../../../context/Roles.enum';
import useAuth from '../../../hooks/useAuth';
import axiosPublic from '../../../plugins/Axios';

export enum RegisterUserErrorsEnum {
  invitationKeyInvalid = 'invitationKeyInvalid',
  invalidEmail = 'invalidEmail',
  emailAlreadyInUse = 'emailAlreadyInUse',
  invalidPassword = 'invalidPassword',
  unknownError = 'unknownError',
}

type JwtPayload = {
  sub: string;
  roles: ROLE[];
  userId: string;
  customerId: string;
  iat: string;
  exp: string;
};

interface IRegisterResponse {
  exp: number;
  iat: number;
  jwtPayload: JwtPayload;
}

interface Rule {
  rule: string;
  ruleHelper?: string | number;
  errorText: string;
}
interface InputInterface {
  value: string;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string | React.ReactNode;
  errorMessageBackend?: string;
}

interface RegisterFormElements {
  emailAddress: InputInterface;
  password: InputInterface;
  invitationKey: InputInterface;
}

const Register: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const auth = useAuth();

  useEffect(() => {
    setLoading(false);
  }, []);

  const [registerFormElements, updateInputFormElements] = useState<RegisterFormElements>({
    emailAddress: {
      value: '',
      rules: [
        {
          rule: ValidationRules.isValidEmail,
          errorText: 'Please enter a valid e-mail address.',
        },
        {
          rule: ValidationRules.isValidEmail,
          errorText: 'Please enter a valid e-mail address.',
        },
        {
          rule: ValidationRules.backend,
          errorText: 'Please enter a valid e-mail address.',
        },
      ],
      error: false,
      errorText: '',
      errorMessageBackend: 'Please enter a valid e-mail address.',
    },
    password: {
      value: '',
      rules: [
        {
          rule: ValidationRules.required,
          errorText: 'Please choose a password that is longer than 6 characters.',
        },
        {
          rule: ValidationRules.minLength,
          ruleHelper: 6,
          errorText: 'Please choose a password that is longer than 6 characters.',
        },
        {
          rule: ValidationRules.backend,
          errorText: 'Please choose a password that is longer than 6 characters.',
        },
      ],
      error: false,
      helpText: 'Minimum 6 characters',
      errorText: '',
      errorMessageBackend: 'Minimum 6 characters',
    },
    invitationKey: {
      value: '',
      error: false,
      helpText: 'To register for demo leave this field blank',
      errorMessageBackend: 'Invalid key',
      errorText: '',
    },
  });
  const isInputValid = (value: string | number | boolean, inputRules: Rule[]): IsValidReturnType => {
    const returnValue = {
      hasError: false,
      errorText: '',
    };

    if (!inputRules || (inputRules.length === 1 && inputRules[0].rule === ValidationRules.backend)) {
      return returnValue;
    }
    for (let index = 0; index < inputRules.length; index += 1) {
      if (inputRules[index].rule === ValidationRules.required && (value === '' || value === false)) {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }

      if (
        inputRules[index].rule === ValidationRules.minLength &&
        Number(inputRules[index].ruleHelper) > value.toString().length
      ) {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }

      if (inputRules[index].rule === ValidationRules.isValidEmail) {
        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value.toString()
          )
        ) {
          return {
            hasError: true,
            errorText: inputRules[index].errorText,
          };
        }
      }
    }
    return returnValue;
  };

  const inputChangeHandler = (newValue: string | number | boolean, itemIdentifier: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const registerFormElementsCopy: any = { ...registerFormElements };
    const updatedElement = { ...registerFormElementsCopy[itemIdentifier] };

    updatedElement.value = newValue;
    if (updatedElement.rules) {
      const { hasError, errorText } = isInputValid(newValue, updatedElement.rules);
      updatedElement.error = hasError;
      updatedElement.errorText = hasError ? errorText : '';
    } else if (updatedElement.error) {
      updatedElement.error = false;
    }

    registerFormElementsCopy[itemIdentifier] = updatedElement;
    updateInputFormElements(registerFormElementsCopy);
  };

  const isFormValidFrontend = (): boolean => {
    let isValid = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const registerFormElementsCopy: any = { ...registerFormElements };

    Object.entries(registerFormElements).forEach(([itemIdentifier, formElement]) => {
      const { hasError, errorText } = isInputValid(formElement.value, formElement.rules);

      if (hasError) {
        isValid = false;
        const updatedElement = {
          ...registerFormElementsCopy[itemIdentifier],
        };
        updatedElement.error = hasError;
        updatedElement.errorText = errorText;
        registerFormElementsCopy[itemIdentifier] = updatedElement;
      }
    });
    updateInputFormElements(registerFormElementsCopy);
    return isValid;
  };

  const setBackendRegisterError = (errorCode: RegisterUserErrorsEnum) => {
    const registerFormElementsCopy: RegisterFormElements = {
      ...registerFormElements,
    };
    const unknownError = false;
    switch (errorCode) {
      case RegisterUserErrorsEnum.invalidEmail: {
        const updatedElement = { ...registerFormElementsCopy.emailAddress };
        updatedElement.error = true;
        updatedElement.errorText = updatedElement.errorMessageBackend;
        registerFormElementsCopy.emailAddress = updatedElement;
        break;
      }
      case RegisterUserErrorsEnum.emailAlreadyInUse: {
        const updatedElement = { ...registerFormElementsCopy.emailAddress };
        updatedElement.error = true;
        updatedElement.errorText = (
          <>
            The e-mail address is already in use. <Link to={routerPaths.forgotPassword}>Forgot your password?</Link>
          </>
        );
        registerFormElementsCopy.emailAddress = updatedElement;
        break;
      }
      case RegisterUserErrorsEnum.invalidPassword: {
        const updatedElement = { ...registerFormElementsCopy.password };
        updatedElement.error = true;
        updatedElement.errorText = updatedElement.errorMessageBackend;
        registerFormElementsCopy.password = updatedElement;

        break;
      }
      case RegisterUserErrorsEnum.invitationKeyInvalid: {
        const updatedElement = { ...registerFormElementsCopy.invitationKey };
        updatedElement.error = true;
        updatedElement.errorText = updatedElement.errorMessageBackend;
        registerFormElementsCopy.invitationKey = updatedElement;

        break;
      }
      case RegisterUserErrorsEnum.unknownError:
      default: {
        setErrorMessage('Something went wrong. Please try again later.');
        break;
      }
    }
    if (unknownError) return;
    setErrorMessage('Something went wrong. Please try again later.');

    updateInputFormElements(registerFormElementsCopy);
  };

  const registerSubmitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isFormValidFrontend()) {
      await axiosPublic
        .post(
          `auth/signup/`,
          {
            email: registerFormElements.emailAddress.value,
            password: registerFormElements.password.value,
            invitationKey: registerFormElements.invitationKey.value,
          },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.status === 201) {
            const payload: IRegisterResponse = jwt_decode(response.data.accessToken);
            localStorage.setItem('accessToken', response.data.accessToken);
            auth?.saveAuthData({
              accessToken: response.data.accessToken,
              customerId: payload.jwtPayload.customerId,
              exp: payload.exp,
              iat: payload.iat,
              roles: payload.jwtPayload.roles,
              userId: payload.jwtPayload.userId,
            });
          }
        })
        .catch((error) => {
          if (error.response.data.message === RegisterUserErrorsEnum.invitationKeyInvalid) {
            setBackendRegisterError(RegisterUserErrorsEnum.invitationKeyInvalid);
          } else if (error.response.data.error.message === 'EMAIL_EXISTS') {
            setBackendRegisterError(RegisterUserErrorsEnum.emailAlreadyInUse);
          } else {
            setBackendRegisterError(RegisterUserErrorsEnum.unknownError);
          }
        });
    }
  };

  interface IsValidReturnType {
    hasError: boolean;
    errorText: string;
  }

  return (
    <>
      <h1 className="mb-3">Register</h1>
      <form onSubmit={registerSubmitHandler}>
        {errorMessage && <div className="errorText">Something went wrong. Please try again later.</div>}
        <TextField
          className="mb-3"
          id="emailAddress"
          label="E-mail address"
          error={registerFormElements.emailAddress.error}
          helperText={registerFormElements.emailAddress.errorText}
          type={TextFieldType.email}
          value={registerFormElements.emailAddress.value}
          valueChanged={(newValue) => inputChangeHandler(newValue, 'emailAddress')}
        />
        <TextField
          className="mb-3"
          autoComplete="current-password"
          label="Password"
          id="password"
          error={registerFormElements.password.error}
          helperText={
            registerFormElements.password.error
              ? registerFormElements.password.errorText
              : registerFormElements.password.helpText
          }
          value={registerFormElements.password.value}
          type={TextFieldType.password}
          valueChanged={(newValue) => inputChangeHandler(newValue, 'password')}
        />
        <TextField
          className="mb-3"
          autoComplete="current-password"
          label="Invitation key (Optional)"
          id="invitationKey"
          helperText={
            registerFormElements.invitationKey.error
              ? registerFormElements.invitationKey.errorText
              : registerFormElements.invitationKey.helpText
          }
          error={registerFormElements.invitationKey.error}
          value={registerFormElements.invitationKey.value}
          type={TextFieldType.text}
          valueChanged={(newValue) => inputChangeHandler(newValue, 'invitationKey')}
        />
        <div className="floatRight">
          <Button
            type={ButtonType.submit}
            buttonStyleType={ButtonStyleType.primary}
            loading={loading}
            text="Register"
          />
        </div>
      </form>
    </>
  );
};
export default Register;
