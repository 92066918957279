import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './ToolsPage.module.scss';
import PrognosButton, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import PageHeader from '../../../components/pageHeader/PageHeader';
import axios from 'axios';
import { axiosPrivate } from '../../../plugins/Axios';
import RouterPaths from '../../../router/RouterPaths';
import PrognosSnackbar, { SnackbarType } from '../../../ui/snackbar/Snackbar';
import { FindAllJobsStatusResponse, Jobs } from './types/tools.interfaces';
import { JobsName } from './types/tools.enums';

const ToolsPage: React.FC = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState<Jobs[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [snackbarState, setSnackbarState] = useState({
    title: '',
    message: '',
    showSnackbar: false,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    async function getAllJobs() {
      setErrorMessage('');
      try {
        const allJobsResponse = await axiosPrivate.get<FindAllJobsStatusResponse[]>('jobs/findLatestJobsSuccess', {
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        const jobsDataResponse: Jobs[] = allJobsResponse.data.map((a) => {
          const dataJobs: Jobs = {
            ...a,
            isLoading: false,
          };
          return dataJobs;
        });
        setJobs(jobsDataResponse);
      } catch (error) {
        setErrorMessage('Something went wrong try again later');
        return () => {
          source.cancel('Cancelling in cleanup');
        };
      }
    }
    getAllJobs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLoadingButton = (jobsName: JobsName, isLoading: boolean) => {
    const jobsDataUpdate = jobs.map((job) => {
      if (job.name === jobsName) {
        const jobsToSave: Jobs = { ...job, isLoading };
        return jobsToSave;
      }

      return job;
    });
    setJobs(jobsDataUpdate);
  };

  const updateCountries = async () => {
    const source = axios.CancelToken.source();
    setLoadingButton(JobsName.COUNTRIES, true);
    await axiosPrivate
      .post('/general/countries')
      .then((response) => {
        const countriesResponse = response.data;
        const jobsDataUpdate = jobs.map((job) => {
          let jobsToReturn = { ...job };
          if (job.name === countriesResponse.name) {
            jobsToReturn = { ...countriesResponse, isLoading: false };
          }
          return jobsToReturn;
        });
        setJobs(jobsDataUpdate);
        setSnackbarState({
          title: '',
          message: 'Countries are updated',
          showSnackbar: true,
        });

        setTimeout(() => {
          setSnackbarState({ title: '', message: '', showSnackbar: false });
        }, 5000);
      })
      .catch((error) => {
        setLoadingButton(JobsName.COUNTRIES, false);
        if (error?.code === 'ERR_CANCELED') {
          return () => {
            source.cancel('Cancelling in cleanup');
          };
        }
        setErrorMessage('Error when updating countries');
      });
  };

  const updateCurrencies = async () => {
    const source = axios.CancelToken.source();
    setLoadingButton(JobsName.CURRENCIES, true);
    await axiosPrivate
      .post('/general/currencies')
      .then((response) => {
        const currenciesResponse = response.data;
        const jobsDataUpdate = jobs.map((job) => {
          let jobsToReturn = { ...job };
          if (job.name === currenciesResponse.name) {
            jobsToReturn = { ...currenciesResponse, isLoading: false };
          }
          return jobsToReturn;
        });
        setJobs(jobsDataUpdate);
        setSnackbarState({
          title: '',
          message: 'Currencies are updated',
          showSnackbar: true,
        });

        setTimeout(() => {
          setSnackbarState({ title: '', message: '', showSnackbar: false });
        }, 5000);
      })
      .catch((error) => {
        setLoadingButton(JobsName.CURRENCIES, false);
        if (error?.code === 'ERR_CANCELED') {
          return () => {
            source.cancel('Cancelling in cleanup');
          };
        }
        setErrorMessage('Error when updating currencies');
      });
  };

  const updateTSNOs = async () => {
    const source = axios.CancelToken.source();
    setLoadingButton(JobsName.TSNOS, true);
    await axiosPrivate
      .post('/general/tsnos')
      .then((response) => {
        const tsnosResponse = response.data;
        const jobsDataUpdate = jobs.map((job) => {
          let jobsToReturn = { ...job };
          if (job.name === tsnosResponse.name) {
            jobsToReturn = { ...tsnosResponse, isLoading: false };
          }
          return jobsToReturn;
        });
        setJobs(jobsDataUpdate);
        setSnackbarState({
          title: '',
          message: 'Tsnos are updated',
          showSnackbar: true,
        });

        setTimeout(() => {
          setSnackbarState({ title: '', message: '', showSnackbar: false });
        }, 5000);
      })
      .catch((error) => {
        setLoadingButton(JobsName.TSNOS, false);
        if (error?.code === 'ERR_CANCELED') {
          return () => {
            source.cancel('Cancelling in cleanup');
          };
        }
        setErrorMessage('Error when updating tsnos');
      });
  };

  const viewAll = (jobsName: string) => {
    switch (jobsName) {
      case JobsName.COUNTRIES:
        navigate(RouterPaths.adminToolsCountries);
        break;
      case JobsName.CURRENCIES:
        navigate(RouterPaths.adminToolsCurrencies);
        break;
      case JobsName.TSNOS:
        navigate(RouterPaths.adminToolsTSNOs);
        break;
      case JobsName.REPORTS:
        navigate(RouterPaths.adminToolsReports);
        break;
      default:
        break;
    }
  };

  const updateData = (jobsName: string) => {
    setErrorMessage('');
    switch (jobsName) {
      case JobsName.COUNTRIES:
        updateCountries();
        break;
      case JobsName.CURRENCIES:
        updateCurrencies();
        break;
      case JobsName.TSNOS:
        updateTSNOs();
        break;

      default:
        break;
    }
  };

  const formatDate = (date: string) => {
    const newDate = new Date(date).toLocaleString('sv-SE', {
      day: 'numeric',
      year: 'numeric',
      month: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
    return newDate;
  };

  return (
    <>
      <PageHeader pageTitle="Tools" />

      <div>
        {errorMessage && <h2 className="pl-2 pt-2">{errorMessage}</h2>}
        {jobs &&
          jobs.map((data) => (
            <div className={classes.container} key={data._id}>
              <h2>{data.name}</h2>
              <p>Items: {data.totalItems}</p>
              <div className={classes.updated}>
                <p>Updated: {formatDate(data.date)}</p>
                <p>Last updated by: {data.userName ? data.userName : '🖥️'}</p>
              </div>
              <div className={classes.buttons}>
                <PrognosButton
                  buttonStyleType={ButtonStyleType.secondary}
                  text="Show all"
                  type={ButtonType.button}
                  onClick={() => viewAll(data.name)}
                />
                {data.name !== JobsName.REPORTS && (
                  <PrognosButton
                    buttonStyleType={ButtonStyleType.primary}
                    text="Update"
                    type={ButtonType.button}
                    loading={data.isLoading}
                    onClick={() => updateData(data.name)}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
      <PrognosSnackbar
        title={snackbarState.title}
        message={snackbarState.message}
        showSnackbar={snackbarState.showSnackbar}
        snackbarType={SnackbarType.success}
      />
    </>
  );
};

export default ToolsPage;
