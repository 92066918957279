import deepCopy from 'deepcopy';
import XLSX from 'xlsx';
import { IReference } from '../types';
import { ChartLineExtended, ChartData as ChartDataPrognos } from '../types';
const getAllUniqueDate = async (datasets: ChartLineExtended[]) => {
  const datasetsCopy = deepCopy(datasets);
  const uniqueSortedDates = datasetsCopy
    .map((x) => x.data)
    .flat()
    .map((x) => x.x)
    .sort((a, b) => Date.parse(a.toString()) - Date.parse(b.toString()))
    .map((date) => new Date(date).getTime())
    .filter((date, i, array) => array.indexOf(date) === i)
    .map((time) => new Date(time));

  return uniqueSortedDates;
};
const parseToExcelData = async (chartData: ChartDataPrognos): Promise<(string | number | null)[][]> => {
  const excelData: (string | number | null)[][] = [];
  const allDates = await getAllUniqueDate(chartData.datasets);
  let rowIndex = 2;
  excelData[0] = [''];
  excelData[1] = [''];
  if (chartData.datasets) {
    await Promise.all(
      allDates.map(async (date) => {
        excelData[rowIndex] = [date.toLocaleDateString('sv-SE')];
        rowIndex += 1;
      })
    );
    await Promise.all(
      chartData.datasets.map(async (chartLine) => {
        rowIndex = 2;
        let dataSeriesIndex = 0;
        const allChartLineDates = chartLine.data.map((x) => new Date(x.x).getTime());
        excelData[0].push(chartLine.name);
        excelData[1].push(chartLine.id);
        await Promise.all(
          allDates.map(async (date) => {
            if (allChartLineDates.includes(date.getTime())) {
              excelData[rowIndex].push(chartLine.data[dataSeriesIndex]?.y);

              dataSeriesIndex += 1;
            } else {
              excelData[rowIndex].push('');
            }
            rowIndex += 1;
          })
        );
      })
    );
  }
  return excelData;
};

const saveFile = (newWorkbook: XLSX.WorkBook, fileName: string) => {
  XLSX.writeFile(newWorkbook, `${fileName}.xlsx`);
};
const createFileName = (
  urlNestedChartIds: string[],
  referenceState: IReference[],
  showCostDrivers: boolean,
  reportName: string
) => {
  let fileName = reportName;
  if (urlNestedChartIds) {
    urlNestedChartIds.forEach((urlNestedChartId) => {
      fileName += `_${urlNestedChartId}`;
    });
  }
  if (showCostDrivers) {
    fileName += '+cost-drivers';
  }
  if (referenceState) {
    let totalReferenceShown = 0;
    referenceState.forEach((reference) => {
      if (reference.checked) {
        totalReferenceShown += 1;
      }
    });
    if (totalReferenceShown !== 0) {
      fileName += `+reference ${totalReferenceShown > 1 ? 's' : ''}(${totalReferenceShown})`;
    }
  }
  return fileName;
};

const exportExcelFile = async (
  chartData: ChartDataPrognos,
  referenceState: IReference[],
  showCostDrivers: boolean,
  urlNestedChartIds: string[],
  reportName: string
): Promise<void> => {
  const fileName = createFileName(urlNestedChartIds, referenceState, showCostDrivers, reportName);

  const data = await parseToExcelData(chartData);

  const worksheet = XLSX.utils.aoa_to_sheet(data);

  const newWorkbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');

  saveFile(newWorkbook, fileName);
};

export default exportExcelFile;
