import { useContext, useDebugValue } from 'react';
import UserContext, { UserType } from '../context/UserProvider';

const useUser = () => {
  const userContext = useContext<UserType | undefined>(UserContext);
  useDebugValue(userContext, (authData) =>
    userContext?.userData?.customerUrl ? 'Have user data' : 'No user data'
  );
  return useContext(UserContext);
};

export default useUser;
