import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import Button, { ButtonType, ButtonStyleType } from '../../../ui/button/Button';
import TextField, { TextFieldType, ValidationRules } from '../../../ui/input/TextField';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

interface Rule {
  rule: string;
  ruleHelper?: string | number;
  errorText: string;
}

interface InputInterface {
  value: string;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}
interface FormElement {
  collectionName: InputInterface;
}
interface IsValidReturnType {
  hasError: boolean;
  errorText: string;
}
interface Props {
  showDialog: boolean;
  closeDialog: () => void;
  collectionAdded: () => void;
  customerId?: string;
  isShared?: boolean;
}

const NewCollectionDialog: React.FC<Props> = ({ showDialog, closeDialog, collectionAdded, customerId, isShared }) => {
  const [generalError, setGeneralError] = React.useState('');
  const axiosPrivate = useAxiosPrivate();

  const closeDialogHandler = () => {
    closeDialog();
  };

  const isInputValid = (value: string | number, inputRules: Rule[]): IsValidReturnType => {
    const returnValue = {
      hasError: false,
      errorText: '',
    };
    for (let index = 0; index < inputRules.length; index += 1) {
      if (inputRules[index].rule === ValidationRules.required && value === '') {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }
    }
    return returnValue;
  };

  const [createCollectionFormElement, updateInputFormElements] = useState<FormElement>({
    collectionName: {
      value: '',
      rules: [
        {
          rule: ValidationRules.required,
          errorText: 'Name cannot be empty',
        },
      ],
      error: false,
      errorText: '',
    },
  });
  const inputChangeHandler = (newValue: string | number, itemIdentifier: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createCustomerFormCopy: any = { ...createCollectionFormElement };
    const updatedElement = { ...createCustomerFormCopy[itemIdentifier] };

    updatedElement.value = newValue;
    if (updatedElement.rules) {
      const { hasError, errorText } = isInputValid(newValue, updatedElement.rules);
      updatedElement.error = hasError;
      updatedElement.errorText = hasError ? errorText : '';
    } else if (updatedElement.error) {
      updatedElement.error = false;
    }

    createCustomerFormCopy[itemIdentifier] = updatedElement;
    updateInputFormElements(createCustomerFormCopy);
  };

  const isFormValidFrontend = (): boolean => {
    let isValid = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createCustomerFormCopy: any = { ...createCollectionFormElement };

    Object.entries(createCollectionFormElement).forEach(([itemIdentifier, formElement]) => {
      const { hasError, errorText } = isInputValid(formElement.value, formElement.rules);

      if (hasError) {
        isValid = false;
        const updatedElement = { ...createCustomerFormCopy[itemIdentifier] };
        updatedElement.error = hasError;
        updatedElement.errorText = errorText;
        createCustomerFormCopy[itemIdentifier] = updatedElement;
      }
    });
    updateInputFormElements(createCustomerFormCopy);
    return isValid;
  };
  const createCollectionHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isFormValidFrontend()) {
      await axiosPrivate
        .post(
          '/collections',
          {
            isShared: isShared ?? false,
            isDraft: false,
            name: createCollectionFormElement.collectionName.value,
            customerId: customerId,
          },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        )
        .then(() => {
          collectionAdded();
          closeDialog();
        })
        .catch(() => {
          setGeneralError('Something went wrong');
        });
    }
  };
  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={showDialog} onClose={closeDialogHandler} aria-labelledby="New collection dialog">
        <form onSubmit={createCollectionHandler}>
          <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#f7f7f7' }}>
            New collection
          </DialogTitle>
          <hr />
          <DialogContent className="pt-5">
            <div>
              <TextField
                className="mb-3"
                id="collectionName"
                label="Name"
                error={createCollectionFormElement.collectionName.error}
                helperText={createCollectionFormElement.collectionName.errorText}
                type={TextFieldType.text}
                value={createCollectionFormElement.collectionName.value}
                valueChanged={(newValue) => inputChangeHandler(newValue, 'collectionName')}
              />
            </div>
            {generalError && (
              <div
                className="mt-3 "
                style={{
                  backgroundColor: '#FFCDD2',
                  border: '1px solid #EF9A9A',
                  padding: '8px',
                  borderRadius: '3px',
                }}
              >
                {generalError}
              </div>
            )}
          </DialogContent>
          <hr />
          <DialogActions className="mt-1 mb-1">
            <Button type={ButtonType.button} buttonStyleType={ButtonStyleType.secondary} text="Cancel" onClick={closeDialogHandler} />
            <Button type={ButtonType.submit} buttonStyleType={ButtonStyleType.primary} text="Create" onClick={createCollectionHandler} />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default NewCollectionDialog;
