import React, { useEffect, useState } from 'react';
import { Link, redirect, useLocation } from 'react-router-dom';
import Button, { ButtonType, ButtonStyleType } from '../../../ui/button/Button';
import TextField, { TextFieldType, ValidationRules } from '../../../ui/input/TextField';
import routerPaths from '../../../router/RouterPaths';
import classes from './ResetPassword.module.scss';
import logoType from '../../../assets/images/Prognos_tailored_logotyp_RGB.png';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

interface Rule {
  rule: string;
  ruleHelper?: string | number;
  errorText: string;
}
interface InputInterface {
  value: string;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}

interface FormElements {
  password: InputInterface;
  confirmationPassword: InputInterface;
}

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [userCode, setUserCode] = useState<null | string>('');
  const [userEmail, setUserEmail] = useState('');
  const [setup, setSetup] = useState(false);

  const { search } = useLocation();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const checkResetPasswordCode = async () => {
      const oobCode = new URLSearchParams(search).get('oobCode');
      if (oobCode) {
        await axiosPrivate
          .post(
            `auth/verifyPasswordResetCode/`,
            { oobCode },
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              const userEmail = response.data;
              setUserEmail(userEmail);
              setUserCode(oobCode);
            }
          })
          .catch((error) => {
            setErrorMessage('The reset code is invalid');
            setSetup(true);
          });
      }
      if (!oobCode) setErrorMessage('The reset code is invalid');
      setSetup(true);
    };
    checkResetPasswordCode();
  }, [axiosPrivate, search]);

  const [formElements, updateInputFormElements] = useState<FormElements>({
    password: {
      value: '',
      rules: [
        {
          rule: ValidationRules.required,
          errorText: 'Please choose a password that is longer than 6 characters.',
        },
        {
          rule: ValidationRules.minLength,
          ruleHelper: 6,
          errorText: 'Please choose a password that is longer than 6 characters.',
        },
        {
          rule: ValidationRules.backend,
          errorText: 'Please choose a password that is longer than 6 characters.',
        },
      ],
      error: false,
      helpText: 'Minimum 6 characters',
      errorText: '',
      errorMessageBackend: 'Minimum 6 characters',
    },
    confirmationPassword: {
      value: '',
      rules: [
        {
          rule: ValidationRules.sameValue,
          errorText: 'Your password and confirmation password do not match.',
        },
      ],
      error: false,
      errorText: '',
    },
  });
  const isInputValid = (value: string | number, inputRules: Rule[]): IsValidReturnType => {
    const returnValue = {
      hasError: false,
      errorText: '',
    };

    if (!inputRules || (inputRules.length === 1 && inputRules[0].rule === ValidationRules.backend)) {
      return returnValue;
    }
    for (let index = 0; index < inputRules.length; index += 1) {
      if (inputRules[index].rule === ValidationRules.required && value === '') {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }

      if (inputRules[index].rule === ValidationRules.minLength && Number(inputRules[index].ruleHelper) > value.toString().length) {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }
      if (inputRules[index].rule === ValidationRules.minLength && Number(inputRules[index].ruleHelper) > value.toString().length) {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }
      if (inputRules[index].rule === ValidationRules.sameValue && value !== formElements.password.value) {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }
    }
    return returnValue;
  };

  const inputChangeHandler = (newValue: string | number, itemIdentifier: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formElementsCopy: any = { ...formElements };
    const updatedElement = { ...formElementsCopy[itemIdentifier] };

    updatedElement.value = newValue;
    if (updatedElement.rules) {
      const { hasError, errorText } = isInputValid(newValue, updatedElement.rules);
      updatedElement.error = hasError;
      updatedElement.errorText = hasError ? errorText : '';
    } else if (updatedElement.error) {
      updatedElement.error = false;
    }

    formElementsCopy[itemIdentifier] = updatedElement;
    updateInputFormElements(formElementsCopy);
  };

  const isFormValidFrontend = (): boolean => {
    let isValid = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formElementsCopy: any = { ...formElements };

    Object.entries(formElements).forEach(([itemIdentifier, formElement]) => {
      const { hasError, errorText } = isInputValid(formElement.value, formElement.rules);

      if (hasError) {
        isValid = false;
        const updatedElement = { ...formElementsCopy[itemIdentifier] };
        updatedElement.error = hasError;
        updatedElement.errorText = errorText;
        formElementsCopy[itemIdentifier] = updatedElement;
      }
    });
    updateInputFormElements(formElementsCopy);
    return isValid;
  };

  const submitFormHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    if (isFormValidFrontend() && userCode) {
      await axiosPrivate
        .post(
          `auth/confirmPasswordReset/`,
          { oobCode: userCode, newPassword: formElements.password.value },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        )
        .then((response) => {
          setSuccessMessage('Password has successfully been updated');
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage('The reset code is invalid');
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  interface IsValidReturnType {
    hasError: boolean;
    errorText: string;
  }

  useEffect(() => {
    if (userCode === null) {
      redirect(routerPaths.login);
    }
  }, [userCode]);

  return (
    <>
      <div className={classes.logotypeContainer}>
        <img src={logoType} className={`${classes.logotype} mb-6`} alt="logo" />
      </div>
      <hr />
      <h1 className={`${classes.titleText} mt-3 mb-3`}>Reset password</h1>
      {userEmail && !successMessage && <p className="mt-4 mb-4">Enter your new password for e-mail address {userEmail}.</p>}
      {successMessage && (
        <>
          <div
            className="mt-3"
            style={{
              backgroundColor: '#edf7ed',
              border: '1px solid #9cd39c',
              color: '#163418',
              padding: '8px',
              borderRadius: '3px',
            }}
          >
            {successMessage}
          </div>
          <Link to={routerPaths.login} className="floatRight">
            <Button className="mt-3" type={ButtonType.button} buttonStyleType={ButtonStyleType.primary} text="Go to login page" />
          </Link>
        </>
      )}
      {!successMessage && (
        <>
          {errorMessage && (
            <div
              className="mt-3"
              style={{
                backgroundColor: '#FFCDD2',
                border: '1px solid #EF9A9A',
                padding: '8px',
                borderRadius: '3px',
              }}
            >
              {errorMessage}
            </div>
          )}
          {setup && !userEmail && (
            <div className={classes.footer}>
              <Link to={routerPaths.forgotPassword}>Forgot your password?</Link>
              <Link to={routerPaths.login} className="floatRight">
                <Button className="mt-3" type={ButtonType.button} buttonStyleType={ButtonStyleType.primary} text="Go to login page" />
              </Link>
            </div>
          )}
          {setup && userEmail && (
            <form onSubmit={submitFormHandler}>
              <TextField
                className="mb-4 mt-4"
                id="password"
                label="Password"
                error={formElements.password.error}
                helperText={formElements.password.errorText}
                type={TextFieldType.password}
                value={formElements.password.value}
                valueChanged={(newValue) => inputChangeHandler(newValue, 'password')}
              />
              <TextField
                className="mb-3"
                id="confirmationPassword"
                label="Confirm password"
                error={formElements.confirmationPassword.error}
                helperText={formElements.confirmationPassword.errorText}
                type={TextFieldType.password}
                value={formElements.confirmationPassword.value}
                valueChanged={(newValue) => inputChangeHandler(newValue, 'confirmationPassword')}
              />
              <div className={`${classes.footer} mt-4`}>
                <Link to={routerPaths.login}>Go to sign in</Link>
                <div className="floatRight">
                  <Button type={ButtonType.submit} buttonStyleType={ButtonStyleType.primary} loading={loading} text="Reset password" />
                </div>
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
};
export default ResetPassword;
