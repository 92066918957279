import React from 'react';
import { Link } from 'react-router-dom';
import routerPaths from '../../../../router/RouterPaths';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  Table,
  TableBody,
} from '@mui/material';
import Button, {
  ButtonStyleType,
  ButtonType,
} from '../../../../ui/button/Button';
import classes from './CustomersTable.module.scss';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<T, Key extends keyof T>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface TableHeader {
  id: keyof TableItem;
  name: string;
  sortable: boolean;
  numeric: boolean;
}
export interface TableItem {
  id: string;
  name: string;
  customerUrl: string;
}

interface TableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TableItem
  ) => void;
  order: Order;
  orderBy: string;
  tableHeaders: TableHeader[];
}

function TableHeadData(props: TableProps) {
  const { order, orderBy, onRequestSort, tableHeaders } = props;
  const createSortHandler =
    (property: keyof TableItem) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {tableHeaders.map((tableHeader) => (
          <TableCell
            key={tableHeader.id}
            align={tableHeader.numeric ? 'right' : 'left'}
            sortDirection={orderBy === tableHeader.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === tableHeader.id}
              direction={orderBy === tableHeader.id ? order : 'asc'}
              onClick={createSortHandler(tableHeader.id)}
            >
              <span className={classes.tableHead}> {tableHeader.name}</span>
              {orderBy === tableHeader.id ? (
                <span className={classes.visuallyHidden}>
                  {/*   {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

interface Props {
  tableHeaders: TableHeader[];
  tableItems: TableItem[];
}

const TableComponent: React.FC<Props> = (props) => {
  const { tableHeaders, tableItems } = { ...props };

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof TableItem>('name');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TableItem
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper className="mb-3 prognosTable">
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="Customer table"
          aria-label="Customer table"
        >
          <TableHeadData
            tableHeaders={tableHeaders}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(tableItems, getComparator(order, orderBy)).map(
              (row) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.customerUrl}</TableCell>
                  <TableCell align="right">
                    <Link
                      to={routerPaths.adminCustomer.replace(
                        ':customerUrl',
                        row.customerUrl
                      )}
                    >
                      <Button
                        small
                        type={ButtonType.button}
                        buttonStyleType={ButtonStyleType.secondary}
                        text="View"
                      />
                    </Link>
                  </TableCell>
                </TableRow>
              )
            )}
            {tableItems && tableItems.length === 0 && (
              <TableRow>
                <TableCell
                  style={{ color: '#19555a' }}
                  colSpan={5}
                  className="text-center"
                >
                  <div className="text-center">Empty</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableComponent;
