import { useState } from 'react';
import { AuthData } from '../context/AuthProvider';
import { axiosPublic } from '../plugins/Axios';
import useAuth from './useAuth';
import jwt_decode from 'jwt-decode';
import { ROLE } from '../context/Roles.enum';
import axios from 'axios';

export type JwtPayload = {
  iat: number;
  exp: number;
  jwtPayload: {
    sub: string;
    roles: ROLE[];
    userId: string;
    customerId: string;
  };
};

const useRefreshToken = () => {
  const useAuthData = useAuth();
  const [updatingState, setUpdatingState] = useState(false);
  if (!useAuthData) return;
  const { saveAuthData } = useAuthData;
  if (updatingState) return;
  const refresh = async () => {
    try {
      setUpdatingState(true);
      const response = await axiosPublic.post('/auth/refreshTokens');
      const payload: JwtPayload = jwt_decode(response.data.accessToken);
      localStorage.setItem('accessToken', response.data.accessToken);
      const authData: AuthData = {
        accessToken: response.data.accessToken,
        exp: payload.exp,
        iat: payload.iat,
        ...payload.jwtPayload,
      };
      saveAuthData(authData);
      setUpdatingState(false);
      return response.data.accessToken;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          useAuthData?.logoutUser();
        }
      } else {
        // TODO log error
      }
    }
  };

  return refresh;
};

export default useRefreshToken;
