import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import TextField, { TextFieldType } from '../../../ui/input/TextField';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useRefreshToken from '../../../hooks/useRefreshToken';

interface Props {
  showDialog: boolean;
  closeDialog: () => void;
}

const AddInvitationKeyDialog: React.FC<Props> = ({ showDialog, closeDialog }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [generalErrorState, setGeneralErrorState] = useState('');
  const axiosPrivate = useAxiosPrivate();
  const refreshToken = useRefreshToken();

  const resetValues = () => {
    setGeneralErrorState('');
    setInputValue('');
    setIsLoading(false);
  };
  const closeDialogHandler = () => {
    closeDialog();
    resetValues();
  };

  const AddInvitation = async () => {
    setGeneralErrorState('');
    setIsLoading(true);
    await axiosPrivate
      .post(
        '/users/updateCompanyForUser',
        {
          invitationKey: inputValue,
        },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .then(async (response) => {
        if (response.status === 200) {
          if (refreshToken) {
            await refreshToken();
          }
          window.location.reload();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setGeneralErrorState('Invalid invitation key');
        setIsLoading(false);
      });
  };

  const updateNewValue = (newValue: string) => {
    setInputValue(newValue);
    setGeneralErrorState('');
  };
  return (
    <Dialog fullWidth maxWidth="xs" open={showDialog} onClose={closeDialogHandler} aria-labelledby="Add invitation key">
      <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#f7f7f7' }}>
        Add invitation key
      </DialogTitle>
      <DialogContent className="pt-5">
        <TextField
          className="mb-3"
          label="Invitation key"
          id="invitationKey"
          value={inputValue}
          type={TextFieldType.text}
          valueChanged={(newValue) => updateNewValue(newValue)}
        />
        {generalErrorState && (
          <div
            className="mt-3 "
            style={{
              backgroundColor: '#FFCDD2',
              border: '1px solid #EF9A9A',
              padding: '8px',
              borderRadius: '3px',
            }}
          >
            {generalErrorState}
          </div>
        )}
      </DialogContent>
      <hr />
      <DialogActions className="mt-1 mb-1">
        <Button
          type={ButtonType.button}
          buttonStyleType={ButtonStyleType.secondary}
          text="Cancel"
          onClick={closeDialogHandler}
        />
        <Button
          loading={isLoading}
          type={ButtonType.submit}
          buttonStyleType={ButtonStyleType.primary}
          text="Add"
          onClick={AddInvitation}
        />
      </DialogActions>
    </Dialog>
  );
};

AddInvitationKeyDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default AddInvitationKeyDialog;
