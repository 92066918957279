import { createContext, FC, ReactNode, useState } from 'react';

export const UserContext = createContext<UserType | undefined>(undefined);

export interface UserData {
  email: string;
  customerName: string;
  customerUrl: string;
}

export type UserType = {
  userData: UserData;
  updateUserData: (userData: UserData) => void;
  removeUserData: () => void;
};

const defaultUserData: UserData = {
  email: '',
  customerName: '',
  customerUrl: '',
};
type Props = {
  children?: ReactNode;
};

export const UserProvider: FC<Props> = ({ children }) => {
  const [userData, setUserData] = useState<UserData>(defaultUserData);

  const removeUserData = () => {
    updateUserData(defaultUserData);
  };

  const updateUserData = (user: UserData) => {
    const newUserInfo: UserData = {
      email: user.email,
      customerName: user.customerName,
      customerUrl: user.customerUrl,
    };
    setUserData(newUserInfo);
  };

  return (
    <UserContext.Provider value={{ userData, updateUserData, removeUserData }}>
      {children}
    </UserContext.Provider>
  );
};
export default UserContext;
