const RouterPaths = {
  login: '/',
  dashboard: '/dashboard',
  register: '/register',
  forgotPassword: '/forgotPassword',
  resetPassword: '/resetPassword',
  reports: '/reports',
  reportCollection: '/reports/collection/:collectionId',
  reportShared: '/reports/report/shared/:reportId',
  report: '/reports/report/:customerUrl/:reportUrl',
  reportEdit: '/reports/report/:reportUrl/edit',
  adminCustomers: '/admin/customers',
  adminSharedCollections: '/admin/shared-collections',
  adminTools: '/admin/tools',
  adminToolsCountries: '/admin/tools/countries',
  adminToolsCurrencies: '/admin/tools/currencies',
  adminToolsTSNOs: '/admin/tools/tsnos',
  adminToolsReports: '/admin/tools/reports',
  adminSharedReports: '/admin/shared-reports',
  adminCustomer: '/admin/customers/:customerUrl',
  adminCustomerCollections: '/admin/customer/:customerUrl/customer-collections',
  adminCustomerUsers: '/admin/customer/:customerUrl/users',
  adminCustomerInvitationKey: '/admin/customer/:customerUrl/invitation-key',
};

export default RouterPaths;
