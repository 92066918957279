import React from 'react';
import { NavLink } from 'react-router-dom';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import { SvgIcon } from '@mui/material';
import useAdminCustomer from '../../hooks/useAdminCustomer';
import routerPaths from '../../router/RouterPaths';

const MenuList: React.FC = () => {
  const useAdminCustomerContext = useAdminCustomer();

  const menuLinks = {
    customers: routerPaths.adminCustomers,
    sharedReports: routerPaths.adminSharedReports,
    sharedCollections: routerPaths.adminSharedCollections,
    tools: routerPaths.adminTools,
  };

  return (
    <nav>
      <ul>
        <li>
          <NavLink to={menuLinks.customers}>
            <SvgIcon component={BusinessOutlinedIcon} />
            Customers
          </NavLink>
          {useAdminCustomerContext?.adminCustomerData?.customerId && (
            <ul>
              <li>
                <NavLink
                  to={routerPaths.adminCustomer.replace(
                    ':customerUrl',
                    useAdminCustomerContext?.adminCustomerData?.customerUrl
                  )}
                >
                  <SvgIcon component={HouseOutlinedIcon} />
                  {useAdminCustomerContext?.adminCustomerData?.customerName}
                </NavLink>
              </li>
            </ul>
          )}
        </li>
        <li>
          <NavLink to={menuLinks.sharedReports}>
            <SvgIcon component={ArticleOutlinedIcon} />
            Shared reports
          </NavLink>
        </li>
        <li>
          <NavLink to={menuLinks.sharedCollections}>
            <SvgIcon component={CollectionsBookmarkOutlinedIcon} />
            Shared collections
          </NavLink>
        </li>
        <li>
          <NavLink to={menuLinks.tools}>
            <SvgIcon component={BuildOutlinedIcon} />
            Tools
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MenuList;
