import PropTypes from 'prop-types';
import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import classes from './BredCrumb.module.scss';

export interface BreadcrumbInterface {
  name: string;
  linkTo: string;
}
interface Props {
  breadcrumb: BreadcrumbInterface[];
}

const BredCrumb: React.FC<Props> = (props) => {
  const { breadcrumb } = props;

  const breadcrumbElements: JSX.Element[] | null = [];
  if (breadcrumb) {
    for (let index = 0; index < breadcrumb.length; index += 1) {
      if (breadcrumb.length === index + 1) {
        breadcrumbElements.push(
          <div
            key={breadcrumb[index].linkTo}
            className={classes.breadcrumbCurrent}
          >
            {breadcrumb[index].name}
          </div>
        );
      } else {
        breadcrumbElements.push(
          <Link key={breadcrumb[index].linkTo} to={breadcrumb[index].linkTo}>
            {breadcrumb[index].name}
          </Link>
        );
      }
    }
  }

  return (
    <div>
      {breadcrumbElements.length > 1 && (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbElements}
        </Breadcrumbs>
      )}
    </div>
  );
};

BredCrumb.propTypes = {
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      linkTo: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default BredCrumb;
