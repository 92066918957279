import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routerPaths from './RouterPaths';
/* import { RootStore } from '../store'; */
import { ROLE } from '../context/Roles.enum';

import LoginPage from '../pages/Auth/Login/LoginPage';
import RegisterPage from '../pages/Auth/Register/RegisterPage';
import ForgotPassword from '../pages/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword/ResetPassword';

import ReportsPage from '../pages/Reports/ReportsPage';
import CollectionPage from '../pages/Reports/Collection/CollectionPage';
import ReportPage from '../pages/Reports/Report/ReportPage';
import CustomersPage from '../pages/Admin/Customers/CustomersPage';
import AdminCustomerPage from '../pages/Admin/Customer/AdminCustomerPage';
import ToolsPage from '../pages/Admin/Tools/ToolsPage';
import CountriesPage from '../pages/Admin/Tools/Countries/CountriesPage';
import CurrenciesPage from '../pages/Admin/Tools/Currencies/CurrenciesPage';
import ToolsReportsPage from '../pages/Admin/Tools/Reports/ToolsReports';

import TSNOsPage from '../pages/Admin/Tools/TSNOs/TSNOsPage';
/* import AdminReportPage from '../pages/Auth/Login/LoginPage'; // '../containers/Admin/AdminReport/AdminReportPage'; */
import AuthRouteCheck from './AuthRouteCheck';
import SharedReportsPage from '../pages/Admin/SharedReports/SharedReportsPage';
import SharedCollections from '../pages/Admin/SharedCollections/SharedCollections';
import Layout from '../components/layouts/layout/Layout';
import AuthenticatedLayoutAdmin from '../components/layouts/authenticatedLayoutAdmin/AuthenticatedLayoutAdmin';

import AuthenticatedLayout from '../components/layouts/authenticatedLayout/AuthenticatedLayout';
import useAuth from '../hooks/useAuth';

import EditReport from '../pages/Admin/EditReport/EditReport';

const Router: React.FC = () => {
  const auth = useAuth();

  const routes = (
    <React.StrictMode>
      <Routes>
        {!auth?.authData.userId && (
          <Route path="/" element={<Layout />}>
            <Route path={routerPaths.login} element={<LoginPage />} />
            <Route path={routerPaths.register} element={<RegisterPage />} />
            <Route path={routerPaths.forgotPassword} element={<ForgotPassword />} />
            <Route path={routerPaths.resetPassword} element={<ResetPassword />} />
          </Route>
        )}
        {/*  // Routes for users */}
        <Route path="/" element={<AuthenticatedLayout />}>
          <Route element={<AuthRouteCheck allowedRoles={[ROLE.USER]} />}>
            <Route path="/" element={<ReportsPage />} />
          </Route>
          <Route element={<AuthRouteCheck allowedRoles={[ROLE.USER]} />}>
            <Route path={routerPaths.reports} element={<ReportsPage />} />
          </Route>
          <Route element={<AuthRouteCheck allowedRoles={[ROLE.USER]} />}>
            <Route path={routerPaths.reportCollection} element={<CollectionPage />} />
          </Route>
          <Route element={<AuthRouteCheck allowedRoles={[ROLE.USER]} />}>
            <Route path={routerPaths.report} element={<ReportPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
          {/*  // Routes for admins */}
        </Route>
        <Route path="/" element={<AuthenticatedLayoutAdmin />}>
          <Route element={<AuthRouteCheck allowedRoles={[ROLE.ADMIN]} />}>
            <Route path={routerPaths.reportEdit} element={<EditReport />} />
            <Route path="admin">
              <Route path={routerPaths.adminTools} element={<ToolsPage />} />
              <Route path="tools">
                <Route path={routerPaths.adminToolsCountries} element={<CountriesPage />} />
                <Route path={routerPaths.adminToolsCurrencies} element={<CurrenciesPage />} />
                <Route path={routerPaths.adminToolsTSNOs} element={<TSNOsPage />} />
                <Route path={routerPaths.adminToolsReports} element={<ToolsReportsPage />} />
              </Route>
              <Route path="customers">
                <Route path={routerPaths.adminCustomers} element={<CustomersPage />} />
                <Route path={routerPaths.adminCustomer} element={<AdminCustomerPage />} />
              </Route>
              <Route path={routerPaths.adminSharedReports} element={<SharedReportsPage />} />
              <Route path={routerPaths.adminSharedCollections} element={<SharedCollections />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.StrictMode>
  );

  return routes;
};

export default Router;
