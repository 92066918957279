import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import TextField, { TextFieldType, ValidationRules } from '../../../ui/input/TextField';
import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

interface Props {
  showDialog: boolean;
  customerId?: string;
  closeDialog: () => void;
  reportCreated: () => void;
  isShared?: boolean;
}
interface IsValidReturnType {
  hasError: boolean;
  errorText: string;
}
interface Rule {
  rule: string;
  ruleHelper?: string | number;
  errorText: string;
}
interface InputInterface {
  value: string;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}
interface FormElement {
  reportName: InputInterface;
}
const NewReportDialog: React.FC<Props> = ({ showDialog, closeDialog, reportCreated, customerId, isShared }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [generalErrorState, setGeneralErrorState] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [createReportFormElementState, setCreateReportFormElementState] = useState<FormElement>({
    reportName: {
      value: '',
      rules: [
        {
          rule: ValidationRules.required,
          errorText: 'Name cannot be empty',
        },
      ],
      error: false,
      errorText: '',
    },
  });

  const axiosPrivate = useAxiosPrivate();

  const isInputValid = (value: string | number, inputRules: Rule[]): IsValidReturnType => {
    const returnValue = {
      hasError: false,
      errorText: '',
    };
    for (let index = 0; index < inputRules.length; index += 1) {
      if (inputRules[index].rule === ValidationRules.required && value === '') {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }
    }
    return returnValue;
  };
  const isFormValidFrontend = (): boolean => {
    let isValid = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createReportFormElementStateCopy: any = {
      ...createReportFormElementState,
    };

    Object.entries(createReportFormElementState).forEach(([itemIdentifier, formElement]) => {
      const { hasError, errorText } = isInputValid(formElement.value, formElement.rules);

      if (hasError) {
        isValid = false;
        const updatedElement = {
          ...createReportFormElementStateCopy[itemIdentifier],
        };
        updatedElement.error = hasError;
        updatedElement.errorText = errorText;
        createReportFormElementStateCopy[itemIdentifier] = updatedElement;
      }
    });
    setCreateReportFormElementState(createReportFormElementStateCopy);
    return isValid;
  };
  const resetFormValues = () => {
    const createReportFormElementStateCopy = {
      ...createReportFormElementState,
    };
    createReportFormElementStateCopy.reportName.value = '';
    createReportFormElementStateCopy.reportName.errorText = '';
    createReportFormElementStateCopy.reportName.error = false;
    setCreateReportFormElementState(createReportFormElementStateCopy);
  };
  const closeDialogHandler = () => {
    resetFormValues();
    closeDialog();
  };

  async function createReport(reportName: string): Promise<number> {
    const response = await axiosPrivate
      .post(
        '/reports',
        {
          reportName: reportName,
          isShared: isShared,
          customerId: customerId,
        },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .then((response) => {
        return {
          statusCode: response.status,
          message: response.data,
        };
      })
      .catch((error) => {
        return {
          statusCode: error.status,
          message: error.response,
        };
      });

    return response.statusCode;
  }

  const createNewReportHandler = async (event: React.FormEvent) => {
    setIsLoading(true);
    setGeneralErrorState('');
    event.preventDefault();
    if (isFormValidFrontend()) {
      const reportName = createReportFormElementState.reportName.value;
      const responseStatusCode = await createReport(reportName);

      if (responseStatusCode !== 201) {
        setGeneralErrorState('Something went wrong');
        setIsLoading(false);
        return;
      }

      reportCreated();
      resetFormValues();
      closeDialogHandler();
    }
    setIsLoading(false);
  };

  const inputChangeHandler = (newValue: string | number, itemIdentifier: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createCustomerFormCopy: any = { ...createReportFormElementState };
    const updatedElement = { ...createCustomerFormCopy[itemIdentifier] };

    updatedElement.value = newValue;
    if (updatedElement.rules) {
      const { hasError, errorText } = isInputValid(newValue, updatedElement.rules);
      updatedElement.error = hasError;
      updatedElement.errorText = hasError ? errorText : '';
    } else if (updatedElement.error) {
      updatedElement.error = false;
    }

    createCustomerFormCopy[itemIdentifier] = updatedElement;
    setCreateReportFormElementState(createCustomerFormCopy);
  };
  return (
    <Dialog fullWidth maxWidth="xs" open={showDialog} onClose={closeDialogHandler} aria-labelledby="Create report">
      <form onSubmit={createNewReportHandler}>
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#f7f7f7' }}>
          Basic info
        </DialogTitle>
        <DialogContent className="pt-5">
          <TextField
            className="mb-3"
            id="reportName"
            label="Report name"
            error={createReportFormElementState.reportName.error}
            helperText={createReportFormElementState.reportName.errorText}
            type={TextFieldType.text}
            value={createReportFormElementState.reportName.value}
            valueChanged={(newValue) => inputChangeHandler(newValue, 'reportName')}
          />
          {generalErrorState && (
            <div
              className="mt-3 "
              style={{
                backgroundColor: '#FFCDD2',
                border: '1px solid #EF9A9A',
                padding: '8px',
                borderRadius: '3px',
              }}
            >
              {generalErrorState}
            </div>
          )}
        </DialogContent>
        <hr />
        <DialogActions className="mt-1 mb-1">
          <Button
            type={ButtonType.button}
            buttonStyleType={ButtonStyleType.secondary}
            text="Cancel"
            onClick={closeDialogHandler}
          />
          <Button
            loading={isLoading}
            type={ButtonType.submit}
            buttonStyleType={ButtonStyleType.primary}
            text="Create"
            onClick={createNewReportHandler}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewReportDialog;
