import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';

import { mdiLogout, mdiMenuDown, mdiKeyVariant, mdiFileChartOutline } from '@mdi/js';
import Icon from '@mdi/react';

import classes from './Header.module.scss';
import routerPaths from '../../../router/RouterPaths';
import PrognosButton, { ButtonType, ButtonStyleType } from '../../../ui/button/Button';
import logoType from '../../../assets/images/prognos_logotyp_white.png';
import { Button, Drawer, Menu, MenuItem, SvgIcon } from '@mui/material';
import SettingsIcon from '@mui/icons-material/SettingsSuggest';

import useAuth from '../../../hooks/useAuth';
import useUser from '../../../hooks/useUser';

import { ROLE } from '../../../context/Roles.enum';
import AddInvitationKeyDialog from '../authenticatedLayout/AddInvitationKeyDialog';
import MenuList from '../MenuList';

interface Props {
  fullWidth?: boolean;
}

const Header: React.FC<Props> = ({ fullWidth }) => {
  const navigate = useNavigate();

  const authContext = useAuth();
  const userContext = useUser();

  const [showUserMenu, setShowUserMenu] = React.useState<null | HTMLElement>(null);

  const [showPhoneNavigation, updateShowPhoneNavigation] = useState(false);
  const [showSettings, updateShowSettings] = useState(false);
  const [isAddInvitationKeyDialogOpen, setIsAddInvitationKeyDialogOpen] = useState(false);

  const logoutHandler = () => {
    authContext?.logoutUser();
  };

  const toggleMenu = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    updateShowPhoneNavigation(open);
  };

  const toggleSettings = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    updateShowSettings(open);
  };

  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowUserMenu(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setShowUserMenu(null);
  };
  const logoTypeOutPut = (
    <div className={classes.logotypeContainer}>
      <Link to="/">
        <img src={logoType} className={classes.logotype} alt="logo" />
      </Link>
    </div>
  );

  const goToLink = (link: string) => {
    navigate(link);
  };

  const menuItemLink = (isAdmin: boolean) => {
    let returnValue = {
      to: routerPaths.reports,
      text: 'Reports',
      onClick: () => undefined,
    };
    if (isAdmin) {
      returnValue = {
        to: routerPaths.adminCustomers,
        text: 'Admin customers',
        onClick: () => undefined,
      };
    }

    return returnValue;
  };

  const openAddCustomerKeyDialog = () => {
    setIsAddInvitationKeyDialogOpen(true);
    setShowUserMenu(null);
  };

  const closeAddInvitationKeyDialog = () => {
    setIsAddInvitationKeyDialogOpen(false);
  };

  const isDemoCustomer = (customerId: string | null) => {
    // todo add const for demo company
    customerId = 'demo';
    if (customerId === 'demo' || !authContext?.authData.roles.includes(ROLE.ADMIN)) {
      return (
        <MenuItem onClick={openAddCustomerKeyDialog}>
          <Icon path={mdiKeyVariant} title="Add invitation key" size={1} className="mr-3" />
          Add invitation key
        </MenuItem>
      );
    }
    return null;
  };

  const mainLink = menuItemLink(!!authContext?.authData?.roles.includes(ROLE.ADMIN));
  const menuItems = (
    <>
      <Link to={mainLink.to} onClick={mainLink.onClick} className={`${classes.headerLink}`}>
        {mainLink.text}
      </Link>

      <PrognosButton
        ria-controls="customized-menu"
        aria-haspopup="true"
        className={classes.logoutButton}
        type={ButtonType.button}
        buttonStyleType={ButtonStyleType.primary}
        onClick={handleUserMenuClick}
        round
      >
        <Icon path={mdiMenuDown} title="User menu" size={1.3} />
      </PrognosButton>
      <Menu
        anchorEl={showUserMenu}
        keepMounted
        open={Boolean(showUserMenu)}
        onClose={handleUserMenuClose}
        className={classes.desktopMenuList}
      >
        {isDemoCustomer(authContext?.authData?.customerId || '')}
        <MenuItem onClick={logoutHandler}>
          <Icon path={mdiLogout} title="Logout" size={1} className="mr-3" /> Logout
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <header className={classes.header}>
        <div className={`${classes.container} ${fullWidth ? '' : classes.containerWidth}`}>
          <Button aria-label="Open menu" className={classes.leftMenuOpen}>
            <SvgIcon onClick={toggleMenu(true)} component={MenuIcon} />
          </Button>

          <Drawer
            onClick={toggleMenu(false)}
            variant="persistent"
            transitionDuration={0}
            elevation={0}
            open={showPhoneNavigation}
            anchor="left"
          >
            <div className={classes.menuItemsPhone}>
              <Button aria-label="Close menu" className={classes.leftMenuClose}>
                <SvgIcon onClick={toggleMenu(false)} component={Close} />
              </Button>

              <MenuList />
            </div>
          </Drawer>
          <div className={classes.logoWithText}>
            {logoTypeOutPut}
            <span className={classes.customer}>{userContext?.userData.customerName || ''}</span>
          </div>
          <div className={classes.desktopMenu}>{menuItems}</div>
          <Button aria-label="Open menu" className={classes.kebabMenu}>
            <SvgIcon onClick={toggleSettings(true)} component={SettingsIcon} />
          </Button>
        </div>
        <Drawer
          onClick={toggleSettings(false)}
          variant="persistent"
          transitionDuration={0}
          elevation={0}
          open={showSettings}
          anchor="top"
        >
          <div className={classes.settings}>
            <div className="floatRight">
              <Button aria-label="Close menu" className={classes.kebabMenu}>
                <SvgIcon onClick={toggleSettings(false)} component={Close} />
              </Button>
            </div>
            {logoTypeOutPut}
            <MenuItem style={{ width: '100%' }} onClick={() => goToLink(mainLink.to)}>
              <Icon path={mdiFileChartOutline} title="Logout" size={1} className="mr-3" />
              {mainLink.text}
            </MenuItem>
            {isDemoCustomer(authContext?.authData?.customerId || '')}
            <MenuItem onClick={logoutHandler} className={classes.phoneLink}>
              <Icon path={mdiLogout} title="Logout" size={1} className="mr-3" /> Logout
            </MenuItem>
          </div>
        </Drawer>
      </header>
      <AddInvitationKeyDialog closeDialog={closeAddInvitationKeyDialog} showDialog={isAddInvitationKeyDialogOpen} />
    </>
  );
};

export default Header;
