import React from 'react';
import Header from './Header';
import LeftMenu from '../leftMenu/LeftMenu';

const Layout: React.FC = () => {
  return (
    <>
      <Header fullWidth={true} />
      <LeftMenu />
    </>
  );
};

export default Layout;
