import React from 'react';

import { TableContainer, TableHead, TableRow, TableCell, TableSortLabel, Paper, Table, TableBody } from '@mui/material';
import classes from './ReportsTable.module.scss';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

enum REPORT_TYPE {
  SI = 'SI',
  STC = 'STC',
  CC = 'CC',
}
export interface IReport {
  _id: string;
  name: string;
  country: string;
  currency: string;
  isDraft: boolean;
  isShared: boolean;
  inCollections: string[];
  reportUrl: string;
  isReportValid: boolean;
  hideReportFromUserReportList: boolean;
  reportType?: REPORT_TYPE.CC | REPORT_TYPE.SI | REPORT_TYPE.STC;
  firstDatePointDate: string;
  lastDataPointDate: string;
  lastDateUpdatedDataFromPdm?: Date;
  lastDateChangedReportRequestData?: Date;
}

type Order = 'asc' | 'desc';

function getComparator<T, Key extends keyof T>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string | JSX.Element }, b: { [key in Key]: number | string | JSX.Element }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface TableHeader {
  id: keyof TableItem;
  name: string;
  sortable: boolean;
  numeric: boolean;
}
export interface TableItem {
  id: string;
  name: string;
  country: string;
  currency: string;
  hidden: string;
  firstDatePointDate: string;
  lastDataPointDate: string;
  isReportValid: JSX.Element;
  actions: JSX.Element;
}

interface TableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableItem) => void;
  order: Order;
  orderBy: string;
  tableHeaders: TableHeader[];
  hasActions: boolean;
}

function TableHeadData(props: TableProps) {
  const { order, orderBy, onRequestSort, tableHeaders, hasActions } = props;
  const createSortHandler = (property: keyof TableItem) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHeaders.map((tableHeader) => (
          <TableCell
            key={tableHeader.id}
            align={tableHeader.numeric ? 'right' : 'left'}
            sortDirection={orderBy === tableHeader.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === tableHeader.id}
              direction={orderBy === tableHeader.id ? order : 'asc'}
              onClick={createSortHandler(tableHeader.id)}
            >
              <span className={classes.tableHead}> {tableHeader.name}</span>
              {orderBy === tableHeader.id ? (
                <span className={classes.visuallyHidden}>
                  {/*  {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {hasActions && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

interface Props {
  tableHeaders: TableHeader[];
  tableItems: TableItem[];
  hasActions: boolean;
  totalItems: number;
  isReportValid?: boolean;
}

const TableComponent: React.FC<Props> = (props) => {
  const { tableHeaders, tableItems, hasActions, totalItems, isReportValid = false } = { ...props };
  console.log(tableItems);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof TableItem>('name');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TableItem) => {
    event.preventDefault();
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper className="mb-3 prognosTable">
      <TableContainer>
        <Table className={classes.table} aria-labelledby="Reports table" aria-label="Reports table">
          <TableHeadData
            tableHeaders={tableHeaders}
            order={order}
            orderBy={orderBy}
            hasActions={hasActions}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(tableItems, getComparator(order, orderBy)).map((row) => (
              <TableRow hover tabIndex={-1} key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.country}</TableCell>
                <TableCell>{row.currency}</TableCell>
                <TableCell>{row.hidden}</TableCell>
                <TableCell>{row?.firstDatePointDate ?? ''}</TableCell>
                <TableCell>{row?.lastDataPointDate ?? ''}</TableCell>
                {!isReportValid && (
                  <TableCell align="right" className="nowrap">
                    {row.isReportValid}
                  </TableCell>
                )}
                {hasActions && (
                  <TableCell align="right" className="nowrap">
                    {row.actions}
                  </TableCell>
                )}
              </TableRow>
            ))}
            {totalItems === 0 && (
              <TableRow>
                <TableCell style={{ color: '#19555a' }} colSpan={5} className="text-center">
                  <div className="text-center">Empty</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pt-2 pb-2 floatRight mr-3">
        Total: {tableItems.length}
        {' of '}
        {totalItems}
      </div>
    </Paper>
  );
};

export default TableComponent;
