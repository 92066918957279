import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { mdiCloseThick } from '@mdi/js';
import { Link } from 'react-router-dom';
import classes from './ReportCard.module.scss';

export enum CardTypeStyle {
  primary = 'primary',
  secondary = 'secondary',
}

export enum MinHeight {
  minHeight150 = 'minHeight150',
}

interface Props {
  title: string;
  cardTypeStyle: CardTypeStyle.primary | CardTypeStyle.secondary;
  footerText?: string;
  link: string;
  footerIcon?: {
    icon: string;
    title: string;
  };
  titleIcon?: {
    icon: string;
    title: string;
  };
  minHeight?: MinHeight.minHeight150 | null;
}

const Card: React.FC<Props> = (props) => {
  const {
    title,
    titleIcon,
    link,
    cardTypeStyle,
    footerText,
    footerIcon,
    minHeight,
  } = props;

  return (
    <Link
      to={link}
      className={`${classes.cardContainer} ${classes[cardTypeStyle]} ${
        minHeight ? classes[minHeight] : ''
      }`}
    >
      <header>
        <h2>
          {titleIcon && (
            <Icon path={titleIcon.icon} title={titleIcon.title} size={0.9} />
          )}
          {title}
        </h2>
      </header>
      {footerText && (
        <footer>
          <div>
            {footerText}
            {footerIcon?.icon && (
              <>
                <Icon
                  className="ml-1"
                  path={mdiCloseThick}
                  title="multiple"
                  size={0.5}
                />
                <Icon
                  path={footerIcon.icon}
                  title={footerIcon.title}
                  size={0.7}
                />
              </>
            )}
          </div>
        </footer>
      )}
    </Link>
  );
};
Card.defaultProps = {
  footerText: '',
  footerIcon: {
    icon: '',
    title: '',
  },
  titleIcon: {
    icon: '',
    title: '',
  },
  minHeight: null,
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  cardTypeStyle: PropTypes.oneOf([
    CardTypeStyle.primary,
    CardTypeStyle.secondary,
  ]).isRequired,
  footerIcon: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  footerText: PropTypes.string,
  titleIcon: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  minHeight: PropTypes.oneOf([MinHeight.minHeight150]),
};

export default Card;
