import React, { useEffect, useState } from 'react';
import { mdiFileDocumentOutline, mdiFolder } from '@mdi/js';
import Grid from '@mui/material/Grid';
import useAuth from '../../hooks/useAuth';

import axios from 'axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Card, {
  CardTypeStyle,
  MinHeight,
} from '../../ui/card/reportCard/ReportCard';
import routerPaths from '../../router/RouterPaths';
import useUser from '../../hooks/useUser';

interface IReport {
  id: string;
  name: string;
  country?: string;
  currency?: string;
  reportUrl: string;
}
interface ICollection {
  id: string;
  name: string;
  numberOfReports: number;
}
interface ReportsResponse {
  reports: IReport[];
  collections: ICollection[];
}

const Reports: React.FC = () => {
  const customerId = useAuth()?.authData?.customerId || '';
  const axiosPrivate = useAxiosPrivate();

  const [reportsResponse, setReportsResponse] = useState<ReportsResponse>({
    collections: [],
    reports: [],
  });
  const [loading, setLoading] = useState(true);
  const userContext = useUser();

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    const source = axios.CancelToken.source();
    async function getReports() {
      try {
        const reportResponse = await axiosPrivate.get('/reports', {
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        const collectionResponse = await axiosPrivate.get('/collections', {
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        setReportsResponse({
          collections: collectionResponse?.data ?? [],
          reports: reportResponse?.data ?? [],
        });
        setErrorMessage('');
      } catch (err) {
        setErrorMessage('Something went wrong try again later.');
      }
      setLoading(false);
    }
    getReports();
    return function () {
      source.cancel('Cancelling in cleanup');
    };
  }, [axiosPrivate]);

  const reportCards: JSX.Element[] = [];

  if (reportsResponse.collections) {
    reportsResponse.collections.forEach((collection: ICollection) => {
      const toLink = routerPaths.reportCollection.replace(
        ':collectionId',
        collection.id
      );
      reportCards.push(
        <Grid item xs={12} sm={6} md={4} key={collection.id}>
          <Card
            link={toLink}
            titleIcon={{ icon: mdiFolder, title: 'Folder image' }}
            minHeight={MinHeight.minHeight150}
            footerText={collection.numberOfReports.toString()}
            footerIcon={{
              icon: mdiFileDocumentOutline,
              title: 'Document image',
            }}
            cardTypeStyle={CardTypeStyle.secondary}
            title={collection.name}
          />
        </Grid>
      );
    });
  }
  if (reportsResponse.reports) {
    reportsResponse.reports.forEach((report: IReport) => {
      const reportItem: IReport = report;
      let footerText = '';
      if (reportItem.country) {
        footerText = reportItem.country;
      }
      if (reportItem.currency) {
        footerText += ` (${reportItem.currency})`;
      }
      const toLink = customerId
        ? routerPaths.report
            .replace(':customerUrl', userContext?.userData.customerUrl || '')
            .replace(':reportUrl', reportItem.reportUrl)
        : '';

      reportCards.push(
        <Grid item xs={12} sm={6} md={4} key={reportItem.id}>
          <Card
            link={toLink}
            titleIcon={{ icon: mdiFolder, title: 'Folder image' }}
            minHeight={MinHeight.minHeight150}
            footerText={footerText}
            cardTypeStyle={CardTypeStyle.primary}
            title={reportItem.name}
          />
        </Grid>
      );
      /*  } */
    });
  }

  return (
    <>
      <h1>Reports</h1>
      <hr className="mb-4 mt-4" />
      <Grid container spacing={2}>
        {reportCards}
      </Grid>
      {errorMessage}
      {reportsResponse.collections.length === 0 &&
        reportsResponse.reports.length === 0 &&
        loading === false && <h2>You have no reports or collections.</h2>}
    </>
  );
};
export default Reports;
