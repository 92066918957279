import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classes from './EditReport.module.scss';
import BasicInfo from './BasicInfo';
import ReportComponent from '../../../components/report/ReportComponent';
import EditReferences from './ReferencesComponent';
import TimeSeriesEdit from './TimeSeriesEdit';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import { mdiChartLine, mdiRefresh } from '@mdi/js';
import { IReport, TimeSeriesUser } from '../../../components/report/types';

export interface Reference {
  id: string;
  name: string;
  country?: string;
  currency?: string;
  description?: string;
  report: string;
  rawData?: TimeSeriesData[];
  indexDate?: Date;
  startDate?: Date;
  sourceReportId: string;
}

export interface ICustomer {
  _id: string;
  name: string;
  customerUrl: string;
}

interface TimeSeriesData {
  x: Date;
  y: number;
}

export interface TimeSeries {
  id: string;
  name: string;
  country?: string;
  currency?: string;
  currencyId: string;
  description?: string;
  data?: TimeSeriesData[];
  costDrivers?: TimeSeries[];
  share?: number;
  tsno?: number;
  showLineInReport?: boolean;
  lineErrors?: string[];
  //STC & CC
  indexDate?: string;
  movAvg?: number;
  startDate?: string;
  newPeriod?: string;
  multiplier?: number;
  divider?: number;
  inverse?: boolean;
}

enum REPORT_TYPE {
  SI = 'SI',
  STC = 'STC',
  CC = 'CC',
}
export interface Report {
  _id: string;
  reportUrl: string;
  benchmarkTimeSerie: TimeSeries;
  indexStartDate?: Date;
  isDraft: boolean;
  isShared: boolean;
  name: string;
  inCollections: string;
  reportCreated: string;
  customerIds?: string;
  customerThatOwenReportID?: string;
  yLabelName?: string;
  reportType?: REPORT_TYPE.CC | REPORT_TYPE.SI | REPORT_TYPE.STC;
  overwriteReportTypeToShow?: REPORT_TYPE.CC | REPORT_TYPE.SI | REPORT_TYPE.STC | null; // if null reportType is used
  isReportValid?: boolean;
  reportGeneralErrors?: string[];
  hideReportFromUserReportList?: boolean;
}

const EditReport: React.FC = () => {
  const { reportUrl } = useParams();
  const [referenceState, setReferenceState] = useState<Reference[]>([]);
  const [reportState, setReportState] = useState<Report | null>(null);

  const [showReport, setShowReport] = useState(false);
  const [stateKey, setStateKey] = useState(Date.now());
  const axiosPrivate = useAxiosPrivate();
  const [isRefetchLoading, setIsRefetchLoading] = useState(false);
  const [customerState, setCustomerState] = useState<ICustomer>();

  async function getReferences(reportId: string) {
    return await axiosPrivate
      .get(`/references/findReferenceForReport`, {
        params: {
          reportId: reportId,
        },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200 && response?.data) {
          const references: Reference[] = response.data;
          return references;
        }
      });
  }

  const fetchData = async () => {
    if (reportUrl) {
      await axiosPrivate
        .get(`/reports/admin/${reportUrl}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        })
        .then(async (response) => {
          if (response.status === 200 && response?.data) {
            const report: Report = response.data;
            setReportState(report);
            if (report?._id) {
              const references = await getReferences(report._id);
              if (references) {
                setReferenceState(references);
              }
              setStateKey(Date.now());
            }
          }
        })
        .catch((e) => {
          // TODO: handle error
        });
    }
  };

  const fetchUpdatedData = async () => {
    setIsRefetchLoading(true);
    if (reportUrl) {
      await axiosPrivate
        .post(
          `/reports/refetchReportValues`,
          {
            reportId: reportState?._id,
          },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        )
        .then(async (response) => {
          if (response.status === 200 && response?.data) {
            const report: Report = response.data;
            setReportState(report);
            if (report?._id) {
              const references = await getReferences(report._id);
              if (references) {
                setReferenceState(references);
              }
              setStateKey(Date.now());
            }
          }
          setIsRefetchLoading(false);
        })
        .catch((er) => {
          console.log(er);
          setIsRefetchLoading(false);
        });
    }
  };
  useEffect(() => {
    async function getCustomer() {
      if (reportState?.customerThatOwenReportID) {
        await axiosPrivate
          .get(`/customers/customer/${reportState?.customerThatOwenReportID}`, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          })
          .then((response) => {
            const customer: ICustomer = response.data;
            setCustomerState(customer);
          })
          .catch((e) => {
            setCustomerState({
              _id: '',
              name: '',
              customerUrl: '',
            });
          });
      }
    }
    getCustomer();
    return () => {
      setCustomerState({ _id: '', name: '', customerUrl: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportState?.customerThatOwenReportID]);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchReportData = () => {
    fetchData();
  };

  const fetchUpdatedReportData = () => {
    fetchUpdatedData();
  };
  const setReferenceValues = () => {
    fetchData();
  };

  const nestedCostDriverAsUser = (timeSeries: TimeSeries[]): TimeSeriesUser[] => {
    const returnValue: TimeSeriesUser[] = [];
    for (const timeSerie of timeSeries) {
      if (timeSerie.showLineInReport !== false) {
        let nestedCostDrivers: TimeSeriesUser[] = [];
        if (timeSerie.costDrivers) {
          nestedCostDrivers = nestedCostDriverAsUser(timeSerie.costDrivers);
        }
        const costDriverToSave: TimeSeriesUser = {
          id: timeSerie.id,
          name: timeSerie.name,
          country: timeSerie.country,
          currency: timeSerie.currency,
          description: timeSerie.description,
          data: timeSerie.data,
          costDrivers: nestedCostDrivers,
          share: timeSerie.share,
        };
        returnValue.push(costDriverToSave);
      }
    }
    return returnValue;
  };

  const reportsAasUser = (report: Report): IReport => {
    if (reportState) {
      // TODO fix benchmarkTimeSerie
      // TODO fix and mapp costdrivers
      // TODO add this tp reportcomponent view
      let nestedCostDrivers: TimeSeriesUser[] = [];
      if (report.benchmarkTimeSerie.costDrivers) {
        nestedCostDrivers = nestedCostDriverAsUser(report.benchmarkTimeSerie.costDrivers);
      }

      const reportToSave: IReport = {
        _id: report._id,
        reportUrl: report.reportUrl,
        yLabelName: report.yLabelName ?? '',
        name: report.name,
        benchmarkTimeSerie: {
          id: report.benchmarkTimeSerie.id,
          name: report.benchmarkTimeSerie.name,
          country: report.benchmarkTimeSerie.country,
          currency: report.benchmarkTimeSerie.currency,
          description: report.benchmarkTimeSerie.description,
          data: report.benchmarkTimeSerie.data,
          costDrivers: nestedCostDrivers,
          share: report.benchmarkTimeSerie.share,
        },
      };
      return reportToSave;
    }
    const reportToSave: IReport = {
      _id: '',
      reportUrl: '',
      yLabelName: '',
      name: '',
      benchmarkTimeSerie: {
        id: '',
        name: '',
      },
    };
    return reportToSave;
  };
  return (
    <div>
      <div className={classes.container}>
        <div>
          <center className="pa-3">
            <h2>{customerState?.name}</h2>
          </center>

          {reportState && (
            <BasicInfo
              setBasicInfoValues={() => fetchReportData()}
              name={reportState?.name}
              customerId={'report.customer' || 'shared'} // TODO check this
              reportId={reportState._id}
              yLabelName={reportState?.yLabelName}
              hideReportFromUserReportList={reportState.hideReportFromUserReportList ?? false}
            />
          )}
          {reportState && (
            <EditReferences
              setReferenceValues={() => setReferenceValues()}
              reportId={reportState._id}
              reportStartDate={
                reportState.benchmarkTimeSerie?.startDate
                  ? new Date(reportState.benchmarkTimeSerie?.startDate)
                  : new Date()
              }
              customerId={reportState.customerThatOwenReportID}
              indexDate={
                reportState.benchmarkTimeSerie?.indexDate
                  ? new Date(reportState.benchmarkTimeSerie?.indexDate)
                  : undefined
              }
              references={referenceState}
            />
          )}
        </div>
        <div>
          {reportState && <TimeSeriesEdit setTimeSeriesValues={() => fetchReportData()} report={reportState} />}
        </div>
      </div>
      <div className={classes.actionButtons}>
        <Button
          type={ButtonType.button}
          buttonStyleType={ButtonStyleType.primary}
          className={classes.previewReportBtn}
          onClick={() => setShowReport(!showReport)}
          text={showReport ? 'Close report' : 'View report'}
          icon={{ iconPath: mdiChartLine, iconTitle: 'Chart Line Icon' }}
        />

        <Button
          type={ButtonType.button}
          buttonStyleType={ButtonStyleType.primary}
          className={classes.refreshBtn}
          loading={isRefetchLoading}
          onClick={() => fetchUpdatedReportData()}
          text="Refresh data"
          icon={{ iconPath: mdiRefresh, iconTitle: 'Refresh Icon' }}
        />
      </div>
      <div style={{ display: showReport ? 'block' : 'none' }} className={classes.graph}>
        {reportState && (
          <ReportComponent key={stateKey} references={referenceState} report={reportsAasUser(reportState)} />
        )}
      </div>
    </div>
  );
};

export default EditReport;
