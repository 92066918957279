import React, { useEffect, useState } from 'react';
import { mdiChevronDown, mdiChevronUp, mdiFolder, mdiFileDocumentOutline, mdiFolderPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { TextField as MuiTextField, Autocomplete } from '@mui/material';
import { Link } from 'react-router-dom';
import deepCopy from 'deepcopy';
import PrognosButton, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';

import classes from './SharedCollections.module.scss';
import IconButton from '../../../ui/iconButton/IconButton';

import NewCollectionDialog from '../Customers/NewCollectionDialog';
import ConfirmDialog, { ConfirmDialogType } from '../../../components/dialog/ConfirmDialog';
import PageHeader from '../../../components/pageHeader/PageHeader';

import routerPaths from '../../../router/RouterPaths';
import { IFindAllCollectionData, IReportCollection } from '../Customer/types/FindAllCollectionAdmin.type';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

interface ICollectionsState extends IFindAllCollectionData {
  showReports: boolean;
}

interface IReport {
  _id: string;
  name: string;
  country: string;
  currency: string;
  isDraft: boolean;
  inCollections: string[];
  reportUrl: string;
  isReportValid: boolean;
}

const SharedCollections: React.FC = () => {
  const confirmDataConstant = {
    dialogText: '',
    errorMessage: '',
    buttonTextAccept: '',
    buttonTextDismiss: '',
    dialogTitle: '',
    isLoading: false,
    showDialog: false,
    submitDialog: () => undefined,
  };
  const [collectionsState, setCollectionsState] = useState<ICollectionsState[]>();
  const [reportsState, setReportsState] = useState<IReportCollection[]>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchReportState] = useState<any>();
  const [inputValue, setInputValue] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [confirmDialogState, setConfirmDialogState] = useState<ConfirmDialogType>(confirmDataConstant);
  const axiosPrivate = useAxiosPrivate();

  const getAllSharedCollections = async () => {
    await axiosPrivate
      .get(`/collections/findAllSharedCollectionAdmin/`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((response) => {
        const collectionsResponse: IFindAllCollectionData[] = response.data;
        const collectionToSave = collectionsResponse.map((collection) => {
          return {
            showReports: false,
            ...collection,
          };
        });
        setCollectionsState(collectionToSave);
      })
      .catch();
  };

  async function getReports() {
    await axiosPrivate
      .get('/reports/getSharedReportsForAdmin', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((response) => {
        const reports: IReport[] = response.data;
        const reportsToSave: IReportCollection[] = reports.map((report) => {
          return {
            reportId: report._id,
            reportName: report.name,
            isDraft: report.isDraft,
            reportUrl: report.reportUrl,
          };
        });
        setReportsState(reportsToSave);
      })
      .catch();
  }

  useEffect(() => {
    getAllSharedCollections();
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleHideShowReportsHandler = (id: string) => {
    if (collectionsState) {
      let collectionsStateCopy = [...collectionsState];
      const collectionToChange = collectionsStateCopy.find((collection) => collection.id === id);
      if (collectionToChange) {
        const collectionToChangeCopy = {
          ...collectionToChange,
          showReports: !collectionToChange.showReports,
        };
        collectionsStateCopy = collectionsStateCopy.map((collection) => {
          if (collection.id === id) {
            return collectionToChangeCopy;
          }
          return collection;
        });
      }
      setCollectionsState(collectionsStateCopy);
    }
  };

  const removeReportFromCollectionHandler = async (collectionId: string, reportId: string) => {
    await axiosPrivate
      .post(
        `collections/removeReportFromCollection/`,
        { collectionId, reportId },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200 && collectionsState) {
          let collectionsStateCopy = deepCopy(collectionsState);

          collectionsStateCopy = collectionsStateCopy.map((collection) => {
            if (collection.id !== collectionId) {
              return collection;
            }
            const reports = collection.reports.filter((reports) => reports.reportId !== reportId);
            const newCollection = { ...collection, reports: reports };
            return newCollection;
          });
          setCollectionsState(collectionsStateCopy);
        }
      })
      .catch((error) => {
        return {
          statusCode: error.status,
          data: error.response,
        };
      });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchHandler = async (newValue: any | null, collectionId: string) => {
    setInputValue('');
    const reportId: string = newValue?.reportId || '';
    if (reportId) {
      await axiosPrivate
        .post(
          '/collections/addReportToCollection',
          {
            reportId,
            collectionId,
          },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.status === 200 && collectionsState) {
            let collectionsStateCopy = [...collectionsState];
            collectionsStateCopy = collectionsStateCopy.map((collection) => {
              if (collection.id !== collectionId) return collection;
              const collectionCopy = { ...collection };

              if (reportsState) {
                const report = reportsState.find((x) => x.reportId === reportId);
                if (report) {
                  collectionCopy.reports.push({
                    reportId: reportId,
                    isDraft: report?.isDraft,
                    reportName: report?.reportName,
                    reportUrl: report?.reportUrl,
                  });
                }
              }
              return collectionCopy;
            });
            setCollectionsState(collectionsStateCopy);
          }
        })
        .catch();
    }
  };

  const getMissingReports = (reportsData: IReportCollection[]): IReportCollection[] => {
    let reportStateCopy: IReportCollection[] = [...(reportsState || [])];
    if (reportsState) {
      reportStateCopy = reportStateCopy.filter(
        (report) => !reportsData.some((reportData) => reportData.reportId === report.reportId)
      );
    }
    return reportStateCopy;
  };

  const deleteCollection = async (collection: ICollectionsState, confirmDialogValues: ConfirmDialogType) => {
    const dialogValuesCopy = { ...confirmDialogValues, isLoading: true };
    setConfirmDialogState(dialogValuesCopy);
    await axiosPrivate
      .delete(`/collections/${collection.id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then(() => {
        setConfirmDialogState({ ...confirmDataConstant });
        if (collectionsState) {
          let collectionsStateCopy = [...collectionsState];
          collectionsStateCopy = collectionsStateCopy.filter((collectionState) => collectionState.id !== collection.id);
          setCollectionsState(collectionsStateCopy);
        }
      })
      .catch((error) => {
        const confirmDialogValuesCopy = {
          ...confirmDialogValues,
          errorMessage: 'Something went wrong',
          isLoading: false,
        };
        setConfirmDialogState(confirmDialogValuesCopy);
      });
  };

  const deleteCollectionConfirm = (collection: ICollectionsState) => {
    const values = {
      dialogTitle: 'Confirm',
      errorMessage: '',
      dialogText: `${'Are you sure you want to delete "'}${collection.name}" ?`,
      buttonTextAccept: 'Delete',
      showDialog: true,
      submitDialog: () => deleteCollection(collection, confirmDialogState),
    };
    values.submitDialog = () => deleteCollection(collection, values);
    setConfirmDialogState(values);
  };

  const newCollectionDialogHandler = () => {
    setShowDialog(true);
  };

  const closeNewCollectionDialog = async () => {
    setShowDialog(false);
  };
  const closeConfirmAlertDialog = () => {
    const confirmAlertDataConstantCopy = { ...confirmDataConstant };
    setConfirmDialogState(confirmAlertDataConstantCopy);
  };
  let collections: JSX.Element = <></>;
  if (collectionsState) {
    collections = (
      <ul className={classes.collectionList}>
        {collectionsState?.map((collection) => (
          <li key={collection.id}>
            <header>
              <div>
                <IconButton
                  areaLabel="Toggle hide short reports"
                  icon={collection.showReports ? mdiChevronDown : mdiChevronUp}
                  onClick={() => toggleHideShowReportsHandler(collection.id)}
                />
                <Icon className="mr-1 ml-2" color="grey" path={mdiFolder} title="Folder" size={0.9} />
                <strong>{collection.name}</strong>
              </div>
              <div>
                <PrognosButton
                  small
                  buttonStyleType={ButtonStyleType.delete}
                  text="Delete"
                  type={ButtonType.button}
                  onClick={() => deleteCollectionConfirm(collection)}
                />
              </div>
            </header>
            {collection.showReports && (
              <>
                <div className="mr-6">
                  {'Customers access: '}
                  {collection?.customers && collection?.customers.map((c) => c.name).join(', ')}
                </div>
                <hr />
                <Autocomplete
                  clearOnBlur
                  clearOnEscape
                  inputValue={inputValue}
                  isOptionEqualToValue={(option, value) => option.reportId === value.reportId}
                  onInputChange={(event, newInputValue) => {
                    if (event && event.type !== 'blur') {
                      setInputValue(newInputValue);
                    }
                  }}
                  defaultValue={null}
                  id="add-report-box"
                  options={getMissingReports(collection.reports)}
                  getOptionLabel={(option) => option?.reportName ?? undefined}
                  renderInput={(params) => <MuiTextField {...params} label="Add report..." variant="standard" />}
                  value={searchReportState}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(event: any, newValue: string | null) => {
                    searchHandler(newValue, collection.id);
                  }}
                />
                {collection.reports.map((report) => (
                  <div className={classes.reportBox} key={report.reportId}>
                    <div>
                      <Icon
                        className="mr-1 ml-2"
                        color="grey"
                        path={mdiFileDocumentOutline}
                        title="Document"
                        size={0.9}
                      />
                      {report.reportName}
                    </div>
                    <div>
                      <Link
                        className="mr-3"
                        to={routerPaths.report
                          .replace(':customerUrl', 'shared')
                          .replace(':reportUrl', report.reportUrl)}
                      >
                        <PrognosButton
                          small
                          type={ButtonType.button}
                          buttonStyleType={ButtonStyleType.secondary}
                          text="View"
                        />
                      </Link>
                      <PrognosButton
                        small
                        buttonStyleType={ButtonStyleType.delete}
                        text="Remove"
                        type={ButtonType.button}
                        onClick={() => removeReportFromCollectionHandler(collection.id, report.reportId)}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <>
      <PageHeader
        pageTitle="Shared Collections"
        button={{ text: 'New Collection', onClick: newCollectionDialogHandler }}
        icon={{ iconPath: mdiFolderPlusOutline, iconTitle: 'Add Collection Icon' }}
      />

      <div className="ml-2 mr-2">{collections}</div>

      {showDialog && (
        <NewCollectionDialog
          showDialog={showDialog}
          collectionAdded={getAllSharedCollections}
          closeDialog={closeNewCollectionDialog}
          isShared={true}
        />
      )}
      <ConfirmDialog
        isLoading={confirmDialogState?.isLoading || false}
        errorMessage={confirmDialogState.errorMessage}
        submitDialog={confirmDialogState.submitDialog}
        showDialog={confirmDialogState.showDialog}
        closeDialog={closeConfirmAlertDialog}
        buttonTextAccept={confirmDialogState?.buttonTextAccept}
        dialogText={confirmDialogState?.dialogText}
      />
    </>
  );
};

export default SharedCollections;
