import React from 'react';
import classes from './LeftMenu.module.scss';
import { Outlet } from 'react-router-dom';
import MenuList from '../MenuList';

const LeftMenu: React.FC = () => {
  return (
    <>
      <div className={classes.main}>
        <div className={classes.leftMenu}>
          <MenuList />
        </div>

        <main className={classes.mainContent}>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default LeftMenu;
