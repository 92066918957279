import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonType, ButtonStyleType } from '../../../ui/button/Button';
import TextField, { TextFieldType, ValidationRules } from '../../../ui/input/TextField';
import routerPaths from '../../../router/RouterPaths';
import classes from './ForgotPassword.module.scss';
import logoType from '../../../assets/images/Prognos_tailored_logotyp_RGB.png';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

interface Rule {
  rule: string;
  ruleHelper?: string | number;
  errorText: string;
}
interface InputInterface {
  value: string;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}

interface FormElements {
  emailAddress: InputInterface;
}

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [formElements, updateInputFormElements] = useState<FormElements>({
    emailAddress: {
      value: '',
      rules: [
        {
          rule: ValidationRules.required,
          errorText: 'Please enter a valid e-mail address.',
        },
      ],
      error: false,
      errorText: '',
      errorMessageBackend: 'Please enter a valid e-mail address.',
    },
  });
  const axiosPrivate = useAxiosPrivate();

  const isInputValid = (value: string | number, inputRules: Rule[]): IsValidReturnType => {
    const returnValue = {
      hasError: false,
      errorText: '',
    };
    for (let index = 0; index < inputRules.length; index += 1) {
      if (inputRules[index].rule === ValidationRules.required && value === '') {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }
    }
    return returnValue;
  };

  const inputChangeHandler = (newValue: string | number, itemIdentifier: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formElementsCopy: any = { ...formElements };
    const updatedElement = { ...formElementsCopy[itemIdentifier] };

    updatedElement.value = newValue;
    if (updatedElement.rules) {
      const { hasError, errorText } = isInputValid(newValue, updatedElement.rules);
      updatedElement.error = hasError;
      updatedElement.errorText = hasError ? errorText : '';
    } else if (updatedElement.error) {
      updatedElement.error = false;
    }

    formElementsCopy[itemIdentifier] = updatedElement;
    updateInputFormElements(formElementsCopy);
  };

  const isFormValidFrontend = (): boolean => {
    let isValid = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formElementsCopy: any = { ...formElements };

    Object.entries(formElements).forEach(([itemIdentifier, formElement]) => {
      const { hasError, errorText } = isInputValid(formElement.value, formElement.rules);

      if (hasError) {
        isValid = false;
        const updatedElement = { ...formElementsCopy[itemIdentifier] };
        updatedElement.error = hasError;
        updatedElement.errorText = errorText;
        formElementsCopy[itemIdentifier] = updatedElement;
      }
    });
    updateInputFormElements(formElementsCopy);
    return isValid;
  };

  const submitFormHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    if (isFormValidFrontend()) {
      await axiosPrivate
        .post(
          `auth/sendResetPasswordEmail/`,
          { email: formElements.emailAddress.value },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSuccessMessage('A password reset link was sent if you are a Prognos user. Please use the link in the email to create a new password.');
          } else {
            setErrorMessage('Something went wrong try again later.');
            setLoading(false);
          }
        })
        .catch((error) => {
          setErrorMessage('Something went wrong try again later.');
          setLoading(false);
        });
    }
    setLoading(false);
  };

  interface IsValidReturnType {
    hasError: boolean;
    errorText: string;
  }

  return (
    <>
      <div className={classes.logotypeContainer}>
        <img src={logoType} className={`${classes.logotype} mb-6`} alt="logo" />
      </div>
      <hr />
      <h1 className={`${classes.titleText} mt-3 mb-3`}>Forgot password</h1>

      <p className="mt-4 mb-4">Enter your e-mail address to get a reset link e-mailed to you.</p>
      {successMessage && (
        <div
          className="mt-3 mb-5"
          style={{
            backgroundColor: '#edf7ed',
            border: '1px solid #9cd39c',
            color: '#163418',
            padding: '8px',
            borderRadius: '3px',
          }}
        >
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div
          className="mt-3 mb-6"
          style={{
            backgroundColor: '#FFCDD2',
            border: '1px solid #EF9A9A',
            padding: '8px',
            borderRadius: '3px',
          }}
        >
          {errorMessage}
        </div>
      )}
      <form onSubmit={submitFormHandler}>
        <TextField
          className="mb-4"
          id="emailAddress"
          label="E-mail address"
          error={formElements.emailAddress.error}
          helperText={formElements.emailAddress.errorText}
          type={TextFieldType.email}
          value={formElements.emailAddress.value}
          valueChanged={(newValue) => inputChangeHandler(newValue, 'emailAddress')}
        />
        <div className={`${classes.footer} `}>
          <Link to={routerPaths.login}>Back to sign in</Link>
          <div className="floatRight">
            <Button type={ButtonType.submit} buttonStyleType={ButtonStyleType.primary} loading={loading} text="Send reset link" />
          </div>
        </div>
      </form>
    </>
  );
};
export default ForgotPassword;
