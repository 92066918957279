import Grid from '@mui/material/Grid';

import { mdiFileDocumentOutline } from '@mdi/js';
import React, { useEffect, useState } from 'react';

import Card, {
  CardTypeStyle,
  MinHeight,
} from '../../../ui/card/reportCard/ReportCard';
import routerPaths from '../../../router/RouterPaths';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import useUser from '../../../hooks/useUser';

interface IReportCollection {
  id: string;
  name: string;
  country?: string;
  currency?: string;
  reportUrl: string;
}
export interface ICollection {
  id: string;
  name: string;
  collectionURL: string;
  reports: IReportCollection[];
}

const Collection: React.FC = () => {
  const { collectionId } = useParams();
  const userContext = useUser();

  const [collection, setCollection] = useState<ICollection | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    async function getReportForCollection(collectionId: string) {
      await axiosPrivate
        .get(`/collections/${collectionId}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        })
        .then((response) => {
          const collectionData: ICollection = response.data;
          if (collectionData) {
            setCollection(collectionData);
          }
        })
        .catch()
        .finally(() => {
          setLoading(false);
        });
    }
    if (typeof collectionId === 'string') {
      getReportForCollection(collectionId);
    }
  }, [collectionId, axiosPrivate]);

  const collectionReportCards: JSX.Element[] = [];

  if (collection) {
    const titleIcon = {
      icon: mdiFileDocumentOutline,
      title: 'Document image',
    };
    collection.reports.forEach((reportItem) => {
      let footerText = '';
      if (reportItem.country) {
        footerText = reportItem.country;
      }
      if (reportItem.currency) {
        footerText += ` (${reportItem.currency})`;
      }
      let toLink = '';

      toLink = routerPaths.report
        .replace(':customerUrl', userContext?.userData.customerUrl || '')
        .replace(':reportUrl', reportItem.reportUrl);

      collectionReportCards.push(
        <Grid item xs={12} sm={6} md={4} key={reportItem.id}>
          <Card
            link={toLink}
            titleIcon={titleIcon}
            minHeight={MinHeight.minHeight150}
            footerText={footerText}
            cardTypeStyle={CardTypeStyle.primary}
            title={reportItem.name}
          />
        </Grid>
      );
    });
  }

  return (
    <>
      <h1>{collection?.name}</h1>
      <hr className="mb-4 mt-4" />
      <Grid container spacing={2}>
        {collectionReportCards}
        {collection?.reports.length === 0 && loading === false && (
          <h2>You have no reports in this collection.</h2>
        )}
      </Grid>
    </>
  );
};
export default Collection;
