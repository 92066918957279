import React from 'react';
/* import { useLocation } from 'react-router-dom'; */
import classes from './AuthenticatedLayout.module.scss';
import Header from './Header';
/* import { logLocation } from '../../plugins/analytics'; */
import { Outlet } from 'react-router-dom';

const Layout: React.FC = () => {
  /*   useEffect(() => {
    logLocation();
  }, [location]); */

  return (
    <div>
      <Header />
      <main className={classes.main}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
