import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/style/main.scss';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { UserProvider } from './context/UserProvider';
import { AdminCustomerProvider } from './context/AdminCustomerProvider';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
/* import reportWebVitals from './reportWebVitals'; */

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <AuthProvider>
      <UserProvider>
        <AdminCustomerProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AdminCustomerProvider>
      </UserProvider>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/* reportWebVitals(); */
