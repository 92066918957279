/* eslint-disable jsx-a11y/no-access-key */
import React from 'react';
import Button, { ButtonStyleType, ButtonType } from '../../ui/button/Button';
import classes from './PageHeader.module.scss';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';

interface Props {
  button?: {
    onClick: () => void;
    text: string;
  };
  pageTitle: string;
  search?: {
    newSearchInput: (searchText: string) => void;
    searchText: string;
  };
  icon?: {
    iconPath: string;
    iconTitle: string;
  };
}

const ConfirmAlertDialog: React.FC<Props> = ({ button, pageTitle, search, icon }) => {
  return (
    <div>
      <div className={classes.pageHeader}>
        {button && (
          <Button
            small
            type={ButtonType.button}
            buttonStyleType={ButtonStyleType.primary}
            onClick={button.onClick}
            text={button.text}
            icon={icon}
          />
        )}
        <h2>{pageTitle}</h2>

        {search && (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon fontSize="small" />
            </div>
            <InputBase
              size="small"
              className={classes.inputInput}
              accessKey="f"
              onChange={(event) => search?.newSearchInput(event.target.value)}
              value={search?.searchText}
              style={{ width: '100%' }}
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        )}
      </div>
      <hr className="mb-2" />
    </div>
  );
};

export default ConfirmAlertDialog;
