import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { axiosPrivate } from '../../../../plugins/Axios';
import PageHeader from '../../../../components/pageHeader/PageHeader';
import { TableContainer, TableHead, TableRow, TableCell, Paper, Table, TableBody } from '@mui/material';
import { GetCurrenciesResponse } from '../types/tools.interfaces';

const CurrenciesPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [allCurrenciesResponse, setAllCurrenciesResponse] = useState<GetCurrenciesResponse[]>([]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    async function getAllCurrencies() {
      try {
        const allCurrenciesResponse = await axiosPrivate.get('general/currencies', {
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        setAllCurrenciesResponse(allCurrenciesResponse?.data ?? []);
      } catch (error) {
        source.cancel('Cancelling in cleanup');
        setErrorMessage('Something went wrong try again later.');
      }
    }
    getAllCurrencies();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader pageTitle="Currencies" />
      {errorMessage && <h2 className="pl-2 pt-2">{errorMessage}</h2>}

      <Paper className="ml-2 mr-2 mb-2">
        <TableContainer>
          <Table aria-label="currencies table">
            <TableHead>
              <TableRow>
                <TableCell>Currency</TableCell>
                <TableCell>Currency Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allCurrenciesResponse &&
                allCurrenciesResponse.map((currency) => (
                  <TableRow key={currency.id}>
                    <TableCell component="th" scope="row">
                      {currency.name}
                    </TableCell>
                    <TableCell>{currency.threeLetterCode}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default CurrenciesPage;
