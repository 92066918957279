import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@mui/material/TextField';

export interface InputRules {
  rule: string;
  errorText: string;
}

interface Props {
  label: string;
  type:
    | TextFieldType.text
    | TextFieldType.password
    | TextFieldType.email
    | TextFieldType.number;
  value: string | number | Date;
  id: string;
  className?: string;
  name?: string;
  helperText?: string | React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  autoComplete?: string;
  multiline?: boolean;
  valueChanged: (value: string) => void;
}

export enum TextFieldType {
  text = 'text',
  password = 'password',
  email = 'email',
  number = 'number',
}
export enum ValidationRules {
  required = 'required',
  minLength = 'minLength',
  maxLength = 'maxLength',
  isValidEmail = 'isValidEmail',
  backend = 'backend',
  regExp = 'regExp',
  sameValue = 'sameValue',
}

const InputField: React.FC<Props> = (props) => {
  const {
    label,
    disabled,
    error,
    type,
    value,
    required,
    autoComplete,
    autoFocus,
    id,
    name,
    className,
    multiline,
    helperText,
    valueChanged,
  } = props;
  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    valueChanged(newValue);
  };

  return (
    <TextField
      variant="outlined"
      className={className}
      fullWidth
      name={name}
      id={id}
      label={label}
      disabled={disabled}
      error={error}
      rows={4}
      multiline={multiline}
      type={type}
      value={value}
      required={required}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      helperText={helperText}
      onChange={updateValue}
    />
  );
};
InputField.defaultProps = {
  name: '',
  helperText: '',
  error: false,
  disabled: false,
  required: false,
  autoFocus: false,
  autoComplete: '',
  className: '',
  multiline: false,
};

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    TextFieldType.text,
    TextFieldType.password,
    TextFieldType.email,
    TextFieldType.number,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  multiline: PropTypes.bool,
  autoComplete: PropTypes.string,
  valueChanged: PropTypes.func.isRequired,
};

export default InputField;
