import { createContext, FC, ReactNode, useState } from 'react';
import { axiosPrivate } from '../plugins/Axios';
import { ROLE } from './Roles.enum';

export const AuthContext = createContext<AuthType | undefined>(undefined);

export interface AuthData {
  userId: string;
  customerId: string;
  iat: number | undefined;
  exp: number | undefined;
  accessToken: string;
  roles: ROLE[];
}

export type AuthType = {
  authData: AuthData;
  saveAuthData: (authData: AuthData) => void;
  logoutUser: () => void;
};

export const defaultAuthData: AuthData = {
  userId: '',
  customerId: '',
  iat: undefined,
  exp: undefined,
  accessToken: '',
  roles: [],
};
type Props = {
  children?: ReactNode;
};

export const AuthProvider: FC<Props> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthData>(defaultAuthData);

  const logoutUser = async () => {
    await axiosPrivate
      .post('/auth/logout')
      .catch((e) => {
        //TODO handle error
      })
      .finally(() => {
        localStorage.setItem('accessToken', '');
        setAuthData(defaultAuthData);
      });
  };

  const saveAuthData = (authInfo: AuthData) => {
    const newUserInfo: AuthData = {
      userId: authInfo.userId,
      customerId: authInfo.customerId,
      iat: authInfo.iat,
      exp: authInfo.exp,
      accessToken: authInfo.accessToken,
      roles: authInfo.roles,
    };
    setAuthData(newUserInfo);
  };

  return (
    <AuthContext.Provider value={{ authData: authData, saveAuthData: saveAuthData, logoutUser }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
