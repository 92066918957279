import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiDelete, mdiPlus, mdiPencil, mdiHelp } from '@mdi/js';
import deepCopy from 'deepcopy';
import { Report, TimeSeries } from './EditReport';

import classes from './TimeSeriesEdit.module.scss';
import IconButton, { IconButtonStyleType } from '../../../ui/iconButton/IconButton';

import ConfirmDialog, { ConfirmDialogType } from '../../../components/dialog/ConfirmDialog';
import TextFieldMui, { TextFieldType, ValidationRules } from '../../../ui/input/TextField';
import TextField from '@mui/material/TextField';
import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { axiosPrivate } from '../../../plugins/Axios';
import { GetCurrenciesResponse, GetTSNOsResponse } from '../Tools/types/tools.interfaces';
import TooltipPrognos from '../../../ui/tooltip/Tooltip';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Checkbox, FormLabel } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export interface Props {
  report: Report;
  setTimeSeriesValues: () => void;
}
interface Rule {
  rule: string;
  ruleHelper?: string | number | RegExp;
  errorText: string;
}
interface FormInput {
  value: string;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}

interface FormInputDate {
  value: Date | null;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}

interface FormInputNumber {
  value: number | undefined;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}
interface FormInputBoolean {
  value: boolean | undefined;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}

interface TimeSerieToUpdate {
  id?: string;
  parentLine: string;
  country: string;
  currency: string;
  currencyId: number | undefined;
  description: string;
  tsno: number | undefined;
  indexDate: string | undefined;
  share: number | undefined;
  movAvg: number | undefined;
  startDate: string | undefined;
  newPeriod: string;
  multiplier: number | undefined;
  divider: number | undefined;
  inverse: boolean | undefined;
  showLineInReport: boolean;
  name: string;
}

interface TimeSeriesRequest {
  reportId: string;
  timeSerieToUpdate: TimeSerieToUpdate;
}

interface FormElement {
  name: FormInput;
  description: FormInput;
  countryReportText: FormInput;
  currencyInReportText: FormInput;
  share: FormInputNumber;
  tsno: FormInput;
  currencyId: FormInput;
  indexDate: FormInputDate;
  movAvg: FormInputNumber;
  multiplier: FormInputNumber;
  divider: FormInputNumber;
  inverse: FormInputBoolean;
  startDate: FormInputDate;
  showLineInReport: FormInputBoolean;
}

const TimeSeriesEdit: React.FC<Props> = (props: Props) => {
  const confirmDataConstant = {
    dialogText: '',
    errorMessage: '',
    buttonTextAccept: '',
    buttonTextDismiss: '',
    dialogTitle: '',
    isLoading: false,
    showDialog: false,
    submitDialog: () => undefined,
  };
  interface IsValidReturnType {
    hasError: boolean;
    errorText: string;
  }

  const { report, setTimeSeriesValues } = props;

  const [showForm, setShowForm] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState('');
  const [confirmDialogState, setConfirmDialogState] = useState<ConfirmDialogType>(confirmDataConstant);
  const [allTSNOResponse, setAllTSNOResponse] = useState<GetTSNOsResponse[]>([]);
  const [allCurrenciesResponse, setAllCurrenciesResponse] = useState<GetCurrenciesResponse[]>([]);
  const [hasCostDrivers, setSetHasCostDrivers] = useState(false);
  const [activeTimeSeriesId, setActiveTimeSeriesId] = useState('');
  const [parentState, setParentState] = useState({ id: '', name: '' });

  const [timeSerieToEditState, setTimeSerieToEditState] = useState<{ timeSeries: TimeSeries | null; edit: boolean }>({
    timeSeries: null,
    edit: false,
  });

  const [formValuesState, setFormValuesState] = useState<FormElement>({
    name: {
      value: '',
      rules: [
        {
          rule: ValidationRules.required,
          errorText: 'Name cannot be empty',
        },
      ],
      error: false,
      errorText: '',
    },
    description: {
      value: '',
      rules: [],
      error: false,
      errorText: '',
    },
    share: {
      value: undefined,
      rules: [
        {
          rule: ValidationRules.regExp,
          ruleHelper: /^(0(\.\d+)?|1(\.0+)?)$/,
          errorText: 'Share must be a number between 0-1',
        },
      ],
      error: false,
      errorText: '',
    },
    countryReportText: {
      value: '',
      rules: [
        {
          rule: ValidationRules.maxLength,
          ruleHelper: 50,
          errorText: 'Limit of 50 words',
        },
      ],
      error: false,
      errorText: '',
    },
    currencyInReportText: {
      value: '',
      rules: [
        {
          rule: ValidationRules.maxLength,
          ruleHelper: 50,
          errorText: 'Limit of 50 words',
        },
      ],
      error: false,
      errorText: '',
    },
    tsno: {
      value: '',
      rules: [],
      error: false,
      errorText: '',
    },
    currencyId: {
      value: '',
      rules: [],
      error: false,
      errorText: '',
    },
    indexDate: {
      value: null,
      rules: [],
      error: false,
      errorText: '',
    },
    multiplier: {
      value: undefined,
      rules: [],
      error: false,
      errorText: '',
    },
    divider: {
      value: undefined,
      rules: [],
      error: false,
      errorText: '',
    },
    inverse: {
      value: undefined,
      rules: [],
      error: false,
      errorText: '',
    },
    showLineInReport: {
      value: true,
      rules: [],
      error: false,
      errorText: '',
    },
    startDate: {
      value: null,
      rules: [],
      error: false,
      errorText: '',
    },
    movAvg: {
      value: undefined,
      rules: [],
      error: false,
      errorText: '',
    },
  });

  const isInputValid = (
    value: string | number | undefined | boolean | Dayjs,
    inputRules: Rule[]
  ): IsValidReturnType => {
    const returnValue = {
      hasError: false,
      errorText: '',
    };
    const notValidateShare = timeSerieToEditState?.timeSeries?.id !== report.benchmarkTimeSerie?.id;
    for (let index = 0; index < inputRules.length; index += 1) {
      if (inputRules[index].rule === ValidationRules.required && value === '') {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }
      if (inputRules[index].rule === ValidationRules.regExp && notValidateShare) {
        const regex = new RegExp(/^(0(\.\d+)?|1(\.0+)?)$/);
        let hasError = false;
        if (value) {
          hasError = !regex.test(value.toString());
        } else {
          hasError = true;
        }
        return {
          hasError,
          errorText: inputRules[index].errorText,
        };
      }
    }
    return returnValue;
  };

  const inputChangeHandler = (newValue: string | number | undefined | boolean | Dayjs, itemIdentifier: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formValuesStateCopy: any = { ...formValuesState };
    const updatedElement = { ...formValuesStateCopy[itemIdentifier] };

    updatedElement.value = newValue;
    if (updatedElement.rules) {
      const { hasError, errorText } = isInputValid(newValue, updatedElement.rules);
      updatedElement.error = hasError;
      updatedElement.errorText = hasError ? errorText : '';
    } else if (updatedElement.error) {
      updatedElement.error = false;
    }

    formValuesStateCopy[itemIdentifier] = updatedElement;
    setFormValuesState(formValuesStateCopy);
  };
  const resetValuesFormValues = () => {
    const formValuesStateCopy = deepCopy(formValuesState);

    formValuesStateCopy.indexDate.value = null;
    formValuesStateCopy.startDate.value = null;
    formValuesStateCopy.currencyId.value = '';
    formValuesStateCopy.name.value = '';
    formValuesStateCopy.share.value = undefined;
    formValuesStateCopy.description.value = '';
    formValuesStateCopy.tsno.value = '';
    formValuesStateCopy.multiplier.value = undefined;
    formValuesStateCopy.divider.value = undefined;
    formValuesStateCopy.movAvg.value = undefined;
    formValuesStateCopy.countryReportText.value = '';
    formValuesStateCopy.currencyInReportText.value = '';
    formValuesStateCopy.inverse.value = undefined;
    formValuesStateCopy.showLineInReport.value = true;

    setFormValuesState(formValuesStateCopy);
  };

  const closeForm = () => {
    setActiveTimeSeriesId('');
    resetValuesFormValues();
    setShowForm(false);
  };
  const resetEFormError = () => {
    const formValuesStateCopy = deepCopy(formValuesState);
    formValuesStateCopy.name.error = false;
    formValuesStateCopy.name.errorText = '';
    formValuesStateCopy.share.error = false;
    formValuesStateCopy.share.errorText = '';
    formValuesStateCopy.tsno.error = false;
    formValuesStateCopy.tsno.errorText = '';
    formValuesStateCopy.description.error = false;
    formValuesStateCopy.description.errorText = '';
    formValuesStateCopy.countryReportText.error = false;
    formValuesStateCopy.countryReportText.errorText = '';
    formValuesStateCopy.currencyInReportText.error = false;
    formValuesStateCopy.currencyInReportText.errorText = '';
    formValuesStateCopy.currencyId.error = false;
    formValuesStateCopy.currencyId.errorText = '';
    formValuesStateCopy.startDate.error = false;
    formValuesStateCopy.startDate.errorText = '';
    formValuesStateCopy.indexDate.error = false;
    formValuesStateCopy.indexDate.errorText = '';
    formValuesStateCopy.multiplier.error = false;
    formValuesStateCopy.multiplier.errorText = '';
    formValuesStateCopy.divider.error = false;
    formValuesStateCopy.divider.errorText = '';
    formValuesStateCopy.movAvg.error = false;
    formValuesStateCopy.movAvg.errorText = '';
    formValuesStateCopy.inverse.error = false;
    formValuesStateCopy.inverse.errorText = '';
    formValuesStateCopy.showLineInReport.error = false;
    formValuesStateCopy.showLineInReport.errorText = '';
    setFormValuesState(formValuesStateCopy);
  };

  const clearIndexDate = () => {
    const formValuesStateCopy = deepCopy(formValuesState);
    formValuesStateCopy.indexDate.value = null;
    formValuesStateCopy.indexDate.error = false;
    formValuesStateCopy.indexDate.errorText = '';
    setFormValuesState(formValuesStateCopy);
  };
  const clearStartDate = () => {
    const formValuesStateCopy = deepCopy(formValuesState);
    formValuesStateCopy.startDate.value = null;
    formValuesStateCopy.startDate.error = false;
    formValuesStateCopy.startDate.errorText = '';
    setFormValuesState(formValuesStateCopy);
  };

  const openTimeSeriesFormHandler = (
    timeSeries: TimeSeries | undefined,
    edit: boolean,
    parentId: string,
    parentName: string
  ) => {
    setActiveTimeSeriesId(timeSeries?.id ?? '');
    resetEFormError();
    setParentState({ id: parentId, name: parentName });
    setTimeSerieToEditState({ timeSeries: timeSeries ?? null, edit });
    if (edit) {
      const formValuesStateCopy = deepCopy(formValuesState);
      const hasCostDriversEdit = (timeSeries?.costDrivers && timeSeries?.costDrivers.length > 0) ?? false;
      setSetHasCostDrivers(hasCostDriversEdit);
      let tsno = '';
      if (timeSeries?.tsno) {
        const item = allTSNOResponse.find((t) => t.tsno === timeSeries.tsno);
        if (item) {
          tsno = item.tsno.toString() + ' - ' + item.description;
        }
      }

      let currencyId = '';
      if (timeSeries?.currencyId) {
        const currencyOBject = allCurrenciesResponse.find(
          (c) => c.pdmId.toString() === timeSeries.currencyId.toString()
        );
        if (currencyOBject) {
          currencyId = `${currencyOBject.threeLetterCode} - ${currencyOBject.name} (${currencyOBject.pdmId})`;
        }
      }

      let indexDate = null;
      if (timeSeries?.indexDate) {
        const year = timeSeries.indexDate.substring(0, timeSeries.indexDate.indexOf('M'));
        const month = timeSeries.indexDate.split('M').pop();

        if (year && month) {
          indexDate = new Date(Number(year), Number(month) - 1);
        }
      }
      formValuesStateCopy.indexDate.value = indexDate;
      formValuesStateCopy.startDate.value = timeSeries?.startDate ? new Date(timeSeries.startDate) : null;
      formValuesStateCopy.currencyId.value = hasCostDriversEdit ? '' : currencyId;
      formValuesStateCopy.name.value = timeSeries?.name || '';
      formValuesStateCopy.share.value = timeSeries?.share || undefined;
      formValuesStateCopy.description.value = timeSeries?.description || '';
      formValuesStateCopy.tsno.value = tsno;
      formValuesStateCopy.multiplier.value = timeSeries?.multiplier || undefined;
      formValuesStateCopy.divider.value = timeSeries?.divider || undefined;
      formValuesStateCopy.movAvg.value = timeSeries?.movAvg || undefined;
      formValuesStateCopy.countryReportText.value = timeSeries?.country || '';
      formValuesStateCopy.currencyInReportText.value = timeSeries?.currency || '';
      formValuesStateCopy.inverse.value = timeSeries?.inverse || undefined;
      formValuesStateCopy.showLineInReport.value = timeSeries?.showLineInReport === false ? false : true;

      setFormValuesState(formValuesStateCopy);
      setShowForm(true);
      return;
    } else {
      setSetHasCostDrivers(false);
    }
    resetValuesFormValues();
    setShowForm(true);
  };

  const isFormValidFrontend = (): boolean => {
    let isValid = true;
    //   eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formValuesStateCopy: any = { ...formValuesState };

    Object.entries(formValuesState).forEach(([itemIdentifier, formElement]) => {
      const { hasError, errorText } = isInputValid(formElement.value, formElement.rules);

      if (hasError) {
        isValid = false;
        const updatedElement = { ...formValuesStateCopy[itemIdentifier] };
        updatedElement.error = hasError;
        updatedElement.errorText = errorText;
        formValuesStateCopy[itemIdentifier] = updatedElement;
      }
    });
    setFormValuesState(formValuesStateCopy);
    return isValid;
  };

  const saveTimeSeriesData = async (timeSeriesRequest: TimeSeriesRequest): Promise<Report | undefined> => {
    try {
      const response = await axiosPrivate.patch<Report>(
        `/reports/${report._id}`,
        {
          ...timeSeriesRequest,
        },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      return undefined;
    }
    return undefined;
  };

  const createOrUpdateTimeSerieHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isFormValidFrontend()) {
      setFormIsLoading(true);
      setErrorMessageState('');
      let tsno: number | undefined = undefined;
      let currencyId: number | undefined = undefined;
      let startDate = '';
      let indexDate = '';

      if (formValuesState.tsno.value) {
        const tsnoToSave = formValuesState.tsno.value.substring(0, formValuesState.tsno.value.indexOf(' -'));
        if (tsnoToSave) {
          tsno = Number(tsnoToSave);
        }
      }

      if (formValuesState.currencyId.value) {
        const currencyIdToSave = formValuesState.currencyId.value.substring(
          formValuesState.currencyId.value.indexOf('(') + 1,
          formValuesState.currencyId.value.lastIndexOf(')')
        );
        if (currencyIdToSave) {
          currencyId = Number(currencyIdToSave);
        }
      }

      if (formValuesState.startDate?.value) {
        const dayjsDate = dayjs(formValuesState.startDate?.value);
        let monthFixZero = '';
        if (dayjsDate.month() < 10) {
          monthFixZero = '0';
        }
        startDate = `${dayjsDate.year()}-${monthFixZero}${dayjsDate.month() + 1}-01`;
      }

      if (formValuesState.indexDate?.value) {
        const dayjsDate = dayjs(formValuesState.indexDate?.value);
        const month = dayjsDate.month();
        let monthFixZero = '';
        if (month < 10) {
          monthFixZero = '0';
        }
        indexDate = `${dayjsDate.year()}M${monthFixZero}${dayjsDate.month() + 1}`;
      }
      const lineId: undefined | string = timeSerieToEditState?.timeSeries?.id ?? undefined;
      const timeSerieToSave: TimeSeriesRequest = {
        reportId: report._id,
        timeSerieToUpdate: {
          parentLine: parentState.id,
          id: lineId,
          name: formValuesState.name.value,
          country: formValuesState.countryReportText.value,
          currency: formValuesState.currencyInReportText.value,
          currencyId: currencyId,
          description: formValuesState.description.value,
          tsno: tsno,
          indexDate: indexDate,
          movAvg: formValuesState.movAvg?.value ?? undefined,
          startDate: startDate,
          newPeriod: 'MONTH',
          multiplier: formValuesState.multiplier?.value ?? undefined,
          divider: formValuesState.divider?.value ?? undefined,
          inverse: formValuesState.inverse?.value ?? undefined,
          share: formValuesState.share.value ? Number(formValuesState.share.value) : undefined,
          showLineInReport: formValuesState.showLineInReport.value ?? false,
        },
      };
      const newReport = await saveTimeSeriesData(timeSerieToSave);
      // TODO check for errors and show it

      if (newReport === undefined) {
        setErrorMessageState('Something went wrong try again later.');
      } else {
        setTimeSeriesValues();
        closeForm();
      }
      setFormIsLoading(false);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    async function getAllTSNOs() {
      try {
        const allTSNO = await axiosPrivate.get('general/tsnos', {
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        setAllTSNOResponse(allTSNO?.data ?? []);
      } catch (error) {
        console.log(error);
        source.cancel('Cancelling in cleanup');
        setErrorMessageState('Something went wrong try again later.');
      }
    }
    getAllTSNOs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    async function getAllCurrencies() {
      try {
        const allCurrencies = await axiosPrivate.get('general/currencies', {
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        setAllCurrenciesResponse(allCurrencies?.data ?? []);
      } catch (error) {
        source.cancel('Cancelling in cleanup');
        setErrorMessageState('Something went wrong try again later.');
      }
    }
    getAllCurrencies();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editTimeSerieForm = (
    <>
      {showForm && (
        <form>
          <hr className="mt-3 mb-3" />
          <header className={classes.formHeader}>
            {timeSerieToEditState?.edit && <h2>Edit time serie</h2>}
            {!timeSerieToEditState?.edit && (
              <>
                <h2>Add cost driver</h2> to {parentState?.name}
              </>
            )}
            <main>
              <div className={classes.inputBasic}>
                <TextFieldMui
                  className="mb-3 mt-3"
                  id="name"
                  label="Name"
                  required
                  error={formValuesState.name.error}
                  helperText={formValuesState.name.errorText}
                  type={TextFieldType.text}
                  value={formValuesState.name.value}
                  valueChanged={(newValue) => inputChangeHandler(newValue, 'name')}
                />
              </div>
              {(timeSerieToEditState?.timeSeries?.id !== report.benchmarkTimeSerie?.id ||
                timeSerieToEditState?.edit === false) && (
                <div className={classes.inputBasic}>
                  <TextFieldMui
                    className="mb-3 mt-3"
                    id="share"
                    label="Share"
                    required
                    error={formValuesState.share.error}
                    helperText={formValuesState.share.errorText}
                    type={TextFieldType.number}
                    value={formValuesState.share.value ?? ''}
                    valueChanged={(newValue) => inputChangeHandler(newValue, 'share')}
                  />
                </div>
              )}
              <div className={classes.inputWithTooltip}>
                <TextFieldMui
                  className="mb-3 mt-3"
                  id="description"
                  label="Description"
                  multiline
                  error={formValuesState.description.error}
                  helperText={formValuesState.description.errorText}
                  type={TextFieldType.text}
                  value={formValuesState.description.value}
                  valueChanged={(newValue) => inputChangeHandler(newValue, 'description')}
                />
                <TooltipPrognos title="Här kommer en lång description">
                  <Icon className={classes.questionmarkIcon} path={mdiHelp} size={0.5} />
                </TooltipPrognos>
              </div>
              {!hasCostDrivers && (
                <>
                  <div className={classes.inputBasic}>
                    <Autocomplete
                      className="mb-3 mt-3"
                      inputValue={formValuesState.tsno.value}
                      value={
                        allTSNOResponse.find(
                          (x) => x.tsno.toString() + ' - ' + x.description === formValuesState.tsno.value
                        ) || null
                      }
                      id="tsno"
                      getOptionLabel={(option) => {
                        return option.tsno.toString() + ' - ' + option.description;
                      }}
                      isOptionEqualToValue={(option, value) => option.tsno.toString() === value.tsno.toString()}
                      filterOptions={(options, state) => {
                        const displayOptions = options.filter((option) => {
                          const searchValue = `${option.tsno.toString()} ${option.description}`;
                          const found = searchValue
                            .toLowerCase()
                            .trim()
                            .includes(state.inputValue.toLowerCase().trim());
                          return found;
                        });
                        return displayOptions;
                      }}
                      options={allTSNOResponse}
                      sx={{ width: '100%' }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.tsno}>
                            {option.tsno} - {option.description} <br />
                            {option.twoLetterCountryCode} - ({option.firstDataPointDate} - {option.latestDataPointDate})
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Tsno" />}
                      onInputChange={(_, newValue) => inputChangeHandler(newValue, 'tsno')}
                    />
                  </div>
                  <div className={classes.inputBasic}>
                    <Autocomplete
                      className="mb-3 mt-3"
                      inputValue={formValuesState.currencyId.value}
                      value={
                        allCurrenciesResponse.find((x) => {
                          return `${x.threeLetterCode} - ${x.name} (${x.pdmId})` === formValuesState.currencyId.value;
                        }) || null
                      }
                      id="currencyId"
                      getOptionLabel={(option: GetCurrenciesResponse) =>
                        `${option.threeLetterCode} - ${option.name} (${option.pdmId})`
                      }
                      options={allCurrenciesResponse}
                      sx={{ width: '100%' }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.threeLetterCode} - {option.name} ({option.pdmId})`
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Currency for script" />}
                      onInputChange={(_, newValue) => inputChangeHandler(newValue, 'currencyId')}
                    />
                  </div>
                </>
              )}
              <div className={classes.inputBasic}>
                <TextFieldMui
                  className="mb-3 mt-3"
                  id="countryReportText"
                  label="Country in customer view"
                  error={formValuesState.countryReportText.error}
                  helperText={formValuesState.countryReportText.errorText}
                  type={TextFieldType.text}
                  value={formValuesState.countryReportText.value}
                  valueChanged={(newValue) => inputChangeHandler(newValue, 'countryReportText')}
                />
              </div>

              <div className={classes.inputBasic}>
                <TextFieldMui
                  className="mb-3 mt-3"
                  id="currencyInReportText"
                  label="Currency in customer view"
                  error={formValuesState.currencyInReportText.error}
                  helperText={formValuesState.currencyInReportText.errorText}
                  type={TextFieldType.text}
                  value={formValuesState.currencyInReportText.value}
                  valueChanged={(newValue) => inputChangeHandler(newValue, 'currencyInReportText')}
                />
              </div>
              <div className={classes.inputWithTooltip}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={`mb-3 mt-3 ${classes.fullWidth}`}
                    openTo="year"
                    views={['year', 'month']}
                    label="Index date"
                    defaultValue={null}
                    maxDate={dayjs().add(1, 'M')}
                    format={'YYYY-MM'}
                    value={formValuesState.indexDate?.value ? dayjs(formValuesState.indexDate?.value) : null}
                    onChange={(newValue) => inputChangeHandler(newValue ?? undefined, 'indexDate')}
                    slotProps={{
                      field: { clearable: true, onClear: () => clearIndexDate() },
                    }}
                  />
                </LocalizationProvider>
                <TooltipPrognos
                  title={`Date to transform time series into index type with given date as reference point, i.e. '2015YA','2016Q1','2019M01',2020W23, 2015-01-08", example = "2017M01"`}
                >
                  <Icon className={classes.questionmarkIcon} path={mdiHelp} size={0.5} />
                </TooltipPrognos>
              </div>
              <div className={classes.inputWithTooltip}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={`mb-3 mt-3 ${classes.fullWidth}`}
                    openTo="year"
                    views={['year', 'month']}
                    label="Start date"
                    format={'YYYY-MM'}
                    maxDate={dayjs().add(1, 'M')}
                    value={formValuesState.startDate?.value ? dayjs(formValuesState.startDate?.value) : null}
                    onChange={(newValue) => inputChangeHandler(newValue ?? undefined, 'startDate')}
                    slotProps={{
                      field: { clearable: true, onClear: () => clearStartDate() },
                    }}
                  />
                </LocalizationProvider>
                <TooltipPrognos title={`Date to decide when the first data point should be", example = "1990-01-01""`}>
                  <Icon className={classes.questionmarkIcon} path={mdiHelp} size={0.5} />
                </TooltipPrognos>
              </div>
              <div className={classes.inputWithTooltip}>
                <TextFieldMui
                  className="mb-3 mt-3"
                  id="multiplier"
                  label="Multiplier"
                  error={formValuesState.multiplier?.error}
                  helperText={formValuesState.multiplier?.errorText}
                  type={TextFieldType.number}
                  value={formValuesState.multiplier?.value ?? ''}
                  valueChanged={(newValue) => inputChangeHandler(newValue, 'multiplier')}
                />

                <TooltipPrognos title={`A Float to multiply every data point with", example = "1000.0"`}>
                  <Icon className={classes.questionmarkIcon} path={mdiHelp} size={0.5} />
                </TooltipPrognos>
              </div>
              <div className={classes.inputWithTooltip}>
                <TextFieldMui
                  className="mb-3 mt-3"
                  id="divider"
                  label="Divider"
                  error={formValuesState.divider?.error}
                  helperText={formValuesState.divider?.errorText}
                  type={TextFieldType.number}
                  value={formValuesState.divider?.value ?? ''}
                  valueChanged={(newValue) => inputChangeHandler(newValue, 'divider')}
                />
                <TooltipPrognos title={`An Float to divide every data point with", example = "1000.0`}>
                  <Icon className={classes.questionmarkIcon} path={mdiHelp} size={0.5} />
                </TooltipPrognos>
              </div>
              <div className={classes.inputWithTooltip}>
                <TextFieldMui
                  className="mb-3 mt-3"
                  id="movAvg"
                  label="Moving average"
                  error={formValuesState.movAvg?.error}
                  helperText={formValuesState.movAvg?.errorText}
                  type={TextFieldType.number}
                  value={formValuesState.movAvg?.value ?? ''}
                  valueChanged={(newValue) => inputChangeHandler(newValue, 'movAvg')}
                />
                <TooltipPrognos
                  title={`Number of periods to use when calculating simple moving average (SMA) for time series", example = "3"`}
                >
                  <Icon className={classes.questionmarkIcon} path={mdiHelp} size={0.5} />
                </TooltipPrognos>
              </div>
              <div className={classes.inputWithTooltip}>
                <FormLabel component="legend">
                  <Checkbox
                    checked={formValuesState.inverse?.value ?? false}
                    color="primary"
                    onChange={(event) => inputChangeHandler(event.target.checked, 'inverse')}
                    sx={{
                      color: '#19555a',
                      '&.Mui-checked': {
                        color: '#19555a',
                      },
                    }}
                  />
                  Inverse
                </FormLabel>
                <TooltipPrognos title={`Boolean value to apply the multiplicative inverse on the time series`}>
                  <Icon className={classes.questionmarkIcon} path={mdiHelp} size={0.5} />
                </TooltipPrognos>
              </div>
              <div className={classes.inputWithTooltip}>
                <FormLabel component="legend">
                  <Checkbox
                    checked={formValuesState.showLineInReport?.value ?? false}
                    color="primary"
                    onChange={(event) => inputChangeHandler(event.target.checked, 'showLineInReport')}
                    sx={{
                      color: '#19555a',
                      '&.Mui-checked': {
                        color: '#19555a',
                      },
                    }}
                  />
                  Show line in report
                </FormLabel>

                <TooltipPrognos title={`Boolean if the line should be shown separately in the report`}>
                  <Icon className={classes.questionmarkIcon} path={mdiHelp} size={0.5} />
                </TooltipPrognos>
              </div>
            </main>
          </header>
          <div className={`${classes.formActions} mt-3 mb-3`}>
            <Button
              className="mr-4"
              type={ButtonType.button}
              buttonStyleType={ButtonStyleType.secondary}
              text="Cancel"
              onClick={closeForm}
            />
            <Button
              loading={formIsLoading}
              type={ButtonType.button}
              buttonStyleType={ButtonStyleType.primary}
              text={timeSerieToEditState.edit ? 'Update' : 'Add'}
              onClick={(event) => createOrUpdateTimeSerieHandler(event)}
            />
          </div>
          <hr />
        </form>
      )}
    </>
  );

  const deleteTimeSerie = async (timeSeries: TimeSeries, confirmDialogValues: ConfirmDialogType) => {
    const dialogValuesCopy = { ...confirmDialogValues, isLoading: true };
    setConfirmDialogState(dialogValuesCopy);
    // TODO FIX DELETE function
    let errorMessage = '';
    try {
      const response = await axiosPrivate.post<{ report: Report; error: string }>(
        `reports/removeCostDriver`,
        {
          reportId: report._id,
          costDriverId: timeSeries.id,
        },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      if (response.data && !response.data.error) {
        //const newReport = response.data.report;
        setConfirmDialogState({ ...confirmDataConstant });
        // TODO pass report data?
        setTimeSeriesValues();
      } else {
        errorMessage = 'nothing to update';
      }
    } catch (error) {
      errorMessage = 'Something went wrong try again later';
    }

    if (errorMessage) {
      const confirmDialogValuesCopy = { ...confirmDialogValues, errorMessage: errorMessage, isLoading: false };
      setConfirmDialogState(confirmDialogValuesCopy);
      return;
    }
  };

  const deleteConfirmTimeSerieHandler = (timeSeries: TimeSeries) => {
    const values = {
      dialogTitle: 'Confirm',
      errorMessage: '',
      dialogText: `${'Are you sure you want to delete "'}${timeSeries.name}" ?`,
      buttonTextAccept: 'Delete',
      showDialog: true,
      submitDialog: () => deleteTimeSerie(timeSeries, confirmDialogState),
    };
    values.submitDialog = () => deleteTimeSerie(timeSeries, values);
    setConfirmDialogState(values);
  };

  const closeConfirmAlertDialog = () => {
    const confirmAlertDataConstantCopy = { ...confirmDataConstant };
    setConfirmDialogState(confirmAlertDataConstantCopy);
  };

  const reportListItem = (
    timeSeries: TimeSeries,
    isBenchmarkTimeSerie = false,
    parentId: string,
    parentName: string
  ): JSX.Element => (
    <li key={timeSeries.id} className={timeSeries.id === activeTimeSeriesId ? 'activeListItem' : ''}>
      <div>
        <span className={classes.reportName}>{timeSeries.name}</span>
        <span className={classes.share}>
          {timeSeries.share && (
            <>
              {' '}
              <span>
                ({(timeSeries.share * 100).toFixed(6).replace(/\.?0+$/, '')}
                %)
              </span>
            </>
          )}
        </span>
        {timeSeries.showLineInReport === false ? <span className={classes.hiddenInReport}>Hidden line</span> : ''}
        {timeSeries?.lineErrors && timeSeries?.lineErrors?.length > 0 && (
          <div
            style={{
              flex: '0 0 auto',
              color: 'red',
              borderRadius: '3px',
              fontSize: '12px',
            }}
          >
            {timeSeries?.lineErrors.map((l) => (
              <div key={l}>{l}</div>
            ))}
          </div>
        )}
      </div>

      <div className={classes.actions}>
        {!isBenchmarkTimeSerie && (
          <IconButton
            className="ml-2"
            buttonStyleType={IconButtonStyleType.delete}
            areaLabel="Delete time serie"
            size={0.8}
            icon={mdiDelete}
            onClick={() => deleteConfirmTimeSerieHandler(timeSeries)}
          />
        )}
        <IconButton
          className="ml-2"
          buttonStyleType={IconButtonStyleType.add}
          areaLabel="Add time serie"
          icon={mdiPlus}
          size={0.8}
          onClick={() => openTimeSeriesFormHandler(undefined, false, timeSeries.id, timeSeries.name)}
        />
        <IconButton
          className="ml-2"
          buttonStyleType={IconButtonStyleType.edit}
          areaLabel="Edit time serie"
          size={0.8}
          icon={mdiPencil}
          onClick={() => openTimeSeriesFormHandler(timeSeries, true, parentId, parentName)}
        />
      </div>
    </li>
  );

  const costDriverElement = (costDrivers: TimeSeries[], parentId: string, parentName: string): JSX.Element => (
    <ul>
      {costDrivers.map((costDriver) => (
        <ul key={costDriver.id}>
          {reportListItem(costDriver, false, parentId, parentName)}
          {costDriver.costDrivers && costDriverElement(costDriver.costDrivers, costDriver.id, costDriver.name)}
        </ul>
      ))}
    </ul>
  );
  const timeSeriesElement = (): JSX.Element => {
    let returnValue: JSX.Element = <div />;
    const benchmarkTimeSerie = report?.benchmarkTimeSerie;

    if (benchmarkTimeSerie) {
      returnValue = (
        <ul>
          {reportListItem(benchmarkTimeSerie, true, benchmarkTimeSerie.id, benchmarkTimeSerie.name)}
          {benchmarkTimeSerie.costDrivers &&
            costDriverElement(benchmarkTimeSerie.costDrivers, benchmarkTimeSerie.id, benchmarkTimeSerie.name)}
        </ul>
      );
    }
    return returnValue;
  };
  const reportGeneralErrors = (): JSX.Element => {
    return (
      <>
        {report.reportGeneralErrors &&
          report.reportGeneralErrors.map((reportGeneralErrors) => (
            <div
              className="mt-3 mb-5"
              style={{
                backgroundColor: '#FFCDD2',
                border: '1px solid #EF9A9A',
                padding: '8px',
                borderRadius: '3px',
              }}
              key={reportGeneralErrors}
            >
              {reportGeneralErrors}
            </div>
          ))}
      </>
    );
  };

  return (
    <div className={classes.card}>
      <h2>Time series</h2>
      {report?.isReportValid === false && (
        <div
          className="mt-3 mb-5"
          style={{
            backgroundColor: '#FFCDD2',
            border: '1px solid #EF9A9A',
            padding: '8px',
            borderRadius: '3px',
          }}
        >
          Report is not valid!
        </div>
      )}
      {reportGeneralErrors()}
      {errorMessageState && (
        <div
          className="mt-3 mb-5"
          style={{
            backgroundColor: '#FFCDD2',
            border: '1px solid #EF9A9A',
            padding: '8px',
            borderRadius: '3px',
          }}
        >
          {errorMessageState}
        </div>
      )}
      {editTimeSerieForm}
      {timeSeriesElement()}
      <ConfirmDialog
        isLoading={confirmDialogState?.isLoading || false}
        errorMessage={confirmDialogState.errorMessage}
        submitDialog={confirmDialogState.submitDialog}
        showDialog={confirmDialogState.showDialog}
        closeDialog={closeConfirmAlertDialog}
        buttonTextAccept={confirmDialogState?.buttonTextAccept}
        dialogText={confirmDialogState?.dialogText}
      />
    </div>
  );
};

export default TimeSeriesEdit;
