import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button, { ButtonType, ButtonStyleType } from '../../../ui/button/Button';
import TextField, { TextFieldType } from '../../../ui/input/TextField';
import routerPaths from '../../../router/RouterPaths';
/* import { RootStore } from '../../../store'; */
import jwt_decode from 'jwt-decode';
/* import { loginUser } from '../../../store/actions/authAction';
 */
import classes from './LoginPage.module.scss';

import logoType from '../../../assets/images/Prognos_tailored_logotyp_RGB.png';
import useAuth from '../../../hooks/useAuth';
import axiosPublic from '../../../plugins/Axios';
import { ROLE } from '../../../context/Roles.enum';

export type JwtPayload = {
  sub: string;
  roles: ROLE[];
  userId: string;
  customerId: string;
  iat: string;
  exp: string;
};

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref?.current?.focus();
  }, []);

  const fromLocation = location.state?.from?.pathname || '/';

  const loading = false;
  const isValidForm = () => {
    let valid = true;
    if (username === '') {
      valid = false;
    }
    return valid;
  };

  interface ILoginResponse {
    exp: number;
    iat: number;
    jwtPayload: JwtPayload;
  }

  const loginSubmitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isValidForm()) {
      try {
        const response = await axiosPublic.post('/auth/login', JSON.stringify({ email: username, password }), {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        if (!response?.data?.accessToken) throw Error;
        const payload: ILoginResponse = jwt_decode(response.data.accessToken);
        localStorage.setItem('accessToken', response.data.accessToken);
        auth?.saveAuthData({
          accessToken: response.data.accessToken,
          customerId: payload.jwtPayload.customerId,
          exp: payload.exp,
          iat: payload.iat,
          roles: payload.jwtPayload.roles,
          userId: payload.jwtPayload.userId,
        });
        setErrorMessage('');
        setUsername('');
        setPassword('');
        navigate(fromLocation, { replace: true });
      } catch (err) {
        setErrorMessage('Login Failed');
      }
    }
  };

  return (
    <>
      <div className={classes.logotypeContainer}>
        <img src={logoType} className={`${classes.logotype} mb-6`} alt="logo" />
      </div>
      {errorMessage && <div className="errorText">{errorMessage}</div>}
      <form onSubmit={loginSubmitHandler} className={classes.form}>
        <TextField
          className="mb-4"
          id="username"
          label="E-mail address"
          type={TextFieldType.email}
          value={username}
          valueChanged={(newValue) => setUsername(newValue)}
        />
        <TextField
          className="mb-4"
          autoComplete="current-password"
          label="Password"
          id="password"
          value={password}
          type={TextFieldType.password}
          valueChanged={(newValue) => setPassword(newValue)}
        />
        <div className="floatRight mb-4 col-sm-10">
          <Button type={ButtonType.submit} loading={loading} buttonStyleType={ButtonStyleType.primary} text="Sign in" />
        </div>
        <Link to={routerPaths.forgotPassword}>Forgot your password?</Link>
      </form>
      <footer className={`${classes.footer} text-center`}>
        New user? <Link to={routerPaths.register}>Register here</Link>
      </footer>
    </>
  );
};
export default Login;
