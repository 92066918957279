import PropTypes from 'prop-types';
import React from 'react';
import { CircularProgress } from '@mui/material';
import classes from './Button.module.scss';
import Icon from '@mdi/react';

interface Props {
  text?: string;
  type: ButtonType;
  buttonStyleType: ButtonStyleType;
  small?: boolean;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  round?: boolean;
  icon?: {
    iconPath: string;
    iconTitle: string;
  };
}
export enum ButtonType {
  submit = 'submit',
  button = 'button',
}

export enum ButtonStyleType {
  primary = 'primary',
  add = 'add',
  secondary = 'secondary',
  third = 'third',
  delete = 'delete',
  iconSecondary = 'iconSecondary',
  text = 'text',
}

const Button: React.FC<Props> = ({
  type,
  buttonStyleType,
  disabled,
  loading,
  onClick,
  className,
  text,
  small,
  children,
  round,
  icon,
}) => {
  const setButtonTypeClass = (buttonStyle: string, smallButton: boolean) => {
    let buttonClasses = '';
    switch (buttonStyle) {
      case ButtonStyleType.primary:
        buttonClasses = classes.primary;
        break;
      case ButtonStyleType.secondary:
        buttonClasses = classes.secondary;
        break;
      case ButtonStyleType.iconSecondary:
        buttonClasses = classes.iconSecondary;
        break;
      case ButtonStyleType.delete:
        buttonClasses = classes.delete;
        break;
      case ButtonStyleType.third:
        buttonClasses = classes.third;
        break;
      case ButtonStyleType.add:
        buttonClasses = classes.add;
        break;
      case ButtonStyleType.text:
        buttonClasses = classes.text;
        break;
      default:
        break;
    }
    if (smallButton) {
      buttonClasses = `${buttonClasses} ${classes.small}`;
    }
    return buttonClasses;
  };

  const buttonTypeClass = setButtonTypeClass(buttonStyleType, small || false);

  return (
    <div className={classes.buttonContainer}>
      <div className={classes.buttonWrapper}>
        <button
          type={type === ButtonType.submit ? 'submit' : 'button'}
          className={`${buttonTypeClass} ${className} ${round ? classes.round : ''}
          ${icon ? classes.icon : ''} 
          ${buttonStyleType === ButtonStyleType.text ? classes.round : classes.button}
          `}
          disabled={disabled || loading}
          onClick={onClick}
        >
          {icon && <Icon path={icon.iconPath} size={'16px'} title={icon.iconTitle} />}
          {children}
          {text}
        </button>
        {loading && <CircularProgress size={18} className={classes.buttonProgress} />}
      </div>
    </div>
  );
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  onClick: () => undefined,
  className: '',
  small: false,
  text: '',
  children: '',
  round: false,
};

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf([ButtonType.submit, ButtonType.button]).isRequired,
  buttonStyleType: PropTypes.oneOf([
    ButtonStyleType.primary,
    ButtonStyleType.secondary,
    ButtonStyleType.delete,
    ButtonStyleType.third,
    ButtonStyleType.add,
    ButtonStyleType.iconSecondary,
    ButtonStyleType.text,
  ]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.node,
  round: PropTypes.bool,
};

export default Button;
