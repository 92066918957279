import PropTypes from 'prop-types';
import { Snackbar as MuiSnackbar } from '@mui/material';
import React from 'react';

import Icon from '@mdi/react';
import { mdiInformationOutline, mdiCheckCircleOutline } from '@mdi/js';
import classes from './Snackbar.module.scss';

export enum SnackbarType {
  success = 'success',
  info = 'info',
}
interface Props {
  showSnackbar: boolean;
  message: string;
  title?: string;
  snackbarType: SnackbarType.success | SnackbarType.info;
}

const Snackbar: React.FC<Props> = ({
  showSnackbar,
  snackbarType,
  message,
  title,
}) => {
  const SnackbarIcon = (): JSX.Element => {
    switch (snackbarType) {
      case SnackbarType.success: {
        return (
          <Icon path={mdiCheckCircleOutline} title="Success icon" size={1} />
        );
      }

      case SnackbarType.info: {
        return (
          <Icon
            path={mdiInformationOutline}
            title="Information icon"
            size={1}
          />
        );
      }
      default:
        return <></>;
    }
  };

  const classTypeName = (): string => {
    switch (snackbarType) {
      case SnackbarType.success:
        return classes.success;
      case SnackbarType.info:
        return classes.info;
      default:
        return '';
    }
  };
  return (
    <MuiSnackbar
      className={`${classTypeName()} ${classes.snackbar}`}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      open={showSnackbar}
    >
      <div className={classes.messageBox}>
        <SnackbarIcon />
        {title && (
          <div>
            <strong>{title}</strong>
            <br />
            {message}
          </div>
        )}
        {!title && message}
      </div>
    </MuiSnackbar>
  );
};
Snackbar.defaultProps = {
  title: '',
};

Snackbar.propTypes = {
  showSnackbar: PropTypes.bool.isRequired,
  snackbarType: PropTypes.oneOf([SnackbarType.success, SnackbarType.info])
    .isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default Snackbar;
