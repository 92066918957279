import React, { useState } from 'react';
import { mdiCheck, mdiCheckBold, mdiFileDocumentPlusOutline, mdiDelete, mdiPencil } from '@mdi/js';
import deepCopy from 'deepcopy';
import IconButton, { IconButtonStyleType } from '../../../ui/iconButton/IconButton';
import classes from './EditReport.module.scss';
import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import TextField, { TextFieldType, ValidationRules } from '../../../ui/input/TextField';
import ConfirmDialog, { ConfirmDialogType } from '../../../components/dialog/ConfirmDialog';
import { Reference, Report } from './EditReport';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ReportsTable from '../Customer/ReportsTable';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export interface Props {
  references: Reference[];
  reportId: string;
  reportStartDate: Date;
  customerId?: string;
  indexDate?: Date;
  startDate?: Date;
  setReferenceValues: () => void;
}
interface TimeSeriesData {
  x: Date;
  y?: number;
}
interface Rule {
  rule: string;
  ruleHelper?: string | number;
  errorText: string;
}
interface FormInput {
  value: string;
  rules?: Rule[];
  error: boolean;
  helpText?: string;
  errorText?: string;
  errorMessageBackend?: string;
}
interface FormElement {
  referenceName: FormInput;
  description: FormInput;
  country: FormInput;
  currency: FormInput;
}
interface IsValidReturnType {
  hasError: boolean;
  errorText: string;
}
enum REPORT_TYPE {
  SI = 'SI',
  STC = 'STC',
  CC = 'CC',
}
interface IReportList {
  _id: string;
  name: string;
  country: string;
  currency: string;
  isDraft: boolean;
  isShared: boolean;
  inCollections: string[];
  reportUrl: string;
  isReportValid: boolean;
  hideReportFromUserReportList: boolean;
  reportType?: REPORT_TYPE.CC | REPORT_TYPE.SI | REPORT_TYPE.STC;
  firstDatePointDate?: Date;
  lastDataPointDate?: Date;
  lastDateUpdatedDataFromPdm?: Date;
  lastDateChangedReportRequestData?: Date;
}
const EditReference: React.FC<Props> = (props: Props) => {
  const confirmDataConstant = {
    dialogText: '',
    errorMessage: '',
    buttonTextAccept: '',
    buttonTextDismiss: '',
    dialogTitle: '',
    isLoading: false,
    showDialog: false,
    submitDialog: () => undefined,
  };
  const { references, setReferenceValues } = props;
  const [reportsListState, setReportsListState] = useState<IReportList[]>([]);
  const [formIsLoadingState, setFormIsLoading] = useState(false);
  const [showReferenceForm, setShowReferenceForm] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState('');
  const [showEditDataPoints, setShowEditDataPoints] = useState(false);
  const [showUseReportAsReference, setShowUseReportAsReference] = useState(false);
  const [indexDate, setIndexDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [sourceReportId, setSourceReportId] = useState<string | null>(null);
  const [dataPointsToEditState, setDataPointsToEditState] = useState<TimeSeriesData[]>([]);
  const [confirmDialogState, setConfirmDialogState] = useState<ConfirmDialogType>(confirmDataConstant);
  const [referenceToEditState, setReferenceToEditState] = useState<Reference | null>(null);
  const [formValuesState, setFormValuesState] = useState<FormElement>({
    referenceName: {
      value: '',
      rules: [
        {
          rule: ValidationRules.required,
          errorText: 'Name cannot be empty',
        },
      ],
      error: false,
      errorText: '',
    },
    description: {
      value: '',
      rules: [],
      error: false,
      errorText: '',
    },
    country: {
      value: '',
      rules: [],
      error: false,
      errorText: '',
    },
    currency: {
      value: '',
      rules: [],
      error: false,
      errorText: '',
    },
  });

  const axiosPrivate = useAxiosPrivate();

  const deleteReference = async (reference: Reference, confirmDialogValues: ConfirmDialogType) => {
    const dialogValuesCopy = { ...confirmDialogValues, isLoading: true };
    setConfirmDialogState(dialogValuesCopy);

    axiosPrivate
      .delete(`/references/${reference.id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then(() => {
        setConfirmDialogState({ ...confirmDataConstant });
        if (referenceToEditState?.id === reference.id) setReferenceToEditState(null);
        setReferenceValues();
      })
      .catch((err) => {
        const confirmDialogValuesCopy = {
          ...confirmDialogValues,
          errorMessage: err.response?.data?.message ?? err.message,
          isLoading: false,
        };
        setConfirmDialogState(confirmDialogValuesCopy);
      });
  };

  const deleteConfirmReferenceHandler = (reference: Reference) => {
    const values = {
      dialogTitle: 'Confirm',
      errorMessage: '',
      dialogText: `${'Are you sure you want to delete "'}${reference.name}" ?`,
      buttonTextAccept: 'Delete',
      showDialog: true,
      submitDialog: () => deleteReference(reference, confirmDialogState),
    };
    values.submitDialog = () => deleteReference(reference, values);
    setConfirmDialogState(values);
  };

  const isInputValid = (value: string | number | Date | null, inputRules: Rule[]): IsValidReturnType => {
    const returnValue = {
      hasError: false,
      errorText: '',
    };
    for (let index = 0; index < inputRules.length; index += 1) {
      if (inputRules[index].rule === ValidationRules.required && value === '') {
        return {
          hasError: true,
          errorText: inputRules[index].errorText,
        };
      }
    }
    return returnValue;
  };

  const inputChangeHandler = (newValue: string | number | null | Date, itemIdentifier: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formValuesStateCopy: any = { ...formValuesState };
    const updatedElement = { ...formValuesStateCopy[itemIdentifier] };

    updatedElement.value = newValue;
    if (updatedElement.rules) {
      const { hasError, errorText } = isInputValid(newValue, updatedElement.rules);
      updatedElement.error = hasError;
      updatedElement.errorText = hasError ? errorText : '';
    } else if (updatedElement.error) {
      updatedElement.error = false;
    }

    formValuesStateCopy[itemIdentifier] = updatedElement;
    setFormValuesState(formValuesStateCopy);
  };

  const resetValuesFormValues = () => {
    const formValuesStateCopy = deepCopy(formValuesState);
    formValuesStateCopy.referenceName.value = '';
    formValuesStateCopy.description.value = '';
    formValuesStateCopy.country.value = '';
    formValuesStateCopy.currency.value = '';
    setFormValuesState(formValuesStateCopy);
    setDataPointsToEditState([]);
  };

  const closeReferenceForm = () => {
    resetValuesFormValues();
    setShowReferenceForm(false);
    setShowEditDataPoints(false);
    setShowUseReportAsReference(false);
    setSourceReportId(null);
    setReferenceToEditState(null);
  };
  const resetEFormError = () => {
    const formValuesStateCopy = deepCopy(formValuesState);
    formValuesStateCopy.referenceName.error = false;
    formValuesStateCopy.referenceName.errorText = '';
    formValuesStateCopy.description.error = false;
    formValuesStateCopy.description.errorText = '';
    formValuesStateCopy.country.error = false;
    formValuesStateCopy.country.errorText = '';
    formValuesStateCopy.currency.error = false;
    formValuesStateCopy.currency.errorText = '';
    setFormValuesState(formValuesStateCopy);
  };
  const openReferenceFormHandler = (reference?: Reference) => {
    setReferenceToEditState(null);
    resetEFormError();
    if (reference) {
      const formValuesStateCopy = deepCopy(formValuesState);
      setReferenceToEditState(reference);
      formValuesStateCopy.referenceName.value = reference.name;
      formValuesStateCopy.description.value = reference.description || '';
      formValuesStateCopy.country.value = reference.country || '';
      formValuesStateCopy.currency.value = reference.currency || '';
      setIndexDate(reference.indexDate ?? null);
      setStartDate(reference.startDate ?? null);
      setSourceReportId(reference.sourceReportId ?? null);
      setFormValuesState(formValuesStateCopy);
      setShowReferenceForm(true);
      setDataPointsToEditState(!reference.sourceReportId && reference?.rawData ? reference?.rawData : []);
      return;
    } else {
      setIndexDate(props.indexDate ?? null);
      setStartDate(props.reportStartDate);
    }
    resetValuesFormValues();
    setShowReferenceForm(true);
  };
  const isFormValidFrontend = (): boolean => {
    let isValid = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formValuesStateCopy: any = { ...formValuesState };

    Object.entries(formValuesState).forEach(([itemIdentifier, formElement]) => {
      const { hasError, errorText } = isInputValid(formElement.value, formElement.rules);

      if (hasError) {
        isValid = false;
        const updatedElement = { ...formValuesStateCopy[itemIdentifier] };
        updatedElement.error = hasError;
        updatedElement.errorText = errorText;
        formValuesStateCopy[itemIdentifier] = updatedElement;
      }
    });
    setFormValuesState(formValuesStateCopy);
    return isValid;
  };

  const handleUpdateDataPoint = (index: number, date: Date | null, value?: string) => {
    const numValue = Number(value);
    if (isNaN(numValue)) {
      setErrorMessageState('Value must be a number');
    }
    const stateCopy = [...dataPointsToEditState];

    stateCopy[index] = {
      x: date ? date : new Date(),
      y: numValue,
    };

    setDataPointsToEditState(stateCopy);
  };

  const handleAddNewDataPoint = () => {
    let date = props.reportStartDate;
    if (dataPointsToEditState.length > 0) {
      const lastDataPoint = dataPointsToEditState[dataPointsToEditState.length - 1];
      const lastDataPointDate = new Date(lastDataPoint.x);
      date = new Date(new Date(lastDataPoint.x).setMonth(lastDataPointDate.getMonth() + 1));
    }
    const stateCopy = [...dataPointsToEditState, { x: date, y: undefined }];

    setDataPointsToEditState(stateCopy);
  };

  const handleDeleteDataPoint = (index: number) => {
    const stateCopy = [...dataPointsToEditState];

    stateCopy.splice(index, 1);

    setDataPointsToEditState(stateCopy);
  };

  const handleEditDataPoints = () => {
    if (!referenceToEditState?.rawData) {
      setDataPointsToEditState([]);
    } else {
      setDataPointsToEditState(referenceToEditState?.rawData);
    }
    setShowUseReportAsReference(false);
    setShowEditDataPoints(true);
  };

  async function handleSetSourceReport(sourceReport: IReportList) {
    setSourceReportId(sourceReport._id);

    await axiosPrivate
      .get(`/reports/${sourceReport.reportUrl}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((res) => {
        const report: Report = res.data;
        console.log(report);

        const formValuesStateCopy: FormElement = { ...formValuesState };
        const updatedCountry = { ...formValuesStateCopy['country'] };
        const updatedCurrency = { ...formValuesStateCopy['currency'] };
        const updatedDescription = { ...formValuesStateCopy['description'] };
        const updatedReferenceName = { ...formValuesStateCopy['referenceName'] };

        updatedCountry.value = report.benchmarkTimeSerie.country ?? '';
        updatedCurrency.value = report.benchmarkTimeSerie.currency ?? '';
        updatedDescription.value = report.benchmarkTimeSerie?.description ?? '';
        updatedReferenceName.value = report.name;

        formValuesStateCopy['country'] = updatedCountry;
        formValuesStateCopy['currency'] = updatedCurrency;
        formValuesStateCopy['description'] = updatedDescription;
        formValuesStateCopy['referenceName'] = updatedReferenceName;
        setFormValuesState(formValuesStateCopy);
      });
  }

  const handleUnSetSourceReport = () => {
    setSourceReportId(null);
  };

  async function handleShowUseReportAsReference() {
    if (reportsListState.length === 0) {
      setErrorMessageState('');
      await axiosPrivate
        .get('/reports/getUserReportsAdmin', {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
          params: {
            customerId: props.customerId,
          },
        })
        .then((response) => {
          const reports: IReportList[] = response.data;
          if (reports) {
            const sortedReports = sortReportByName(reports);
            setReportsListState(sortedReports);
          }
        })
        .catch(() => {
          setErrorMessageState('Something went wrong try again later.');
        });
    }
    setShowUseReportAsReference(true);
  }

  const sortReportByName = (reportsToSort: IReportList[]) => {
    const reportCopy = deepCopy(reportsToSort);
    return reportCopy.sort((a, b) => {
      const nameA = a.name?.toUpperCase();
      const nameB = b.name?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };

  const createOrUpdateReferenceHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isFormValidFrontend()) {
      setFormIsLoading(true);

      let method = 'post';
      let url = '/references';

      if (referenceToEditState) {
        method = 'patch';
        url = `/references/${referenceToEditState.id}`;
      }

      axiosPrivate({
        method: method,
        url: url,
        data: {
          reportId: props.reportId,
          name: formValuesState.referenceName.value,
          description: formValuesState.description.value,
          country: formValuesState.country.value,
          currency: formValuesState.currency.value,
          timeSeriesRawData: dataPointsToEditState,
          indexDate: indexDate?.toString() === '' ? null : indexDate,
          startDate: startDate?.toString() === '' ? null : startDate,
          sourceReportId: sourceReportId,
        },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
        .then((response) => {
          if (response.status !== 200 && response.status !== 201) {
            setErrorMessageState('Something went wrong: ' + response.statusText);
          } else {
            setErrorMessageState('');
            closeReferenceForm();
            setReferenceValues();
          }
        })
        .catch((ex) => {
          if (ex.response?.data?.message) {
            setErrorMessageState('Something went wrong: ' + ex.response?.data?.message);
          } else {
            setErrorMessageState('Something went wrong: ' + ex);
          }
        })
        .finally(() => {
          setFormIsLoading(false);
        });
    }
    setFormIsLoading(false);
  };

  const editReferenceForm = (
    <>
      {showReferenceForm && (
        <form>
          <hr className="mt-3 mb-3" />
          <header>
            <h2>{referenceToEditState ? 'Edit reference' : 'New reference'}</h2>
            <main>
              <TextField
                className="mb-3 mt-3"
                id="referenceName"
                label="Name"
                error={formValuesState.referenceName.error}
                helperText={formValuesState.referenceName.errorText}
                type={TextFieldType.text}
                value={formValuesState.referenceName.value}
                valueChanged={(newValue) => inputChangeHandler(newValue, 'referenceName')}
              />
              <TextField
                className="mb-3 mt-3"
                id="description"
                label="Description"
                multiline
                error={formValuesState.description.error}
                helperText={formValuesState.description.errorText}
                type={TextFieldType.text}
                value={formValuesState.description.value}
                valueChanged={(newValue) => inputChangeHandler(newValue, 'description')}
              />
              <TextField
                className="mb-3 mt-3"
                id="currency"
                label="Currency"
                error={formValuesState.currency.error}
                helperText={formValuesState.currency.errorText}
                type={TextFieldType.text}
                value={formValuesState.currency.value}
                valueChanged={(newValue) => inputChangeHandler(newValue, 'currency')}
              />
              <TextField
                className="mb-3 mt-3"
                id="country"
                label="Country"
                error={formValuesState.country.error}
                helperText={formValuesState.country.errorText}
                type={TextFieldType.text}
                value={formValuesState.country.value}
                valueChanged={(newValue) => inputChangeHandler(newValue, 'country')}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Index Date"
                  className="mb-3 mt-3"
                  value={indexDate ? dayjs(indexDate) : null}
                  openTo="year"
                  views={['year', 'month']}
                  onChange={(date: Dayjs | null) => {
                    date ? setIndexDate(date.startOf('month').toDate()) : setIndexDate(null);
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  className="mb-3 mt-3 ml-3"
                  value={startDate ? dayjs(startDate) : null}
                  openTo="year"
                  views={['year', 'month']}
                  onChange={(date: Dayjs | null) => {
                    date ? setStartDate(date.startOf('month').toDate()) : setStartDate(null);
                  }}
                />
              </LocalizationProvider>
            </main>
          </header>
          {showUseReportAsReference && (
            <div>
              <hr className="mt-3 mb-3" />
              <h3>Select report to use as reference:</h3>
              <ReportsTable
                tableHeaders={[]}
                tableItems={
                  reportsListState &&
                  reportsListState.map((tableItem) => ({
                    id: tableItem._id,
                    name: tableItem.name,
                    country: '',
                    currency: '',
                    firstDatePointDate: tableItem.firstDatePointDate
                      ? new Date(tableItem.firstDatePointDate).toLocaleDateString('sv-SE')
                      : '',
                    lastDataPointDate: tableItem.lastDataPointDate
                      ? new Date(tableItem.lastDataPointDate).toLocaleDateString('sv-SE')
                      : '',
                    hidden: tableItem.hideReportFromUserReportList ? 'hidden' : '',
                    isReportValid: (
                      <>{!tableItem.isReportValid && <div className="isValidError">Error in report!</div>}</>
                    ),
                    actions: (
                      <>
                        {!sourceReportId && (
                          <IconButton
                            className="ml-2"
                            buttonStyleType={IconButtonStyleType.add}
                            areaLabel="Select report to use as reference"
                            size={0.8}
                            icon={mdiFileDocumentPlusOutline}
                            onClick={() => {
                              handleSetSourceReport(tableItem);
                            }}
                          />
                        )}
                        {sourceReportId === tableItem._id && (
                          <IconButton
                            className="ml-2"
                            buttonStyleType={IconButtonStyleType.add}
                            areaLabel="Select report to use as reference"
                            size={0.8}
                            icon={mdiCheckBold}
                            onClick={() => {
                              handleUnSetSourceReport();
                            }}
                          />
                        )}
                      </>
                    ),
                  }))
                }
                hasActions
                totalItems={reportsListState.length}
              />
              <Button
                className="mr-4"
                type={ButtonType.button}
                buttonStyleType={ButtonStyleType.secondary}
                text="Close"
                onClick={() => setShowUseReportAsReference(false)}
              />
              <hr className="mt-3 mb-3" />
            </div>
          )}

          {showEditDataPoints && (
            <div>
              <hr className="mt-3 mb-3" />
              <h3>Edit data points:</h3>
              {dataPointsToEditState.length === 0 && <div>Reference doesn't have any data points yet</div>}
              <ul>
                {dataPointsToEditState.map((x, index) => (
                  <li key={'dp' + index}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        className="mt-4"
                        value={x.x ? dayjs(x.x) : null}
                        openTo="year"
                        views={['year', 'month']}
                        onChange={(date: Dayjs | null) => {
                          date
                            ? handleUpdateDataPoint(index, new Date(date.startOf('month').toDate()), x.y?.toString())
                            : handleUpdateDataPoint(index, null, x.y?.toString());
                        }}
                      />
                    </LocalizationProvider>
                    <TextField
                      id={'value' + index}
                      label="Value"
                      className="mt-4 ml-2"
                      type={TextFieldType.text}
                      value={x.y ? x.y : ''}
                      valueChanged={(newValue) => {
                        handleUpdateDataPoint(index, dayjs(x.x).startOf('month').toDate(), newValue);
                      }}
                    />
                    <IconButton
                      className="ml-2"
                      buttonStyleType={IconButtonStyleType.delete}
                      areaLabel="Delete data point"
                      size={0.8}
                      icon={mdiDelete}
                      onClick={() => handleDeleteDataPoint(index)}
                    />
                  </li>
                ))}
              </ul>
              <Button
                className="ml-1 mt-2 mr-4"
                type={ButtonType.button}
                buttonStyleType={ButtonStyleType.add}
                text="Add new data point"
                onClick={handleAddNewDataPoint}
              />
              <Button
                className="mr-4"
                type={ButtonType.button}
                buttonStyleType={ButtonStyleType.secondary}
                text="Remove all"
                onClick={() => setDataPointsToEditState([])}
              />
              <Button
                className="mr-4"
                type={ButtonType.button}
                buttonStyleType={ButtonStyleType.secondary}
                text="Close"
                onClick={() => setShowEditDataPoints(false)}
              />
              <hr className="mt-3 mb-3" />
            </div>
          )}
          <div className={`${classes.formActions} mt-3 mb-3`}>
            {!showUseReportAsReference && dataPointsToEditState?.length === 0 && !showEditDataPoints && (
              <Button
                className="mr-4"
                type={ButtonType.button}
                buttonStyleType={ButtonStyleType.secondary}
                icon={sourceReportId ? { iconPath: mdiCheck, iconTitle: 'Chart Line Icon' } : undefined}
                text={sourceReportId ? 'Report used as reference' : 'Use report as reference'}
                onClick={handleShowUseReportAsReference}
              />
            )}
            {!showEditDataPoints && !sourceReportId && !showUseReportAsReference && (
              <Button
                className="mr-4"
                type={ButtonType.button}
                buttonStyleType={ButtonStyleType.secondary}
                text="Edit data points"
                onClick={handleEditDataPoints}
              />
            )}
            <Button
              className="mr-4"
              type={ButtonType.button}
              buttonStyleType={ButtonStyleType.secondary}
              text="Cancel"
              onClick={closeReferenceForm}
            />
            <Button
              loading={formIsLoadingState}
              type={ButtonType.submit}
              buttonStyleType={ButtonStyleType.primary}
              text="Save"
              onClick={createOrUpdateReferenceHandler}
            />
          </div>
          <hr />
        </form>
      )}
    </>
  );

  const referenceListItem = (reference: Reference): JSX.Element => (
    <li key={reference.id}>
      <span className={classes.reportName}>{reference.name}</span>
      <span className="buttons floatRight">
        <IconButton
          className="ml-2"
          buttonStyleType={IconButtonStyleType.delete}
          areaLabel="Delete reference"
          size={0.8}
          icon={mdiDelete}
          onClick={() => deleteConfirmReferenceHandler(reference)}
        />
        <IconButton
          className="ml-2"
          buttonStyleType={IconButtonStyleType.edit}
          areaLabel="Edit reference"
          size={0.8}
          icon={mdiPencil}
          onClick={() => openReferenceFormHandler(reference)}
        />
      </span>
    </li>
  );
  const closeConfirmAlertDialog = () => {
    const confirmAlertDataConstantCopy = { ...confirmDataConstant };
    setConfirmDialogState(confirmAlertDataConstantCopy);
  };

  return (
    <div className={classes.card}>
      <h2>References</h2>
      {errorMessageState && (
        <div
          className="mt-3 mb-5"
          style={{
            backgroundColor: '#FFCDD2',
            border: '1px solid #EF9A9A',
            padding: '8px',
            borderRadius: '3px',
          }}
        >
          {errorMessageState}
        </div>
      )}
      {editReferenceForm}
      <ul>{references && references.map((reference) => referenceListItem(reference))}</ul>
      <div className={classes.formActions}>
        <Button
          className="floatRight mt-3"
          type={ButtonType.button}
          buttonStyleType={ButtonStyleType.add}
          text="New"
          onClick={() => openReferenceFormHandler()}
        />
      </div>
      <ConfirmDialog
        isLoading={confirmDialogState?.isLoading || false}
        errorMessage={confirmDialogState.errorMessage}
        submitDialog={confirmDialogState.submitDialog}
        showDialog={confirmDialogState.showDialog}
        closeDialog={closeConfirmAlertDialog}
        buttonTextAccept={confirmDialogState?.buttonTextAccept}
        dialogText={confirmDialogState?.dialogText}
      />
    </div>
  );
};

export default React.memo(EditReference);
