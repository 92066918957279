import React, { useEffect, useState } from 'react';
import ReportComponent from '../../../components/report/ReportComponent';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

interface TimeSeriesData {
  x: Date;
  y: number;
}
interface TimeSeries {
  id: string;
  name: string;
  country?: string;
  currency?: string;
  description?: string;
  data?: TimeSeriesData[];
  costDrivers?: TimeSeries[];
  share?: number;
}
interface IGetOneReportResponse {
  _id: string;
  reportUrl: string;
  benchmarkTimeSerie: TimeSeries;
  indexStartDate?: Date;
  name: string;
  yLabelName?: string;
}

interface IReferenceResponse {
  id: string;
  name: string;
  country?: string;
  currency?: string;
  description?: string;
  report: string;
  data?: TimeSeriesData[];
  checked: boolean;
}

const ReportPage: React.FC = () => {
  const { reportUrl } = useParams();
  // eslint-disable-next-line

  const [reportState, setReportState] = useState<IGetOneReportResponse>();

  const [referenceState, setReferenceState] = useState<IReferenceResponse[]>([]);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    async function getReferences(reportId: string) {
      return await axiosPrivate
        .get(`/references/findReferenceForReport`, {
          params: {
            reportId: reportId,
          },
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200 && response?.data) {
            const references: IReferenceResponse[] = response.data;
            return references;
          }
        });
    }

    async function getReportData() {
      if (typeof reportUrl === 'string') {
        await axiosPrivate
          .get(`/reports/${reportUrl}`, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          })
          .then(async (response) => {
            if (response.status === 200 && response?.data) {
              const report: IGetOneReportResponse = response.data;

              if (report?._id) {
                const references = await getReferences(report._id);
                if (references) {
                  setReferenceState(references);
                }
              }
              setReportState(report);
            }
          })
          .catch((e) => {
            // TODO: handle error
          });
      }
    }
    getReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>{reportState && <ReportComponent references={referenceState} report={reportState} />}</div>
    </>
  );
};
export default ReportPage;
