import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { axiosPrivate } from '../../../../plugins/Axios';
import PageHeader from '../../../../components/pageHeader/PageHeader';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  Pagination,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import deepCopy from 'deepcopy';
import classes from './ToolsReports.module.scss';
import RouterPaths from '../../../../router/RouterPaths';
import { Link } from 'react-router-dom';
import Button, { ButtonStyleType, ButtonType } from '../../../../ui/button/Button';

enum REPORT_TYPE {
  SI = 'SI',
  STC = 'STC',
  CC = 'CC',
}
export interface AllReportsInfoResponse {
  _id: string;
  reportUrl: string;
  isDraft: boolean;
  isShared: boolean;
  isReportValid: boolean;
  name: string;
  inCollections: string[];
  customerIds: string[];
  customerThatOwenReportID: string;
  reportType?: REPORT_TYPE.CC | REPORT_TYPE.SI | REPORT_TYPE.STC;
  tsnos: number[];
}

const ToolsReports: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [allReportsInfo, setAllReportsInfo] = useState<AllReportsInfoResponse[]>([]);
  const [searchText, setSearchText] = useState('');
  const [itemsInTable, setItemsInTable] = useState<AllReportsInfoResponse[]>([]);
  const [page, setPage] = useState(1);
  const [showOnlyPublishedReportsWithErrors, setShowOnlyPublishedReportsWithErrors] = useState(false);

  const maxItemsToShow = 50;

  const newSearchInput = (searchTextInput: string, tsnosSorted?: AllReportsInfoResponse[]) => {
    setSearchText(searchTextInput);
    const tsnosCopy = tsnosSorted || deepCopy(allReportsInfo);
    let filteredReports = tsnosCopy.filter((tsnoFilter) => {
      const searchTextLower = searchTextInput.toLocaleLowerCase();
      if (!searchTextInput) return true;
      if (
        tsnoFilter.tsnos.toString().includes(searchTextLower) ||
        tsnoFilter.name.toLocaleLowerCase().includes(searchTextLower)
      ) {
        return true;
      }

      return false;
    });
    if (showOnlyPublishedReportsWithErrors) {
      filteredReports = filteredReports.filter((fr) => fr.isDraft === false && fr.isReportValid === false);
    }
    setItemsInTable(filteredReports);
  };

  const itemsToShow = () => {
    const items = itemsInTable.slice((page - 1) * maxItemsToShow, (page - 1) * maxItemsToShow + maxItemsToShow);

    return items;
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    async function getAllReports() {
      try {
        const allReportsInfoResponse = await axiosPrivate.get<{
          allReportsInfo: AllReportsInfoResponse[];
          error: boolean;
        }>('reports/getAllReportsInfo', {
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        if (allReportsInfoResponse?.data?.error === false) {
          setAllReportsInfo(allReportsInfoResponse?.data?.allReportsInfo ?? []);
          newSearchInput(searchText, allReportsInfoResponse?.data?.allReportsInfo ?? []);
        }
      } catch (error) {
        console.log(error);
        source.cancel('Cancelling in cleanup');
        setErrorMessage('Something went wrong try again later.');
      }
    }
    getAllReports();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    newSearchInput(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnlyPublishedReportsWithErrors]);

  return (
    <>
      <PageHeader pageTitle="Reports" search={{ searchText, newSearchInput }} />
      {errorMessage && <h2 className="pl-2 pt-2">{errorMessage}</h2>}
      <div className={classes.itemsWrapper}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showOnlyPublishedReportsWithErrors}
              title="Show only published reports with errors"
              color="primary"
              onChange={(event) => setShowOnlyPublishedReportsWithErrors(!showOnlyPublishedReportsWithErrors)}
              sx={{
                color: '#19555a',
                '&.Mui-checked': {
                  color: '#19555a',
                },
              }}
            />
          }
          label="Show only published reports with error"
        />
        <p>
          {itemsInTable.length} items of {allReportsInfo.length}
        </p>
        <Pagination count={Math.ceil(itemsInTable.length / maxItemsToShow)} page={page} onChange={handleChange} />
      </div>
      <Paper className="ml-2 mr-2 mb-2">
        <TableContainer>
          <Table aria-label="tsnos table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>TSNO</TableCell>
                <TableCell>Has error</TableCell>
                <TableCell>Draft/Published</TableCell>
                <TableCell>Is shared</TableCell>
                <TableCell>In collection</TableCell>
                <TableCell>Report type</TableCell>
                <TableCell>Link report</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemsInTable &&
                itemsToShow().map((reportInfo) => (
                  <TableRow key={reportInfo._id}>
                    <TableCell component="th" scope="row">
                      {reportInfo.name}
                    </TableCell>
                    <TableCell>{reportInfo.tsnos.join(', ')}</TableCell>
                    <TableCell>
                      {!reportInfo.isReportValid && <div className="isValidError">Error in report!</div>}
                    </TableCell>
                    <TableCell>{reportInfo.isDraft ? 'Draft' : 'Published'}</TableCell>
                    <TableCell>{reportInfo.isShared ? 'Shared' : ''}</TableCell>
                    <TableCell>{reportInfo.inCollections.length > 0 ? 'In collection' : ''}</TableCell>
                    <TableCell>{reportInfo.reportType}</TableCell>
                    <TableCell>
                      {reportInfo.reportUrl && (
                        <Link className="ml-3" to={RouterPaths.reportEdit.replace(':reportUrl', reportInfo.reportUrl)}>
                          <Button
                            small
                            type={ButtonType.button}
                            buttonStyleType={ButtonStyleType.secondary}
                            text="Go to report"
                          />
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ToolsReports;
