import { useContext, useDebugValue } from 'react';
import AuthContext, { AuthType } from '../context/AuthProvider';

const useAuth = () => {
  const authContext = useContext<AuthType | undefined>(AuthContext);
  useDebugValue(authContext, (authData) =>
    authContext?.authData?.userId ? 'Logged In' : 'Logged Out'
  );
  return useContext(AuthContext);
};

export default useAuth;
