import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { axiosPrivate } from '../../../../plugins/Axios';
import PageHeader from '../../../../components/pageHeader/PageHeader';
import { TableContainer, TableHead, TableRow, TableCell, Paper, Table, TableBody, Pagination } from '@mui/material';
import { GetTSNOsResponse } from '../types/tools.interfaces';
import deepCopy from 'deepcopy';
import classes from './TSNOs.module.scss';

const TSNOsPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [allTSNOResponse, setAllTSNOResponse] = useState<GetTSNOsResponse[]>([]);
  const [searchText, setSearchText] = useState('');
  const [itemsInTable, setItemsInTable] = useState<GetTSNOsResponse[]>([]);
  const [page, setPage] = useState(1);

  const maxItemsToShow = 25;

  const newSearchInput = (searchTextInput: string, tsnosSorted?: GetTSNOsResponse[]) => {
    setSearchText(searchTextInput);
    const tsnosCopy = tsnosSorted || deepCopy(allTSNOResponse);
    const filteredTsnos = tsnosCopy.filter((tsnoFilter) => {
      const searchTextLower = searchTextInput.toLocaleLowerCase();
      if (!searchTextInput) return true;
      if (
        tsnoFilter.tsno.toString().includes(searchTextLower) ||
        tsnoFilter.description.toLocaleLowerCase().includes(searchTextLower)
      ) {
        return true;
      }

      return false;
    });

    setItemsInTable(filteredTsnos);
  };

  const itemsToShow = () => {
    const items = itemsInTable.slice((page - 1) * maxItemsToShow, (page - 1) * maxItemsToShow + maxItemsToShow);

    return items;
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    async function getAllTSNOs() {
      try {
        const allTSNOResponse = await axiosPrivate.get('general/tsnos', {
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        setAllTSNOResponse(allTSNOResponse?.data ?? []);
        newSearchInput(searchText, allTSNOResponse?.data ?? []);
      } catch (error) {
        console.log(error);
        source.cancel('Cancelling in cleanup');
        setErrorMessage('Something went wrong try again later.');
      }
    }
    getAllTSNOs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      <PageHeader pageTitle="Tsnos" search={{ searchText, newSearchInput }} />
      {errorMessage && <h2 className="pl-2 pt-2">{errorMessage}</h2>}
      <div className={classes.itemsWrapper}>
        <p>
          {itemsInTable.length} items of {allTSNOResponse.length}
        </p>
        <Pagination count={Math.ceil(itemsInTable.length / maxItemsToShow)} page={page} onChange={handleChange} />
      </div>
      <Paper className="ml-2 mr-2 mb-2">
        <TableContainer>
          <Table aria-label="tsnos table">
            <TableHead>
              <TableRow>
                <TableCell>TSNO</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Country Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemsInTable &&
                itemsToShow().map((tsno) => (
                  <TableRow key={tsno.tsno}>
                    <TableCell component="th" scope="row">
                      {tsno.tsno}
                    </TableCell>
                    <TableCell>{tsno.description}</TableCell>
                    <TableCell>{tsno.twoLetterCountryCode}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default TSNOsPage;
