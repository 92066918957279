import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { axiosPrivate } from '../../../../plugins/Axios';
import PageHeader from '../../../../components/pageHeader/PageHeader';
import { TableContainer, TableHead, TableRow, TableCell, Paper, Table, TableBody } from '@mui/material';
import { GetCountriesResponse } from '../types/tools.interfaces';

const CountriesPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [allCountriesResponse, setAllCountriesResponse] = useState<GetCountriesResponse[]>([]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    async function getAllCountries() {
      try {
        const allCountriesResponse = await axiosPrivate.get('general/countries', {
          cancelToken: source.token,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        setAllCountriesResponse(allCountriesResponse?.data ?? []);
      } catch (error) {
        source.cancel('Cancelling in cleanup');
        setErrorMessage('Something went wrong try again later.');
      }
    }
    getAllCountries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader pageTitle="Countries" />
      {errorMessage && <h2 className="pl-2 pt-2">{errorMessage}</h2>}

      <Paper className="ml-2 mr-2 mb-2">
        <TableContainer>
          <Table aria-label="country table">
            <TableHead>
              <TableRow>
                <TableCell>Country</TableCell>
                <TableCell>Country Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allCountriesResponse &&
                allCountriesResponse.map((country) => (
                  <TableRow key={country.id}>
                    <TableCell component="th" scope="row">
                      {country.name}
                    </TableCell>
                    <TableCell>{country.threeLetterCode}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default CountriesPage;
