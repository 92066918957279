import React from 'react';
/* import { useNavigate } from 'react-router-dom'; */
import classes from './Layout.module.scss';
/* import { logLocation } from '../../plugins/analytics'; */
import { Outlet } from 'react-router-dom';

const Layout: React.FC = () => {
  /*   const navigate = useNavigate(); */ // TODO FIX log

  /*   useEffect(() => {
    logLocation();
  }, [location]); */

  return (
    <main className={classes.main}>
      <div className={classes.box}>
        <Outlet />
      </div>
    </main>
  );
};

export default Layout;
