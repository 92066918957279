import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { TextField as MuiTextField, Autocomplete } from '@mui/material';

import Button, { ButtonStyleType, ButtonType } from '../../../ui/button/Button';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

interface Props {
  showDialog: boolean;
  customerId: string;
  closeDialog: () => void;
  accessGiven: () => void;
}
interface IFindAllCollectionResponse {
  id: string;
  numberOfReports: number;
  collectionURL: string;
  name: string;
}

const GiveAccessToSharedCollectionDialog: React.FC<Props> = ({
  customerId,
  showDialog,
  closeDialog,
  accessGiven,
}) => {
  const [generalErrorState, setGeneralErrorState] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchReportState] = useState<any>();
  const [selectedCollectionState, setSelectedCollectionState] =
    useState<IFindAllCollectionResponse | null>(null);
  const [collectionListState, setCollectionListState] = useState<
    IFindAllCollectionResponse[]
  >([]);
  const [inputValue, setInputValue] = useState('');

  const axiosPrivate = useAxiosPrivate();

  const closeDialogHandler = () => {
    closeDialog();
  };

  const saveAccessToCollectionHandler = async () => {
    setIsLoading(true);
    setGeneralErrorState('');
    if (!selectedCollectionState) {
      setGeneralErrorState('No collection selected');
      setIsLoading(false);
      return;
    }

    await axiosPrivate
      .post(
        `collections/giveAccessToCollection/`,
        { collectionId: selectedCollectionState.id, customerId },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          accessGiven();
        } else {
          setGeneralErrorState('Error! Could not give access');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error) return;
      });
  };

  const getSharedCollections = async () => {
    await axiosPrivate
      .get(`/collections/findMissingSharedCollections/`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
        params: {
          customerId: customerId,
        },
      })
      .then((response) => {
        const collectionsResponse: IFindAllCollectionResponse[] = response.data;
        if (collectionsResponse) {
          setCollectionListState(collectionsResponse);
        }
      })
      .catch();
  };
  useEffect(() => {
    getSharedCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const searchHandler = async (newValue: IFindAllCollectionResponse | null) => {
    setInputValue('');
    setSelectedCollectionState(newValue);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={showDialog}
      onClose={closeDialogHandler}
      aria-labelledby="Create report"
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ backgroundColor: '#f7f7f7' }}
      >
        Collection access form
      </DialogTitle>
      <DialogContent className="pt-5">
        <Autocomplete
          clearOnBlur
          clearOnEscape
          id="searchCollection"
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={collectionListState}
          value={searchReportState}
          onChange={(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            event: any,
            newValue: IFindAllCollectionResponse | null
          ) => {
            searchHandler(newValue);
          }}
          getOptionLabel={(collection) => collection.name}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => (
            <MuiTextField {...params} label="Search..." variant="standard" />
          )}
        />
        {generalErrorState && (
          <div
            className="mt-3 "
            style={{
              backgroundColor: '#FFCDD2',
              border: '1px solid #EF9A9A',
              padding: '8px',
              borderRadius: '3px',
            }}
          >
            {generalErrorState}
          </div>
        )}
      </DialogContent>
      <hr />
      <DialogActions className="mt-1 mb-1">
        <Button
          type={ButtonType.button}
          buttonStyleType={ButtonStyleType.secondary}
          text="Cancel"
          onClick={closeDialogHandler}
        />
        <Button
          loading={isLoading}
          type={ButtonType.submit}
          buttonStyleType={ButtonStyleType.primary}
          text="Give access"
          onClick={saveAccessToCollectionHandler}
        />
      </DialogActions>
    </Dialog>
  );
};

export default GiveAccessToSharedCollectionDialog;
