export enum UserJob {
  USER = 'USER',
  JOB = 'JOB',
}
export enum StatusJob {
  Ok = 'OK',
  ERROR = 'ERROR',
}
export enum JobsName {
  CURRENCIES = 'CURRENCIES',
  COUNTRIES = 'COUNTRIES',
  TSNOS = 'TSNOS',
  REPORTS = 'REPORTS',
}
