import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { ROLE } from '../context/Roles.enum';

interface Props {
  allowedRoles: ROLE[];
}
const RequireAuth = (props: Props) => {
  const { allowedRoles } = props;
  const auth = useAuth();
  const location = useLocation();

  return auth?.authData.roles?.find((role: ROLE) =>
    allowedRoles?.includes(role)
  ) ? (
    <Outlet />
  ) : auth?.authData ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
