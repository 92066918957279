import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Reports from './Reports';
import Collections from './Collections';
import Users from './Users';
import InvitationKeys from './InvitationKeys';

import { useParams } from 'react-router-dom';
import { ICustomer } from '../Customers/Customer.type';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useAdminCustomer from '../../../hooks/useAdminCustomer';
import classes from './AdminCustomerPage.module.scss';
const AdminCustomer: React.FC = () => {
  const { customerUrl } = useParams();

  const [value, setValue] = React.useState(0);
  const [customerState, setCustomerState] = useState<ICustomer>();

  const axiosPrivate = useAxiosPrivate();
  const useAdminCustomerContext = useAdminCustomer();

  useEffect(() => {
    async function getCustomer() {
      if (typeof customerUrl === 'string') {
        await axiosPrivate
          .get(`/customers/${customerUrl}`, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          })
          .then((response) => {
            const customer: ICustomer = response.data;
            setCustomerState(customer);
            useAdminCustomerContext?.updateAdminCustomerData({
              customerId: customer.id,
              customerName: customer.name,
              customerUrl: customer.customerUrl,
            });
          })
          .catch((e) => {
            setCustomerState({
              id: '',
              name: 'Customer not found',
              customerUrl: '',
            });
          });
      }
    }
    getCustomer();
    return () => {
      setCustomerState({ id: '', name: '', customerUrl: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUrl]);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
  }

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  const TabPanel = (tabPanelProps: TabPanelProps) => {
    const { children, index } = tabPanelProps;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        aria-labelledby={`action-tab-${index}`}
        id={`action-tabpanel-${index}`}
      >
        {value === index && <div className="pt-3">{children}</div>}
      </div>
    );
  };

  return (
    <>
      <h1 className={classes.top}>{customerState?.name}</h1>
      <hr className="mb-4" />
      {customerState?.id && (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="Admin dashboard tabs"
          >
            <Tab label="Reports" />
            <Tab label="Collections" />
            <Tab label="Users" />
            <Tab label="Invitation keys" />
          </Tabs>
          <TabPanel index={0}>
            <Reports customerId={customerState.id} />
          </TabPanel>
          <TabPanel index={1}>
            <Collections customerUrl={customerState.customerUrl} customerId={customerState.id} />
          </TabPanel>
          <TabPanel index={2}>
            <Users customerId={customerState.id} customerName={customerState.name} />
          </TabPanel>
          <TabPanel index={3}>
            <InvitationKeys customerId={customerState.id} />
          </TabPanel>
        </>
      )}
    </>
  );
};

export default AdminCustomer;
